<template>
    <div class="glucose">
        <div class="glucose_card">
            <b-numberinput class="glucose_level" :controls=false :max="maxDays" :min="minDays" v-model="numberOfDays"/>
        </div>
        <div class="glucose_meter_row">
            <div class="glucose_meter">
                <glucose-meter :value="firstReportAverageValue" caption="Morning"/>
            </div>
            <div class="glucose_meter">
                <glucose-meter :value="dailyReportAverageValue" caption="Rest of the day"/>
            </div>
            <div class="glucose_meter">
                <glucose-meter :value="allReportAverageValue" caption="All"/>
            </div>
        </div>
        <hr class="myhr"/>
        <div class="glucose_meter_row">
            <div class="glucose_meter_ico">
                <img class="glucose_meter_icon" src="./svg/syringe.svg">
            </div>
            <div class="glucose_meter">
                <glucose-meter-linear :value="firstReportAverageValue" caption="Morning"/>
            </div>
            <div class="glucose_meter">
                <glucose-meter-linear :value="dailyReportAverageValue" caption="Rest of the day"/>
            </div>
            <div class="glucose_meter">
                <glucose-meter-linear :value="allReportAverageValue" caption="All"/>
            </div>
        </div>
        <hr class="myhr"/>
        <div class="glucose_meter_row">
            <div class="glucose_meter_ico">
                <img class="glucose_meter_icon" src="./svg/pills.svg">
            </div>
            <div class="glucose_meter">
                <glucose-meter-box :value="firstReportAverageValue" caption="Morning"/>
            </div>
            <div class="glucose_meter">
                <glucose-meter-box :value="dailyReportAverageValue" caption="Rest of the day"/>
            </div>
            <div class="glucose_meter">
                <glucose-meter-box :value="allReportAverageValue" caption="All"/>
            </div>
        </div>

    </div>
</template>


<script>
    import GlucoseMeter from "./GlucoseMeter";
    import GlucoseMeterLinear from "./GlucoseMeterLinear";
    import GlucoseMeterBox from "./GlucoseMeterBox";

    import {mapGetters} from 'vuex'
    import moment from 'moment';


    const now = new moment(new Date());

    function getLastDates(days) {
        const today = now;
        let dates = Array();
        for (let i = days; i >=0; i--) {
            dates.push(today.clone().subtract(i, "days").format("YYYY-MM-DD"))
        }
        return dates
    }

    export default {
        components: {
            GlucoseMeterBox,
            GlucoseMeterLinear,
            GlucoseMeter
        },
        name: "GlucoseLevel",
        props: {
            created: String,
            user_id: String,
        },
        data() {
            return {
                averageDays: 1,
                minDays: 1,
                maxDays: 14,
                firstReportAverageValue: 0,
                dailyReportAverageValue: 0,
                allReportAverageValue: 0,
                dates: [],
                reports: []
            }
        },
        created() {
            let days = moment().diff(this.created, 'days');
            this.maxDays = days + 1;
            this.numberOfDays = days;
        },
        methods: {
            round(num) {
                return Math.round(num * 10) / 10;
            },
            getFit(glucose) {
                if (glucose < 80) return 'glucose_red'; // < 80 red
                if (glucose > 120) return 'glucose_black'; // > 120 black
                return '';
            },
            async getAverages() {
                const dates = getLastDates(this.averageDays)
                this.dates = dates;
                const today = now;
                const start = today.clone().subtract(this.averageDays - 1, "days");
                if ((moment(Date(this.getUserGlucoseDailyAveragesStart)) >= start) ||
                    moment(Date(this.getUserGlucoseDailyAveragesEnd) <= today)) {
                    this.reports = await this.getReports(start, today)
                } else {
                    this.reports = this.getUserGlucoseDailyAverages
                }
                let firstSumValues = 0;
                let firstCountValues = 0;
                let dailySumValues = 0;
                let dailyCountValues = 0;
                let allSumValues = 0;
                let allCountValues = 0;
                for (const date of dates) {
                    let report = this.reports.find(el => el.date == date)
                    if (report !== undefined) {
                        if (report.first_report != 0) {
                            firstSumValues += report.first_report;
                            firstCountValues += 1;
                        }
                        if (report.daily_average != 0) {
                            dailySumValues += report.daily_average;
                            dailyCountValues += 1;
                        }
                        if (report.all_average != 0) {
                            allSumValues += report.all_average;
                            allCountValues += 1
                        }
                    }
                }
                if (firstCountValues > 0) {
                    this.firstReportAverageValue = firstSumValues / firstCountValues;
                } else {
                    this.firstReportAverageValue = 0;
                }
                if (dailyCountValues > 0) {
                    this.dailyReportAverageValue = dailySumValues / dailyCountValues;
                } else {
                    this.dailyReportAverageValue = 0;
                }
                if (allCountValues > 0) {
                    this.allReportAverageValue = allSumValues / allCountValues;
                } else {
                    this.allReportAverageValue = 0;
                }
            },

            async getReports(startday, endday) {
                const str_startday = startday.format("YYYY-MM-DD");
                const str_endday = endday.format("YYYY-MM-DD");
                await this.$store.dispatch('USER_GET_BLOOD_PRESSURE_DAILY_AVERAGES', {
                    'user_id': this.user_id,
                    'start': str_startday,
                    'end': str_endday,
                });
                return await this.$store.dispatch('USER_GET_GLUCOSE_DAILY_AVERAGES', {
                    'user_id': this.user_id,
                    'start': str_startday,
                    'end': str_endday,
                    'number': this.numberOfDays
                });
            },
            compareDates(date1, date2) {
                const datea = new Date(date1);
                const dateb = new Date(date2);
                if (datea.getFullYear() !== dateb.getFullYear()) return false;
                if (datea.getMonth() !== dateb.getMonth()) return false;
                return datea.getDate() === dateb.getDate();
            }
        },
        computed: {
            ...mapGetters(['getUserGlucoseDailyAverages',
                'getUserGlucoseDailyAveragesStart',
                'getUserGlucoseDailyAveragesEnd']),
            numberOfDays: {
                get() {
                    return this.averageDays
                },
                set(value) {
                    if (this.minDays < value < this.maxDays) this.averageDays = value;
                    if (value < this.minDays) this.averageDays = this.minDays
                    if (value > this.maxDays) this.averageDays = this.maxDays
                    if (this.minDays <= this.averageDays <= this.maxDays) {
                        this.getAverages();
                    }
                }
            }
        },
    }
</script>

<style scoped>

    .glucose {
        min-width: 620px;
        margin-bottom: 5px;
    }

    .glucose_card {
        display: block;
        float: left;
        position: relative;
        height: 115px;
        width: 200px;
        margin: 10px 0 10px 0;
        background-image: url("svg/glucose_level_bg.svg");
        background-repeat: no-repeat;
    }

    .glucose_level {
        position: absolute;
        left: 70px;
        top: 65px;
        width: 100px;
        font-weight: 500;
        font-size: 31px;
        line-height: 18px;
        /* or 58% */
        letter-spacing: -0.08px;
        color: #4CD964;
    }

    .glucose_red {
        color: red;
        border-color: red;
    }

    .glucose_black {
        color: black;
        border-color: black;
    }

    .glucose_meter_row {
        position: relative;
        margin: 0;
        padding: 0 25px 0 201px;
        width: 100%;
    }

    .glucose_meter_ico {
        display: block;
        position: absolute;
        top: 15px;
        right: 0px;
        height: 25px;
        width: 25px;
    }

    .glucose_meter_icon {
        height: 20px;
        width: 20px;
        padding: 0;
        margin: 0;
    }

    .glucose_meter {
        position: relative;
        padding: 0;
        margin: 0;
        display: inline-block;
        text-align: center;
        width: 33%;
    }

    .myhr {
        margin: 0;
    }
</style>