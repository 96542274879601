<template>
    <tr>
        <td><b> {{ (index+1)+')' }} </b></td>
        <td v-if="type==='number'" >
            <b-input v-model="score.min"
                     validation-message="Only Numbers is allowed in min"
                     pattern="[0-9]*"
                     required>
            </b-input>
        </td>
        <td v-else-if="type==='date'">
            <b-datepicker v-model="min" :max-date="max" required></b-datepicker>
        </td>
        <td v-else-if="type==='time'">
            <b-timepicker v-model="min" :max-time="max" required></b-timepicker>
        </td>
        <td v-else-if="type==='datetime'">
            <b-datetimepicker v-model="min" :max-datetime="max" required></b-datetimepicker>
        </td>

        <td v-if="type==='number'">
            <b-input v-model="score.max"
                     validation-message="Only Numbers is allowed in max"
                     pattern="[0-9]*"
                     required></b-input>
        </td>
        <td v-else-if="type==='date'">
            <b-datepicker v-model="max" :min-date="min" required></b-datepicker>
        </td>
        <td v-else-if="type==='time'">
            <b-timepicker v-model="max" :min-time="min" required></b-timepicker>
        </td>
        <td v-else-if="type==='datetime'">
            <b-datetimepicker v-model="max" :min-datetime="min" required></b-datetimepicker>
        </td>

        <td>
            <b-input v-model="score.score"
                     validation-message="Only Numbers is allowed in score"
                     pattern="[0-9]*"
                     required>
            </b-input>
        </td>
        <td class="score_cell" ><b-button v-if="unblocked" @click="removeScore">-</b-button></td>
    </tr>
</template>

<script>
    export default {
        name: "AddMeterScoreForm",
        props:{
            value: {},
            index: Number,
            pRemoveScore: {},
            pUpdate: {},
            unblocked: Boolean,
            type: String,
            min: Number(0),
            max: Number(1),
        },
        data() {
            return {
               score: {}
            }
        },
        created() {
            this.refresh()
        },
        methods: {
            removeScore() {
                this.pRemoveScore(this.index);
            },
            refresh() {
                this.score = this.value;
                this.score.id = this.index;
                if (this.type === 'date' || this.type === 'datetime' || this.type === 'time') {
                    if (this.score.min) {
                        this.min = new Date(this.score.min * 1000);
                        this.max = new Date(this.score.max * 1000);
                    } else {
                        this.min = new Date();
                        this.max = new Date();
                    }
                }
            }
        },
        watch: {
            score: {
                deep: true,
                handler () {
                    this.pUpdate(this.index, this.score);
                }
            },
            value: {
                deep: true,
                handler () {
                    this.refresh()
                }
            },
            min: function () {
                if (this.type === 'date' || this.type === 'datetime' || this.type === 'time') {
                    this.score.min = Math.floor(this.min.getTime() / 1000);
                } else {
                    this.score.min = this.min;
                }
                this.pUpdate(this.index, this.score);
            },
            max: function () {
                if (this.type === 'date' || this.type === 'datetime' || this.type === 'time') {
                    this.score.max = Math.floor(this.max.getTime() / 1000);
                } else {
                    this.score.max = this.max;
                }
                this.pUpdate(this.index, this.score);
            }
        }
    }
</script>

<style scoped>
    .score_cell {
        text-align: center;
    }
</style>