<template>
    <tr>
        <td>
            <b>{{(1+index)+')'+ answer.type+':' }}</b><br/>
            <b-checkbox class="is-small"
                        v-if="nanswers > 1 && unblocked"
                        v-model="exclusive">Exclusive
            </b-checkbox>
        </td>
        <td>
            <div>
                <b-field>
                    <b>Answer Title:</b>
                    <b-input v-model="answer_title"></b-input>
                </b-field>
            </div>
            <div v-if="unblocked">
                <b-field v-if="widgets[answer.type].length > 1">
                    <b>Select widget type</b>
                    <b-select v-model="answer_widget" placeholder="Select a name">
                        <option
                                v-for="option in widgets[answer.type]"
                                :value="option"
                                :key="option">
                            {{ option }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div v-if="answer.type === 'radio'||answer.type === 'checkbox'">
                <add-radio-form v-for="(val, key) in values"
                                :key="key"
                                :value="val"
                                :index="key"
                                :pRemoveRadio="cRemoveRadio"
                                :pUpdate="cUpdateMe"
                                :unblocked="unblocked"/>
                <b-button v-if="unblocked" @click="addRadio">Add {{answer.type}}</b-button>
            </div>
            <div v-else-if="answer.type === 'date'&&unblocked">
                <b-field>
                    <b>Min:</b>
                    <b-datepicker v-model="min" :max-date="max" required></b-datepicker>
                </b-field>
                <b-field>
                    <b>Max:</b>
                    <b-datepicker v-model="max" :min-date="min" required></b-datepicker>
                </b-field>
            </div>
            <div v-else-if="answer.type === 'time'&&unblocked">
                <b-field>
                    <b>Min:</b>
                    <b-timepicker editable v-model="min" :max-time="max" required></b-timepicker>
                </b-field>
                <b-field>
                    <b>Max:</b>
                    <b-timepicker editable v-model="max" :min-time="min" required></b-timepicker>
                </b-field>
            </div>
            <div v-else-if="answer.type === 'datetime'&&unblocked">
                <b-field>
                    <b>Min:</b>
                    <b-datetimepicker v-model="min" :max-datetime="max" required></b-datetimepicker>
                </b-field>
                <b-field>
                    <b>Max:</b>
                    <b-datetimepicker v-model="max" :min-datetime="min" required></b-datetimepicker>
                </b-field>
            </div>
            <div v-else-if="answer.type === 'number'&&unblocked">
                <b-field>
                    <b>Min:</b>
                    <b-numberinput v-if="answer_widget==='scale'" type="number" v-model="min" :max="max" min=1 :controls="ctrls"
                                   required></b-numberinput>
                    <b-numberinput v-else type="number" v-model="min" :max="max" :controls="ctrls"
                                   required></b-numberinput>
                </b-field>
                <b-field>
                    <b>Max:</b>
                    <b-numberinput v-if="answer_widget==='scale'" type="number" v-model="max" :min="min" max=8   :controls="ctrls"
                                   required></b-numberinput>
                    <b-numberinput v-else type="number" v-model="max" :min="min" :controls="ctrls"
                                   required></b-numberinput>
                </b-field>
                <b-field>
                    <b>Default:</b>
                    <b-numberinput type="number" v-model="defaultValue" :min="min" :max="max" :controls="ctrls"
                                   :disabled="!isDefaultValue"></b-numberinput>
                    <b-checkbox v-model="isDefaultValue"></b-checkbox>
                </b-field>

            </div>
            <div v-else-if="answer.type === 'text'&&unblocked">
                <b>text</b>
            </div>
            <b-field v-if="answer.type==='number'||answer.type=='date'||answer.type=='time'||answer.type=='datetime'" style="display: block">
                <b>Scores:</b>
                <table v-if="scores.length > 0" class="score_table">
                    <tr>
                        <th class="score_header">#</th>
                        <th class="score_header"><b>min</b></th>
                        <th class="score_header"><b>max</b></th>
                        <th class="score_header"><b>score</b></th>
                        <th class="score_header">remove</th>
                    </tr>
                    <add-meter-score-form v-for="(val, key) in scores"
                                          :key="key"
                                          :value="val"
                                          :index="key"
                                          :pRemoveScore="cRemoveScore"
                                          :pUpdate="cScoreUpdateMe"
                                          :unblocked="unblocked"
                                          :type="answer.type"
                    />
                </table>
                <b-button v-if="unblocked" @click="addScore">Add score</b-button>
            </b-field>
        </td>
        <td>
            <b-button v-if="unblocked" @click="removeAnswer">-</b-button>
        </td>
    </tr>
</template>

<script>
    import AddRadioForm from "./AddRadioForm";
    import WidGets from "../answer_widgets.json"
    import AddMeterScoreForm from "./AddMeterScoreForm";

    export default {
        name: "AddAnswerForm",
        components: {
            AddMeterScoreForm,
            AddRadioForm,
        },
        data() {
            return {
                ans: {},
                values: [],
                scores: [],
                min: Number(0),
                max: Number(1),
                isDefaultValue: false,
                defaultValue: 0,
                answer_title: null,
                answer_widget: null,
                widgets: WidGets,
                ctrls: false,
                exclusive: Boolean
            }
        },
        props: {
            answer: null,
            index: Number,
            pRemoveAnswer: {},
            pUpdate: {},
            unblocked: Boolean,
            clearExclusives: {},
            nanswers: Number,
        },
        created() {
            this.refresh()
        },
        methods: {
            refresh() {
                if (typeof this.answer == "object") {
                    this.ans = {};
                    //if (this.answer.length > 0) {
                        this.ans = JSON.parse(JSON.stringify(this.answer));
                    //}

                    if (this.answer.hasOwnProperty('is_exclusive')) {
                        this.exclusive = this.answer.is_exclusive;
                    }

                    if (this.answer.hasOwnProperty('score')) {
                        this.scores = JSON.parse(JSON.stringify(this.answer.score))
                    }

                    if (typeof this.ans.widget == 'string') {
                        this.answer_widget = this.ans.widget;
                    } else {
                        const l = this.widgets[this.answer.type];
                        this.answer_widget = l[0];
                    }

                    if (typeof this.ans.title == 'string') {
                        this.answer_title = this.ans.title;
                    }

                    if (this.ans.type === 'date' || this.ans.type === 'datetime' || this.ans.type === 'time') {
                        if (this.ans.min) {
                            this.min = new Date(this.ans.min * 1000);
                            this.max = new Date(this.ans.max * 1000);
                        } else {
                            this.min = new Date();
                            this.max = new Date();
                        }
                    } else if (this.ans.type === 'number') {
                        if (typeof this.ans.min == 'number') {
                            this.min = this.ans.min;
                            this.max = this.ans.max;
                            if (this.ans.hasOwnProperty('default')) {
                                this.defaultValue = this.ans.default;
                                this.isDefaultValue = true;
                            } else {
                                this.defaultValue = Math.floor((this.min + this.max) / 2);
                            }
                        }
                    } else if (this.ans.type === 'radio' || this.ans.type === 'checkbox') {
                        this.values = JSON.parse(JSON.stringify(this.ans.values)); // hack for deep copy
                    }
                } else {
                    if (this.answer.type === 'date' || this.answer.type === 'datetime' || this.answer.type === 'time') {
                        this.min = new Date();
                        this.max = new Date();
                        this.ans.min = Math.floor(this.min.getTime() / 1000);
                        this.ans.max = Math.floor(this.max.getTime() / 1000);
                    }
                    if (this.answer.type === 'number') {
                        this.min = 0;
                        this.max = 10;
                        this.ans.min = this.min;
                        this.ans.max = this.max;
                    }
                }
                this.pUpdate(this.index, this.ans);
            },
            addRadio() {
                this.values.push({"id": '', "text": ""});
                this.$log.info(this.values);
            },
            addScore() {
                this.scores.push({"min": this.ans.min,"max": this.ans.max, "score": 0})
                this.$log.info(this.scores);
            },
            removeAnswer() {
                this.pRemoveAnswer(this.index);
            },
            cRemoveRadio(index) {
                this.values.splice(index, 1);
            },
            cRemoveScore(index) {
                this.scores.splice(index, 1);
            },
            cScoreUpdateMe(index, data) {
                this.scores[index] = data;
            },
            cUpdateMe(index, data) {
                this.values[index] = data;
            },
        },
        watch: {
            answer: {
                deep: true,
                handler() {
                    this.refresh()
                }
            },
            ans: function () {
                this.pUpdate(this.index, this.ans);
            },
            values: function () {
                this.ans.values = this.values;
                this.pUpdate(this.index, this.ans);
            },
            scores: function () {
                this.ans.score = this.scores;
                this.pUpdate(this.index, this.ans);
            },
            min: function () {
                if (this.answer.type === 'date' || this.answer.type === 'datetime' || this.answer.type === 'time') {
                    this.ans.min = Math.floor(this.min.getTime() / 1000);
                } else {
                    this.ans.min = this.min;
                }
                this.pUpdate(this.index, this.ans);
            },
            max: function () {
                if (this.answer.type === 'date' || this.answer.type === 'datetime' || this.answer.type === 'time') {
                    this.ans.max = Math.floor(this.max.getTime() / 1000);
                } else {
                    this.ans.max = this.max;
                }
                this.pUpdate(this.index, this.ans);
            },
            defaultValue: function () {
                this.ans.default = this.defaultValue;
                this.pUpdate(this.index, this.ans);
            },
            isDefaultValue: function() {
                if (this.isDefaultValue) {
                    this.ans.default = this.defaultValue;
                } else {
                    delete this.ans.default
                }
            },
            answer_title: function () {
                this.ans.title = this.answer_title;
                this.pUpdate(this.index, this.ans);
            },
            answer_widget: function () {
                this.ans.widget = this.answer_widget;
                this.pUpdate(this.index, this.ans);
            },
            exclusive: function () {
                this.ans.is_exclusive = this.exclusive;
                this.pUpdate(this.index, this.ans);
                if (this.exclusive) {
                    //this.$log.debug(this.ans);
                    this.clearExclusives(this.index);
                }
            },
        }
    }
</script>

<style scoped>
    .score_table {
        display: block;
        width: 100%;
    }

    .score_header {
        text-align: center;
    }
</style>