<template>
    <div class="level-container">
        <div class="level-name">
            <b-field grouped v-if="hasPermission('write:stages')">
                <b-field>
                    <b-icon v-if="isLevelValid" icon="check-outline" type="is-success is-small"></b-icon>
                    <b-icon v-else icon="close-outline" type="is-danger is-small"></b-icon>
                </b-field>
                <b-field v-if="isLevelNameValid" :label="'Level name'" label-position="on-border">
                    <b-input v-model="levelName" icon="check-outline"></b-input>
                </b-field>
                <b-field v-else :label="'Level '+index+' name'" label-position="on-border">
                    <b-input v-model="levelName" placeholder="Enter the name of level"
                             icon="close-outline"></b-input>
                </b-field>
                <b-field v-if="isLevelDescriptionValid" label="Description" label-position="on-border">
                    <b-input v-model="levelDescription" icon="check-outline"></b-input>
                </b-field>
                <b-field v-else label="Description" label-position="on-border">
                    <b-input v-model="levelDescription" placeholder="Enter the name of level"
                             icon="close-outline"></b-input>
                </b-field>
                <b-field>
                    <b-button @click="confirmRemoveLevel"
                              type="is-danger is-rounded" vertical :disabled="levelIsNew">
                        remove level
                    </b-button>
                </b-field>
                <b-field>
                    <b-button @click="moveUp"
                              type="is-success is-rounded" vertical :disabled="isFirst||levelIsNew">
                        <b-icon icon="arrow-up-bold" size="is-small"/>
                        move up
                    </b-button>
                </b-field>
                <b-field>
                    <b-button @click="moveDown"
                              type="is-success is-rounded" vertical :disabled="isLast||levelIsNew">
                        <b-icon icon="arrow-down-bold" size="is-small"/>
                        move down
                    </b-button>
                </b-field>
            </b-field>
            <b-field grouped v-else>
                <b-field>
                    <b-icon v-if="isLevelValid" icon="check-outline" type="is-success is-small"></b-icon>
                    <b-icon v-else icon="close-outline" type="is-danger is-small"></b-icon>
                </b-field>
                <b-field :label="'Level '+index+' name'" label-position="on-border">
                    <b-input :value="levelName" icon="check-outline" disabled=true></b-input>
                </b-field>
                <b-field label="Description" label-position="on-border">
                    <b-input :value="levelDescription" icon="check-outline" disabled=true></b-input>
                </b-field>
            </b-field>

        </div>
        <div class="level-stages">
            <stage v-for="(stage, key) in levelStages"
                   :key="key"
                   :id="stage.id"
                   :num="key"
                   :level-id="id"
                   :level-name="levelName"
            />
            <div v-if="hasPermission('write:stages')"
                 class="draggable" :level="id" stage=-1 style="width: 100%;">
                <b-button class="level-addstage" @click="addStage"
                          type="is-success is-rounded " vertical :disabled="levelIsNew">
                    a<br/>d<br/>d<br/><br/>s<br/>t<br/>a<br/>g<br/>e
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Stage from "./Stage";
    import {mapGetters} from 'vuex'

    export default {
        name: "Level",
        components: {Stage},
        props: {
            id: Number,
            index: Number,
        },
        computed: {
            ...mapGetters(['getLevel', 'getStages', 'isFirstLevel', 'isLastLevel', 'hasPermission']),
            isLevelValid() {
                const level = this.getLevel(this.id);
                if (level != undefined) {
                    return Object.keys(level.errors).length == 0
                }
                return true
            },
            isLevelNameValid() {
                const level = this.getLevel(this.id);
                if (level != undefined) {
                    if (level.hasOwnProperty('errors')) {
                        if (level.errors.hasOwnProperty('name')) return false;
                    }
                }
                return true;
            },
            isLevelDescriptionValid() {
                const level = this.getLevel(this.id);
                if (level != undefined) {
                    if (level.hasOwnProperty('errors')) {
                        if (level.errors.hasOwnProperty("description")) return false;
                    }
                }
                return true;
            },
            levelName: {
                get() {
                    const level = this.getLevel(this.id);
                    if (level != undefined) {
                        if (level.hasOwnProperty('name')) {
                            return level.name
                        }
                    }
                    return ''
                },
                set(value) {
                    this.$store.dispatch("UPDATE_LEVEL", {id: this.id, fields: {name: value}})
                }
            },
            levelDescription: {
                get() {
                    const level = this.getLevel(this.id);
                    if (level != undefined) {
                        if (level.hasOwnProperty('description')) {
                            return this.getLevel(this.id).description
                        }
                    }
                    return ''
                },
                set(value) {
                    this.$store.dispatch("UPDATE_LEVEL", {id: this.id, fields: {description: value}})
                }
            },
            levelStages: {
                get() {
                    const stages = Object.values(this.getStages(this.id));
                    return [...stages].sort((a, b) => a.order - b.order);
                }
            },
            levelIsNew: {
                get() {
                    const level = this.getLevel(this.id);
                    if (level != undefined) {
                        if (level.hasOwnProperty('isNew')) return true;
                    }
                    return false
                }
            },
            isLoading: {
                get() {
                    return true;
                }
            },
            isFirst() {
                return this.isFirstLevel(this.id);
            },
            isLast() {
                return this.isLastLevel(this.id);
            },
        },
        methods: {
            addStage() {
                this.$store.dispatch("ADD_STAGE", this.id);
            },
            confirmRemoveLevel() {
                this.$buefy.dialog.confirm({
                    title: 'Delete level',
                    message: 'Are you sure you want to <b>delete</b> level? This action cannot be undone.',
                    confirmText: 'Delete level',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.removeLevel()
                })
            },
            removeLevel() {
                this.$store.dispatch('REMOVE_LEVEL', this.id);
            },
            moveUp() {
                const order = this.getLevel(this.id).order - 1;
                this.$store.dispatch("REORDER_LEVEL", {id: this.id, order: order})
            },
            moveDown() {
                const order = this.getLevel(this.id).order + 1;
                this.$store.dispatch("REORDER_LEVEL", {id: this.id, order: order})
            }
        }
    }
</script>

<style scoped>
    .level-container {
        position: relative;
        width: 100%;
        height: 430px;
        text-align: left;
        border: 1px solid gray;
        border-radius: 5px;
        margin-top: 10px;
    }

    .level-name {
        padding: 10px;
        width: 100%;
        height: 55px;
    }

    .level-stages {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 10px;
        width: 100%;
        height: 370px;
        top: 55px;
    }

    .level-addstage {
        position: relative;
        height: 270px;
    }
</style>