<template>
    <form action="" @submit.prevent="saveCondition">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
            </header>
            <section class="modal-card-body">
                <table class="table">
                    <tr>
                        <th>logic</th>
                        <th>report type</th>
                        <th>condition type</th>
                        <th>values</th>
                    </tr>
                    <tr>
                        <td>
                            {{ getLogic() }}
                        </td>
                        <td>
                            <div v-for="(f_report, key) in reportTypes" :key="key">
                                <b-radio v-model="report"
                                         name="reportType"
                                         :native-value="f_report">{{f_report}}
                                </b-radio>
                            </div>
                        </td>
                        <td>
                            <div v-for="(condition, key) in conditionTypes" :key="key">
                                <b-radio v-model="conditionT"
                                         name="conditionType"
                                         :native-value="condition">{{condition}}
                                </b-radio>
                            </div>
                        </td>
                        <td v-if="reports[report].hasOwnProperty(conditionT)">
                            <div v-if="reports[report][conditionT]==='Time'">
                                <b-timepicker v-model="values"></b-timepicker>
                            </div>
                            <div v-if="reports[report][conditionT]==='Number'">
                                <b-input v-model="value"></b-input>
                            </div>
                            <div v-if="reports[report][conditionT]==='date_time'">
                                <section v-for="(val, key) in values" :key="key">
                                    <b-timepicker v-model="values[key]"
                                                  @input="setValues({index:key, payload:values[key]})"/>
                                </section>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Weekdays:</b></td>
                        <td>
                            <b-select :value="checkDaysTypeVar" v-model="checkDaysType" size="is-small">
                                <option value="disable">Disable</option>
                                <option value="specify">Specify</option>
                                <option value="work">Work Days</option>
                                <option value="all">All Days</option>
                                <option value="clear">Clear</option>
                            </b-select>
                        </td>
                        <td colspan="2">
                            <b-field>
                                <span v-for="(day, key) in days" v-bind:key="key">
                                    <b-checkbox-button v-model="condition_days" :native-value="day" size="is-small"
                                                       type="is-success"
                                                       :disabled="!checkDays"><b>{{weekdays[day]}}</b></b-checkbox-button>
                                </span>
                            </b-field>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Stages:</b></td>
                        <td>
                            <b-select :value="checkStageTypeVar" v-model="checkStageType" size="is-small">
                                <option value="disable">Disable</option>
                                <option value="level">Level</option>
                                <option value="stage">Stages</option>
                            </b-select>
                        </td>
                        <td colspan="2">
                            <b-field grouped>
                                    <b-field horizontal>
                                        <b>Level: </b>
                                        <b-select v-model="stagesLevel" size="is-small" value="1" :disabled="!checkStage">
                                            <option v-for="(level, key_level) in levels" v-bind:key="key_level" :value="level.id">
                                                {{level.order}}
                                            </option>
                                        </b-select>
                                    </b-field>
                                <b-field v-if="checkStageType=='stage'" horizontal>
                                    <b>Stage: </b>
                                    <b-select  v-model="stagesStage" size="is-small" value="1" :disabled="!checkStage">
                                        <option v-for="(stage, key_stage) in stages" v-bind:key="key_stage + stagesLevel" :value="stage.id">
                                            {{stage.order}}
                                        </option>
                                    </b-select>
                                </b-field>
                            </b-field>
                        </td>
                    </tr>
                </table>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                <button class="button is-rounded is-success">Save</button>
            </footer>
        </div>
    </form>
</template>

<script>
    import {mapGetters} from "vuex";

    var moment = require('moment');

    export default {
        name: "AddConditionForm",
        props: {
            fdata: {},
            isEdit: Boolean,
            pCloseModal: {},
            type: {
                type: String,
                default: 'report'
            }
        },
        data() {
            return {
                title: 'Create condition',
                truth: true,
                reportTypes: ['glucose_report', 'sleep_report', 'wake_report', 'nutrition_report', 'exercise_report', 'blood_pressure_report', 'food_report', 'medicine_report', 'supplement_report', 'weight_report', 'glucose_food_sum', 'water_report'],
                reportType: 'sleep_report',
                conditionTypes: [],
                conditionType: 'Less',
                days: [6, 0, 1, 2, 3, 4, 5],
                weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                conditionDaysVar: [],
                checkDays: false,
                checkDaysTypeVar: 'disable',
                checkStage: false,
                checkStageTypeVar: 'disable',
                stagesLevel: 0,
                stagesStage: 0,
                reports: {
                    glucose_report: {
                        'Less': 'Number',
                        'More': 'Number',
                        'Equal': 'Number',
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time',
                        'Less_yesterday': 'Yesterday',
                        'More_yesterday': 'Yesterday',
                        'In_yesterday_range': 'Yesterday'
                    },
                    sleep_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    wake_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    nutrition_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    exercise_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    blood_pressure_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    food_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    medicine_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    supplement_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    weight_report: {
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    water_report: {
                        'Less': 'Number',
                        'More': 'Number',
                        'Equal': 'Number',
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time',
                    },
                    glucose_food_sum: {
                        'Less': 'Number',
                        'More': 'Number',
                        'Equal': 'Number',
                        'After': 'Time',
                        'Before': 'Time',
                        'Between': 'date_time',
                    }
                },
                //values: {
                //},0
                value: 0,
            }
        },
        created() {
            this.refresh();
            if (this.type === 'no_reports') {
                this.reports = {
                    glucose_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    sleep_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    wake_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    nutrition_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    exercise_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    blood_pressure_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    food_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    medicine_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    supplement_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    weight_report: {
                        'Before': 'Time',
                        'Between': 'date_time'
                    },
                    water_report: {
                        'Before': 'Time',
                        'Between': 'date_time',
                    },
                }
            }
            if (this.isEdit) {
                this.condition = JSON.parse(JSON.stringify(this.fdata))
                this.title = 'Edit condition'
                this.truth = this.condition.conditions.find(el => el.type === 'negation') === undefined;
                const report = this.condition.conditions.find(el => el.type === 'report')
                this.reportType = report.report_type;
                this.conditionTypes = Object.keys(this.reports[report.report_type])

                if (report.hasOwnProperty('comparator')) {
                    this.value = report.value;
                    switch (report.comparator) {
                        case 'lt':
                            this.conditionType = 'Less'
                            break;
                        case 'rt':
                            this.conditionType = 'More'
                            break;
                        case 'eq':
                            this.conditionType = 'Equal'
                            break;
                        case 'lty':
                            this.conditionType = 'Less_yesterday'
                            break;
                        case 'rty':
                            this.conditionType = 'More_yesterday'
                            break;
                        case 'eqy':
                            this.conditionType = 'In_yesterday_range'
                            break;
                    }
                }

                const condition = this.condition.conditions.find(el => el.meta === 'condition')
                if (condition !== undefined) {
                    if (condition.type === 'date_time') {
                        if (condition.hasOwnProperty('time_in_range')) {
                            this.value = JSON.parse(JSON.stringify(condition.time_in_range));
                            this.conditionType = 'Between';
                        } else if (condition.hasOwnProperty('time_before')) {
                            this.value = condition.time_before;
                            this.conditionType = 'Before';
                        } else if (condition.hasOwnProperty('time_after')) {
                            this.value = condition.time_after;
                            this.conditionType = 'After';
                        }
                        if (condition.hasOwnProperty('days')) {
                            this.condition_days = condition.days;
                            this.checkDays = true;
                        }
                    }
                }

                this.condition.conditions.find(el => {
                    if (el.meta === 'condition') {
                        switch (el.type) {
                            case 'date_time':
                                if (el.hasOwnProperty('time_in_range')) {
                                    this.conditionType = 'Between'
                                    this.value = el.time_in_range;
                                }
                                break;
                            default:
                        }
                    }
                })

                const stages = this.condition.conditions.find(el => el.meta === 'stage')
                if (stages !== undefined) {
                    if (stages.hasOwnProperty('level')){
                        this.checkStageTypeVar = 'level'
                        this.checkStage = true;
                        this.stagesLevel = stages.level;
                    }
                    if (stages.hasOwnProperty('stage')){
                        this.checkStageTypeVar = 'stage'
                        this.checkStage = true;
                        this.stagesStage = stages.stage;
                        let level_id = 0;
                        for (let level of this.levels) {
                            for(let stage of level.stages) {
                                if (stage.id === stages.stage) {
                                    level_id = level.id;
                                }
                            }
                        }
                        if (level_id >0) {
                            this.stagesLevel = level_id
                        }
                    }
                }
            } else {
                this.conditionT = 'Between';
                this.report = 'glucose_report';
            }
        },
        computed: {
            ...mapGetters(['getLevels', 'getStagesStatus', 'hasPermission']),
            report: {
                get() {
                    return this.reportType;
                },
                set(payload) {
                    this.reportType = payload;
                    this.conditionTypes = Object.keys(this.reports[payload])
                }
            },
            conditionT: {
                get() {
                    return this.conditionType;
                },
                set(payload) {
                    this.conditionType = payload
                    switch (this.conditionType) {
                        case 'Between':
                            this.value = [moment().format("HH:mm"), moment().format("HH:mm")];
                            break;
                        case 'Before':
                            this.value = moment().format("HH:mm");
                            break;
                        case 'After':
                            this.value = moment().format("HH:mm");
                            break;
                        case 'More':
                            this.value = 0;
                            break;
                        case 'Less':
                            this.value = 0;
                            break
                        case 'Equal':
                            this.value = 0;
                            break

                    }
                }
            },
            values: {
                get() {
                    if (Array.isArray(this.value)) {
                        return this.value.map(el => {
                            return new Date(moment(el, "HH:mm"))
                        });
                    }
                    return new Date(moment(this.value, "HH:mm"))
                },
                set(payload) {
                    if (Array.isArray(payload)) {
                        return this.value = payload.map(el => {
                            return moment(el).format("HH:mm")
                        });
                    } else {
                        this.value = moment(payload).format("HH:mm")
                    }
                }
            },
            condition_days: {
                get() {
                    return this.conditionDaysVar;
                },
                set(payload) {
                    this.conditionDaysVar = payload
                    this.checkDaysType = 'specify'
                }
            },
            checkDaysType: {
                get() {
                    return this.checkDaysTypeVar;
                },
                set(payload) {
                    switch (payload) {
                        case 'disable':
                            this.checkDays = false;
                            this.conditionDaysVar = [];
                            break;
                        case 'specify':
                            this.checkDays = true;
                            break;
                        case 'work':
                            this.checkDays = true;
                            this.conditionDaysVar = [6, 0, 1, 2, 3];
                            break;
                        case 'all':
                            this.checkDays = true;
                            this.conditionDaysVar = [0, 1, 2, 3, 4, 5, 6];
                            break;
                        case 'clear':
                            this.checkDays = true;
                            this.conditionDaysVar = [];
                            break;
                    }
                    this.checkDaysTypeVar = payload;
                }
            },
            checkStageType: {
                get() {
                    return this.checkStageTypeVar;
                },
                set(payload){
                    switch (payload) {
                        case 'disable':
                            this.checkStage = false;
                            this.stagesLevel = 0;
                            this.stagesStage = 0;
                            this.checkStageTypeVar = 'disable';
                            break;
                        case 'level':
                            this.checkStage = true;
                            this.stagesLevel = this.levels[0].id;
                            this.checkStageTypeVar = 'level';
                            break;
                        case 'stage':
                            this.checkStage = true;
                            this.stagesLevel = this.levels[0].id;
                            this.stagesStage = this.stages[0].id;
                            this.checkStageTypeVar = 'stage';
                            break
                    }
                }
            },
            levels: {
                get(){
                    const levels = Object.values(this.getLevels);
                    return [...levels].sort((a, b) => a.order - b.order);
                }
            },
            stages: {
                get() {
                    const stages = Object.values(this.levels.find(el => el.id === this.stagesLevel).stages)
                    return [...stages].sort((a, b) => a.order - b.order);
                }
            }
        },
        methods: {
            async refresh() {
                await this.$store.dispatch('LEVELS_GET');
            },
            closeModal() {
                this.pCloseModal();
            },
            setValues(payload, index) {
                let vals = this.values;
                this.value = '';
                vals[index] = payload;
                this.values = vals;
            },
            getLogic() {
                if (this.type === 'no_reports') return 'No';
                return 'Is'
            },
            saveCondition() {
                let isTimeCondition = false;
                let isStageCondition = false;

                let condition = {
                    type: 'and',
                    meta: 'condition',
                    conditions: []
                }

                let report = {meta: 'report', type: 'report', report_type: this.reportType}
                let condition_time = {meta: 'condition'}

                let condition_stages = {meta: 'stage', type:'stage'}

                if (this.checkStage) {
                    let level = this.levels.find(el => el.id === this.stagesLevel)
                    let text = level.order
                    isStageCondition = true;
                    if (this.checkStageTypeVar === 'level') {
                        condition_stages.level = this.stagesLevel;
                        condition_stages.text = 'level: ' + text;
                    }
                    if (this.checkStageTypeVar === 'stage') {
                        condition_stages.stage = this.stagesStage;
                        condition_stages.stage = this.stagesStage;
                        condition_stages.text = 'stage: ' + text +'.' + level.stages.find(el=>el.id === this.stagesStage).order
                    }

                }
                if (this.checkDays) {
                    isTimeCondition = true
                    condition_time.days = this.condition_days;
                }
                switch (this.conditionType) {
                    case 'Between': // between 2 dates
                        condition_time.time_in_range = JSON.parse(JSON.stringify(this.value));
                        condition_time.type = 'date_time';
                        isTimeCondition = true;
                        this.$emit('setTime', this.value[1])
                        break;
                    case 'Before':
                        condition_time.time_before = this.value;
                        condition_time.type = 'date_time';
                        isTimeCondition = true;
                        this.$emit('setTime', this.value)
                        break;
                    case 'After':
                        condition_time.time_after = this.value;
                        condition_time.type = 'date_time';
                        isTimeCondition = true;
                        break;
                    case 'More': // more than value
                        report.value = this.value;
                        report.comparator = 'rt'
                        break;
                    case 'Less': // less than value
                        report.value = this.value;
                        report.comparator = 'lt'
                        break;
                    case 'Equal': // equal value
                        report.value = this.value;
                        report.comparator = 'eq'
                        break;
                    case 'Less_yesterday':
                        report.comparator = 'lty';
                        break
                    case 'More_yesterday':
                        report.comparator = 'rty';
                        break;
                    case 'In_yesterday_range':
                        report.comparator = 'eqy'
                        break;
                }

                condition.conditions.push(report);

                if (isTimeCondition) {
                    condition.conditions.push(condition_time);
                }
                if (isStageCondition) {
                    condition.conditions.push(condition_stages);
                }

                this.$emit('change', condition);
                this.closeModal();
            }
        }
    }
</script>

<style scoped>

</style>
