<template>
    <section>
        <b-button type="is-rounded is-success" @click="createCondition">add condition</b-button>
        <b-modal :active.sync="isCompModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-condition-form
                    :pCloseModal="closeModal"
                    :fdata="formData"
                    :isEdit="isEdit">
            </add-condition-form>
        </b-modal>
        <b-table :data="statesMachineGetStates"
                 :current-page.sync="currentPage"
                 @filters-change="filterKey+=1">
            <template slot-scope="props">
                <b-table-column field="name" label="Condition Name" sortable searchable>
                    {{ props.row.name}}
                    <div class="conditions">
                        <view-conditions v-if="props.row.trigger_type!=='time'" :condition="props.row.condition" :type="props.row.trigger_type" :key="props.row.id+' '+filterKey" />
                        <b v-if="props.row.trigger_type==='time'">trigger time: {{props.row.trigger_time}}</b>
                    </div>
                </b-table-column>
                <b-table-column field="trigger_type" label="Type" sortable searchable>
                    {{props.row.trigger_type}}
                </b-table-column>
                <b-table-column field="response" label="Actions Names" sortable searchable>
                    <span v-for="(action, key) in props.row.actions" :key="key">
                        <span v-if="key>0">, </span>{{ action.name}}
                    </span>
                </b-table-column>
                <b-table-column label="Actions" class="buttons-block">
                    <b-button type="is-rounded is-small is-success" @click="editCondition(props.row.id)">Edit</b-button>
                    <b-button type="is-rounded is-small is-danger" @click="confirmCustomDelete(props.row.id)">Delete</b-button>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex';

    import AddConditionForm from "./AddConditionsForm";
    import ViewConditions from "./ViewConditions";
    import RespInfo from "../../utils/respinfo";

    export default {
        name: "StatesMachineTable",
        components: {
            AddConditionForm,
            ViewConditions,
        },
        data() {
            return {
                currentPage: 1,
                isCompModalActive: false,
                formData: null,
                isEdit: false,
                filterKey: 0,
            }
        },
        created() {
            this.refresh();
        },
        computed: {
            ...mapGetters(['statesMachineGetStates']),
        },
        methods: {
            closeModal() {
                this.isCompModalActive = false;
            },
            createCondition() {
                this.formData = null;
                this.isEdit = false;
                this.isCompModalActive = true;
            },
            editCondition(id) {
                this.formData = this.statesMachineGetStates.find(el => el.id === id)
                this.isEdit = true;
                this.isCompModalActive = true;
            },
            refresh(){
                this.$store.dispatch('STATES_MACHINE_GET');
            },
            confirmCustomDelete(id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete action trigger',
                    message: 'Are you sure you want to <b>delete</b> action trigger? This action cannot be undone.',
                    confirmText: 'Delete action trigger',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.removeTrigger(id)
                })
            },
            async removeTrigger(id) {
                let resp = await this.$store.dispatch('STATES_MACHINE_DELETE', id);
                this.$buefy.toast.open(RespInfo(resp));
                this.refresh();
            },
        }
    }
</script>

<style scoped>
    .buttons-block {
        width: 200px
    }

    .conditions {
        width: 100%;
    }

    .condition {
        display: inline-block;
        border: 1px solid lightgray;
        padding: 3px;
        margin-right: 5px;;

    }
</style>