<template>
    <section>
        <view-condition v-if="condition.meta==='condition'" :condition="condition" :type="type" />
        <view-conditions-block v-if="condition.meta==='block'" :block="condition" />
    </section>
</template>

<script>
    import ViewCondition from "./ViewCondition";
    import ViewConditionsBlock from "./ViewConditionsBlock";

    export default {
        name: "ViewConditions",
        components:{
            ViewConditionsBlock,
            ViewCondition,
        },
        props:{
            condition:{},
            type: {
                type: String,
                default: 'report'
            }
        }
    }
</script>

<style scoped>

</style>