<template>
    <div class="time_in_programm_card">
        <div class="time_in_programm_date">
            {{ day }}
        </div>
        <div class="time_in_programm">{{timeInTheProgram(ptime)}}</div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "TimeInProgramm",
        props: {
            ptime: String,
        },
        data() {
            return {
                day: '',
            }
        },
        created() {
            this.day = moment(this.ptime).format("DD/MM/YYYY");
        },
        methods: {
            timeInTheProgram(date) {
                const days = moment().diff(date, 'days');
                let str = ' days'
                if (days == 1) str = ' day'
                return days + str
            }
        }
    }
</script>

<style scoped>
    .time_in_programm_card {
        position: relative;
        display: inline-block;
        height: 80px;
        width: 200px;
        background-image: url("svg/time_in_program_calendar.svg");
        border-left: 1px solid #9A99A2;
        background-repeat: no-repeat;
    }

    .time_in_programm_date {
        position: absolute;
        left: 65px;
        top: 10px;
        font-size: 18px;
        line-height: 18px;
        /* or 100% */
        align-items: center;
        letter-spacing: -0.08px;
        color: #9A99A2;
    }

    .time_in_programm {
        position: absolute;
        left: 65px;
        top: 35px;
        font-weight: 500;
        font-size: 31px;
        line-height: 18px;
        /* or 58% */
        letter-spacing: -0.08px;
        color: #5C5C5C;
    }
</style>