<template>
    <section>
        <b-modal :active.sync="isModalActive">
            <b-message :active.sync="isMessageActive" title="Warning" type="is-warning" :has-icon="hasIcon">
                You don't authorize. Log in, please
            </b-message>
        </b-modal>
    </section>
</template>

<script>
    export default {
        name: "BlockedPage",
        data() {
            return {
                isModalActive: true,
                isMessageActive: true,
                hasIcon: true
            }
        },
        watch: {
            isModalActive: function () {
                if (!this.isModalActive) {
                    this.$router.push('/');
                }
            },
            isMessageActive: function () {
                if (!this.isMessageActive) {
                    this.$router.push('/');
                }
            }
        }
    }
</script>

<style scoped>

</style>