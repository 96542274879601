<template>
    <form action="" @submit.prevent="createConditions">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
            </header>
            <section class="modal-card-body">
                <b-field label="Name" horizontal>
                    <b-input v-model="condition.name"></b-input>
                </b-field>
                <b-field label="Type" horizontal>
                    <b-select v-model="triggerType">
                        <option value="report">report</option>
                        <option value="time">time</option>
                        <option value="no_reports">no reports</option>
                    </b-select>
                </b-field>
                <b-field v-if="condition.trigger_type!='report'" label="Time" horizontal>
                    <b-timepicker v-model="triggerTime" :disabled="triggerType==='no_reports'"></b-timepicker>
                </b-field>
                <section v-if="condition.trigger_type!='time'">
                    <div v-if="condition.condition.meta === undefined">
                        <div class="condition">
                            <b-button type="is-rounded is-success" @click="addCondition">add condition</b-button>
                            <b-button v-if="condition.trigger_type==='report'" type="is-rounded is-success" @click="addLogicBlock">add logic block</b-button>
                        </div>
                    </div>
                    <div v-if="condition.condition.meta === 'condition'">
                        <view-condition :condition="condition.condition" :type="condition.trigger_type" :key="conditionKey"/>
                        <b-button type="is-success is-rounded is-small" @click="editCondition()">edit</b-button>
                        <b-button type="is-danger is-rounded is-small" @click="deleteBlock(0)">delete</b-button>
                    </div>
                    <div v-if="condition.condition.meta === 'block'">
                        <add-condition-block :block="condition.condition"
                                             :level="1"
                                             :index="0"
                                             @change="saveCondition"
                                             :delete-me="deleteBlock"
                                             :key="conditionKey+'_'+0">

                        </add-condition-block>
                    </div>
                </section>
                <div class="actions">
                    <div v-for="(act, key) in actions" :key="key" class="action">
                        <div class="buttons">
                            <b-button @click="editAction(key)" type="is-small is-rounded is-success">Edit</b-button>
                            <b-button @click="deleteAction(key)" type="is-small is-rounded is-danger">Delete</b-button>
                        </div>
                        <section v-if="act.hasOwnProperty('name')">
                            {{ act.name}}
                        </section>
                        <section v-else>
                            No name
                        </section>
                    </div>
                    <b-button @click="addTemplate" type="is-success is-rounded is-small">Add Template</b-button>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                <button class="button is-rounded is-success">Save</button>
            </footer>
        </div>
        <b-modal :active.sync="isCompModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-condition-form
                    :pCloseModal="closeChildModal"
                    :fdata="formData"
                    :isEdit="isConditionEdit"
                    :type="condition.trigger_type"
                    @setTime="saveTime($event)"
                    @change="saveCondition($event)">
            </add-condition-form>
        </b-modal>
        <add-template-form v-if="isTemplateActive"
                           :is-active="isTemplateActive"
                           :data="action"
                           :is-edit="isActionEdit"
                           @close="isTemplateActive=false"
                           @save="saveAction"
        />
    </form>
</template>

<script>
    import {mapGetters} from 'vuex';
    import AddConditionBlock from "./AddConditionBlock";
    import AddConditionForm from "./AddConditionForm";
    import AddTemplateForm from "../ChatTemplates/AddTemplateForm";
    import ViewCondition from "./ViewCondition";

    var moment = require('moment');

    export default {
        name: "AddConditionsForm",
        components: {
            ViewCondition,
            AddTemplateForm,
            AddConditionBlock,
            AddConditionForm,
        },
        props: {
            fdata: {},
            isEdit: Boolean,
            pCloseModal: {}
        },
        data() {
            return {
                condition: {
                    name: '',
                    condition: {
                        meta: undefined
                    },
                    actions: [],
                    trigger_type: 'report',
                    trigger_time: '11:30',
                },
                isConditionEdit: false,
                isCompModalActive: false,
                formData: null,

                isTemplateActive: false,
                isActionEdit: false,
                actionIndex: -1,
                action: {},
                conditionKey: 0,
            }
        },
        created() {
            if (this.isEdit) {
                this.condition = JSON.parse(JSON.stringify(this.fdata))
            } else {
                this.condition.owner = self.getAuthId;
            }
        },
        computed: {
            ...mapGetters(['getAuthId']),
            title() {
                if (this.isEdit) return 'Edit Condition';
                return 'Create Condition';
            },
            actions: {
                get() {
                    return this.condition.actions;
                }
            },
            triggerTime: {
                get() {
                    return new Date(moment(this.condition.trigger_time, "HH:mm"))
                },
                set(payload) {
                    this.condition.trigger_time = moment(payload).format("HH:mm")
                }
            },
            triggerType: {
                get(){
                    return this.condition.trigger_type
                },
                set(payload) {
                    this.condition.trigger_type = payload;
                    this.condition.condition = { meta: undefined }
                    this.conditionKey += 1;
                }
            }
        },
        methods: {
            closeModal() {
                this.pCloseModal();
            },
            closeChildModal() {
                this.isCompModalActive = false;
            },
            createConditions() {
                if (this.isEdit) {
                    this.condition.owner = this.getAuthId;
                    this.$store.dispatch('STATES_MACHINE_STATES_EDIT', this.condition)
                } else {
                    this.condition.owner = this.getAuthId;
                    this.$store.dispatch('STATES_MACHINE_STATES_CREATE', this.condition)
                }
                this.closeModal();
            },
            addLogicBlock() {
                let block = {
                    type: 'and',
                    meta: 'block',
                    conditions: [],
                };
                this.condition.condition = Object.assign({}, block);
            },
            addCondition() {
                this.isConditionEdit = false;
                this.formData = null;
                this.isCompModalActive = true;
            },
            editCondition() {
                this.isConditionEdit = true;
                this.formData = this.condition.condition;
                this.isCompModalActive = true;
            },
            deleteBlock(key) {
                if (key === 0) {
                    let block = {
                        type: undefined
                    }
                    this.condition.condition = block;
                }
            },
            saveCondition(payload) {
                this.condition.condition = Object.assign({}, payload)
                this.conditionKey += 1;
            },
            saveTime(payload){
                this.condition.trigger_time = payload;
                this.conditionKey += 1;
            },
            addTemplate() {
                this.action = {};
                this.actionIndex = -1;
                this.isActionEdit = false;
                this.isTemplateActive = true;
            },
            editAction(index) {
                this.action = JSON.parse(JSON.stringify(this.condition.actions[index]))
                this.actionIndex = index;
                this.isActionEdit = true;
                if (this.action.type === 'send_response_template') {
                    this.isTemplateActive = true;
                }
            },
            saveAction(payload) {
                let index = payload.index;
                let action = payload.action;
                if (this.isActionEdit) {
                    this.condition.actions.splice(index, 1, action)
                } else {
                    this.condition.actions.push(action)
                }
            },
            deleteAction(index) {
                this.condition.actions.splice(index, 1);
            }

        }
    }
</script>

<style scoped>
    .condition {
        padding: 3px;
        border: 1px solid lightgray;
        margin: 2px;
    }

    .actions {
        padding: 3px;
        border: 1px solid lightgray;
        margin: 2px;
        text-align: left;
    }

    .action {
        padding: 3px;
        border: 1px solid lightgray;
        margin: 2px;
        min-height: 35px;
    }

    .buttons {
        float: right;
    }
</style>