import { render, staticRenderFns } from "./AddQuestionForm.vue?vue&type=template&id=934c6058&scoped=true"
import script from "./AddQuestionForm.vue?vue&type=script&lang=js"
export * from "./AddQuestionForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "934c6058",
  null
  
)

export default component.exports