<template>
    <b-modal :active="isActive">
        <form action="" @submit.prevent="closeModal">
            <div class="modal-card" style="width: auto; height: 100%">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{title}} #{{index+1}}</p>
                </header>
                <section class="modal-card-body">
                    <b-field grouped>
                        <b-field type="is-success is-small" calss="inline" label="name" label-position="on-border">
                            <b-input v-model="actionName" :disabled="!hasPermission('write:stages')">
                            </b-input>
                        </b-field>
                        <b-field type="is-success is-small" calss="inline" label="report type"
                                 label-position="on-border">
                            <b-select v-model="actionType" :disabled="!hasPermission('write:stages')">
                                <option>glucose</option>
                                <option>sleep</option>
                                <option>exercise</option>
                                <option>blood_pressure</option>
                                <option>medicine</option>
                                <option>supplement</option>
                                <option>weight</option>
                                <option>food</option>
                                <option>water</option>
                            </b-select>
                        </b-field>
                        <b-field type="is-success is-small" calss="inline" label="stage" label-position="on-border">
                            <b-input v-model="stageId" :disabled="true">
                            </b-input>
                        </b-field>
                    </b-field>
                    <b-field grouped>
                        <b-field v-if="isTimeValid" type="is-success is-small" class="picker" label="start"
                                 label-position="on-border">
                            <b-timepicker v-model="actionStartTime" hour-format="24" editable
                                          :disabled="!hasPermission('write:stages')">
                            </b-timepicker>
                        </b-field>
                        <b-field v-else type="is-danger is-small" class="picker" message="must be < end time"
                                 label="start"
                                 label-position="on-border">
                            <b-timepicker v-model="actionStartTime" hour-format="24" editable
                                          :disabled="!hasPermission('write:stages')">
                            </b-timepicker>
                        </b-field>
                        <b-field v-if="isTimeValid" type="is-success is-small" class="picker" label="end"
                                 label-position="on-border">
                            <b-timepicker v-model="actionEndTime" hour-format="24" editable
                                          :disabled="!hasPermission('write:stages')"></b-timepicker>
                        </b-field>
                        <b-field v-else type="is-danger is-small" class="picker" message="must be > from time"
                                 label="end"
                                 label-position="on-border">
                            <b-timepicker v-model="actionEndTime" hour-format="24" editable
                                          :disabled="!hasPermission('write:stages')"></b-timepicker>
                        </b-field>
                        <b-field v-if="isRewardValid" type="is-success is-small" class="picker" label="reward"
                                label-position="on-border">
                            <b-input class="picker" v-model="actionReward" :disabled="!hasPermission('write:stages')"/>
                        </b-field>
                        <b-field v-else type="is-danger is-small" class="picker" label="reward"
                                label-position="on-border">
                            <b-input class="picker" v-model="actionReward" :disabled="!hasPermission('write:stages')"/>
                        </b-field>
                    </b-field>
                    <b-field grouped>
                        <b-select :value="checkDaysTypeVar" v-model="checkDaysType" size="is-small">
                            <option value="disable">Disable</option>
                            <option value="specify">Specify</option>
                            <option value="work">Work Days</option>
                            <option value="all">All Days</option>
                            <option value="clear">Clear</option>
                        </b-select>
                        <b-field>
                            <span v-for="(day, key) in days" v-bind:key="key">
                                <b-checkbox-button v-model="condition_days" :native-value="day" size="is-small"
                                                   type="is-success"
                                                   :disabled="!checkDays"><b>{{weekdays[day]}}</b>
                                </b-checkbox-button>
                            </span>
                        </b-field>
                    </b-field>
                    <table class="table">
                        <stage-action-condition-edit
                                v-for="(context, key) in contexts"
                                :key="key +'_'+ stageActionKey"
                                :context="context"
                                :contxts="contexts"
                                :action="action"
                                :stage-id="stageId"
                                :level-id="levelId"
                                :stage-title = "stageTitle"
                                :level-name = "levelName"
                                @save="saveActionCondition"
                                @remove="removeActionCondition"
                        />
                    </table>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                    <button class="button is-rounded is-success">Save</button>
                </footer>
            </div>
        </form>
        <add-template-form v-if="isTemplateActive"
                           :is-active="isTemplateActive"
                           :data="message"
                           :is-edit="isTemplateEdit"
                           @close="isTemplateActive=false"
                           @save="saveActionMessage"
        />
    </b-modal>
</template>

<script>
    import {mapGetters} from 'vuex'
    import moment from "moment";
    import AddTemplateForm from "../ChatTemplates/AddTemplateForm";
    import StageActionConditionEdit from "./StageActionConditionEdit";

    export default {

        name: "StageActionEdit",
        components: {StageActionConditionEdit, AddTemplateForm},
        props: {
            isActive: Boolean,
            levelId: Number,
            stageId: Number,
            index: Number,
            stageTitle: String,
            levelName: String,
        },
        data() {
            return {
                title: 'Edit Action',
                days: [6, 0, 1, 2, 3, 4, 5],
                weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                stageActionKey: 0,
                checkDaysTypeVar: 'specify',
                checkDays: true,
                isTemplateActive: false,
                message: 0,
                isTemplateEdit: true,
                editMessageContext: "on_time",
                on_time_message: {},
                contexts_glucose: ['on_time', 'no_report', 'value_less', 'value_more', 'in_yesterday_range', 'less_yesterday_range', 'more_yesterday_range'],
                contexts_water: ['on_time', 'no_report', 'value_less', 'value_more'],
                //'value_in_range',
                contexts_others: ['on_time', 'no_report'],
                nameOfContext: {
                    'on_time': "On time",
                    'no_report': "No reports",
                    'value_less': "Value less than",
                    'value_more': "Value more than",
                    'value_in_range': "Value in range",
                    'in_yesterday_range':'In yesterday range'
                }
            }
        },
        computed: {
            ...mapGetters(['getStageAction', 'hasPermission']),
            contexts: {
                get() {
                    if (this.actionType == 'glucose') return this.contexts_glucose;
                    if (this.actionType == 'water') return this.contexts_water;
                    return this.contexts_others;
                }
            },
            action:{
                get() {
                    return this.getStageAction(this.stageId, this.index)
                },
            },
            actionName: {
                get() {
                    return this.getStageAction(this.stageId, this.index).name
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {name: value}
                    })
                    this.stageActionKey++;
                }
            },
            actionType: {
                get() {
                    return this.getStageAction(this.stageId, this.index).action
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {action: value}
                    })
                    this.stageActionKey++;
                }
            },
            actionStartTime: {
                get() {
                    return new Date(moment(this.getStageAction(this.stageId, this.index).start_time, 'HH:mm'));
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {start_time: moment(value).format('HH:mm')}
                    })
                    this.stageActionKey++;
                }
            },
            actionEndTime: {
                get() {
                    return new Date(moment(this.getStageAction(this.stageId, this.index).end_time, 'HH:mm'));
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {end_time: moment(value).format('HH:mm')}
                    })
                    this.stageActionKey++;
                }
            },
            actionConditions: {
                get() {
                    let stageAction = this.getStageAction(this.stageId, this.index);
                    if (stageAction.hasOwnProperty('triggers')) {
                        return stageAction.triggers
                    }
                    return {}
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {triggers: value}
                    })
                    this.stageActionKey++;
                }
            },
            conditionDaysVar: {
                get() {
                    let stageAction = this.getStageAction(this.stageId, this.index);
                    if (stageAction.hasOwnProperty('weekdays')) {
                        return stageAction.weekdays
                    }
                    return []
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {weekdays: value}
                    })
                    this.stageActionKey++;
                }
            },
            checkDaysType: {
                get() {
                    return this.checkDaysTypeVar;
                },
                set(payload) {
                    switch (payload) {
                        case 'disable':
                            this.checkDays = false;
                            this.conditionDaysVar = [];
                            break;
                        case 'specify':
                            this.checkDays = true;
                            break;
                        case 'work':
                            this.checkDays = true;
                            this.conditionDaysVar = [6, 0, 1, 2, 3];
                            break;
                        case 'all':
                            this.checkDays = true;
                            this.conditionDaysVar = [0, 1, 2, 3, 4, 5, 6];
                            break;
                        case 'clear':
                            this.checkDays = true;
                            this.conditionDaysVar = [];
                            break;
                    }
                    this.checkDaysTypeVar = payload;
                }
            },
            actionReward: {
                get() {
                    return this.getStageAction(this.stageId, this.index).reward
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {reward: value}
                    })
                }
            },
            condition_days: {
                get() {
                    return this.conditionDaysVar;
                },
                set(payload) {
                    this.conditionDaysVar = payload
                    this.checkDaysType = 'specify'
                }
            },
            isActionValid() {
                const action = this.getStageAction(this.stageId, this.index);
                if (action !== undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return Object.keys(action.errors).length == 0
                    }
                }
                return true
            },
            isTimeValid() {
                const action = this.getStageAction(this.stageId, this.index);
                if (action != undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return !action.errors.hasOwnProperty('time')
                    }
                }
                return true
            },
            isRewardValid() {
                const action = this.getStageAction(this.stageId, this.index);
                if (action != undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return !action.errors.hasOwnProperty('reward')
                    }
                }
                return true
            },
        },
        methods: {
            closeModal() {
                this.$emit('close')
            },
            saveActionCondition(payload){
                let actionConditions = JSON.parse(JSON.stringify(this.actionConditions))
                actionConditions[payload['context']] = JSON.parse(JSON.stringify(payload['trigger']));
                this.actionConditions = actionConditions;
            },
            removeActionCondition(payload){
                let actionConditions = JSON.parse(JSON.stringify(this.actionConditions))
                if (actionConditions[payload['context']]) {
                  delete actionConditions[payload['context']]
                  this.actionConditions = actionConditions;
                }
            },
        }
    }
</script>

<style scoped>

</style>
