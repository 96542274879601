<template>
    <section>
        <div class="nutrition-list-item">
            <div class="nutrition-list-item-name">{{name}}</div>
            <div class="nutrition-list-item-buttons">
                <b-field grouped>
                    <b-field horizontal class="is-small">
                        <b-checkbox v-model="yes">Yes</b-checkbox>
                    </b-field>
                    <b-field horizontal class="is-small">
                        <b-checkbox v-model="no">No</b-checkbox>
                    </b-field>
                </b-field>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "ClientNutritionInstructionItem",
        props: {
            id: Number,
            user_id: String,
        },
        computed: {
            ...mapGetters(['getNutrition', 'getClientNutritionInstruction']),
            name() {
                return this.getNutrition(this.id).name
            },
            yes: {
                get() {
                    const nutrition = this.getClientNutritionInstruction(this.id);
                    if (nutrition !== undefined) {
                        return this.getClientNutritionInstruction(this.id).role == 1;
                    }
                    return false
                },
                set(value) {
                    this.updateNutritionInstruction('yes', value);
                }
            },
            no:{
                get() {
                    const nutrition = this.getClientNutritionInstruction(this.id);
                    if (nutrition !== undefined) {
                        return this.getClientNutritionInstruction(this.id).role == 2;
                    }
                    return false
                },
                set(value) {
                    this.updateNutritionInstruction('no', value);
                }
            }
        },
        methods:{
            async updateNutritionInstruction(roleType, roleValue) {
                let role = 0;
                if (roleType==='yes'&&roleValue===true) role = 1;
                if (roleType==='no'&&roleValue===true) role = 2;

                let oldInstruction = this.getClientNutritionInstruction(this.id);
                let nutritionInstruction = {};
                if (oldInstruction === undefined ) {
                    nutritionInstruction.item_id = this.id;
                } else {
                    nutritionInstruction = Object.assign({}, oldInstruction);
                }
                nutritionInstruction.role = role;
                await this.$store.dispatch("CLIENT_NUTRITION_INSTRUCTION_UPDATE", {user: this.user_id, instruction: nutritionInstruction});
                this.$emit('update')
            }
        }
    }
</script>

<style scoped>
    .nutrition-list-item {
        height: 45px;
        padding: 7px;
        margin: 2px;
        border: 1px solid lightgray;
    }

    .nutrition-list-item-name {
        font-weight: bold;
        float: left;
        padding-left: 10px;
    }

    .nutrition-list-item-buttons {
        float: right;
        padding-right: 5px;
    }
</style>