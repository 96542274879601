<template>
    <div v-if="user !== undefined" class="tile is-ancestor is-12" :style="height">
        <password-change-modal :isActive="password_modal" :userId="user_id" v-on:close="password_modal=false"/>
        <div class="tile is-parent is-4">
            <div v-if="user.chats !== undefined" class="tile is-child">
                <b-button @click="chatLink">chat</b-button>
                <client-chat v-if="user.chats.find(el=>el.type === 0) !== undefined"
                             :clientId="user.user_id"
                             :room_id="user.chats.find(el=>el.type === 0).id"/>
            </div>
        </div>
        <div class="tile is-parent is-vertical is-8">
            <div class="tile is-child" style="position: relative">
                <div class="client_info">
                    <div class="client_card">
                        <img :src="user.picture"/>
                        <div class="client_card_header">Patient:</div>
                        <div class="client_card_name"> {{user.name}}</div>
                        <div class="client_card_info">
                            <span v-if="user.user_metadata.hasOwnProperty('gender')">
                                <span v-if="user.user_metadata.gender=='MALE'">
                                    <b-icon icon="gender-male" size="is-small"/> Male
                                </span>
                                <span v-if="user.user_metadata.gender=='FEMALE'">
                                    <b-icon icon="gender-female" size="is-small"/> Female
                                </span>
                            </span>
                            <span v-if="user.user_metadata.hasOwnProperty('birth_date')">
                                {{ formatDate(user.user_metadata.birth_date)}}
                                ({{getAgeString(user.user_metadata.birth_date)}})
                            </span>
                        </div>
                        <div class="client_card_buttons">
                            <div class="card_block"><br />
                                <b>Helsinki:</b><b-checkbox v-model="helsinki" size="is-small" :disabled="!hasPermission('write:users')"></b-checkbox>
                            </div>
                            <div class="card_block"><br />
                                <b-button @click="password_modal=true" type="is-success is-rounded is-small" >change password</b-button>
                            </div>
                            <div class="card_block"><br />
                                <b-button tag="a" :href="'/client/'+user_id+'/stages'" type="is-success is-rounded is-small">stages</b-button>
                            </div>
                            <div class="card_block" v-if="user.user_metadata.hasOwnProperty('status')">
                                <i>status:</i> <b>{{user.user_metadata.status}}</b><br />
                                <b-button v-if="user.user_metadata.status=='active'"
                                          type="is-danger is-rounded is-small" @click="confirmMarkUserAsComplete">complete client</b-button>
                            </div>
                        </div>
                    </div>
                    <div class="client_info_card">
                        <div>
                            <glucose-level :user_id="user_id" :created="user.created_at"/>
                        </div>
                        <div class="lineabove">
                            <time-in-programm :ptime="user.created_at"/>
                            <client-level-points :stage="user.stages"/>
                            <client-weight-height
                                    v-if="user_initial_weight !== undefined"
                                    :height="user_height"
                                    :weight="user_initial_weight"
                                    caption="Original"/>
                            <client-weight-height
                                    v-if="user_weight !== undefined"
                                    :height="user_height"
                                    :weight="user_weight"
                                    caption="Current"/>
                        </div>
                        <div class = "lineabove">
                            <client-blood-pressure />

                        </div>
                    </div>
                </div>
                <div class="client_calendar">
                    <b-tabs type="is-boxed" style="height:100%; ">
                        <b-tab-item label="Chart" style="height:100%;">
                            <client-glucose-chart :user_id="user.user_id" :created="user.created_at"/>
                        </b-tab-item>
                        <b-tab-item label="Calendar" style="height:100%;">
                            <client-glucose-calendar :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Questionnaries" style="height:100%;">
                            <client-questionnaries :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Medical exams" style="height:100%;">
                            <client-medical-exams :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Nutrition Instructions" style="height:100%;">
                            <client-nutrition-instructions :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Medicine Instructions" style="height:100%;">
                            <client-medicine-instructions :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Supplements Instructions" style="height:100%;">
                            <client-supplements-instructions :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Questionnaires comparison" style="height:100%;">
                            <client-questionnaires-comparison :user_id="user.user_id"/>
                        </b-tab-item>
                        <b-tab-item label="Support contacts" style="height:100%;">
                            <client-support-contacts :meta="user.user_metadata"/>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import moment from 'moment';

    import TimeInProgramm from "../components/ClientPage/TimeInProgramm";
    import GlucoseLevel from "../components/ClientPage/GlucoseLevel";
    import ClientGlucoseCalendar from "../components/ClientPage/ClientGlucoseCalendar";
    import ClientChat from "../components/ClientPage/ClientChat";
    import ClientQuestionnaries from "../components/ClientPage/ClientQuestionnaries";
    import ClientMedicalExams from "../components/ClientPage/ClientMedicalExams";
    import ClientNutritionInstructions from "../components/ClientPage/ClientNutritionInstructions";

    import timeDifference from "../utils/date";
    import ClientLevelPoints from "../components/ClientPage/ClientLevelPoints";
    import ClientWeightHeight from "../components/ClientPage/ClientWeightHeight";
    import RespInfo from "../utils/respinfo";
    import ClientQuestionnairesComparison from "../components/ClientPage/ClientQuestionnairesComparison";
    import ClientBloodPressure from "../components/ClientPage/ClientBloodPressure";
    import ClientGlucoseChart from "../components/ClientPage/ClientGlucoseChart";
    import ClientSupplementsInstructions from "../components/ClientPage/ClientSupplementsInstructions";
    import ClientMedicineInstructions from "../components/ClientPage/ClientMedicineInctructions";
    import ClientSupportContacts from "../components/ClientPage/ClientSupportContacts";
    import PasswordChangeModal from '../components/ClientPage/PasswordChangeModal.vue';

    export default {
        name: "ClientPage",
        components: {
            ClientSupportContacts,
            ClientSupplementsInstructions,
            ClientMedicineInstructions,
            ClientBloodPressure,
            ClientQuestionnairesComparison,
            ClientWeightHeight,
            ClientLevelPoints,
            ClientNutritionInstructions,
            ClientMedicalExams,
            ClientGlucoseCalendar,
            ClientChat,
            ClientQuestionnaries,
            TimeInProgramm,
            GlucoseLevel,
            ClientGlucoseChart,
            PasswordChangeModal,
        },
        data() {
            return {
                height: 0,
                user: undefined,
                glucose_l: 7.5,
                user_id: '',
                user_height: undefined,
                user_initial_weight: undefined,
                user_weight: undefined,
                password_modal: false,
            }
        },
        created() {
            window.addEventListener('resize', this.resizeTable);
            this.resizeTable();
            this.refresh()
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeTable);
        },
        computed: {
            ...mapGetters([
                "getUserById",
                "hasPermission"
            ]),
            helsinki:{
                get(){
                    if (this.user['user_metadata'].hasOwnProperty('experiment')) {
                        return this.user['user_metadata']['experiment'] === 'helsinki';
                    }
                    return false
                },
                set(payload){
                    if(payload) {
                        this.setExperiment('helsinki')
                    } else {
                        this.setExperiment('none')
                    }
                }
            },
        },
        methods: {
            async refresh() {
                this.user_id = this.$route.params.id;
                this.user = this.getUserById(this.user_id);
                window.console.log(this.user);
                if (this.user === undefined || !this.user.user_metadata.hasOwnProperty('status')) {
                    window.console.log('this.user -> undefined');
                    await this.$store.dispatch('USER_GET_BY_ID', this.user_id)
                    this.user = this.getUserById(this.user_id);
                }
                if (this.user.user_metadata.hasOwnProperty('height')) {
                    this.user_height = this.user.user_metadata.height
                    if (this.user.user_metadata.hasOwnProperty('weight')) {
                        this.user_weight = this.user.user_metadata.weight
                        if (this.user.user_metadata.hasOwnProperty('initial_weight')) {
                            this.user_initial_weight = this.user.user_metadata.initial_weight
                        } else {
                            this.user_initial_weight = this.user_weight
                        }
                    }
                }
            },
            async setExperiment(experiment) {
                this.user['user_metadata']['experiment'] = experiment;
                await this.$store.dispatch('USER_SET_EXPERIMENT', {user_id: this.user_id, experiment: experiment});
                await this.refresh();
            },
            resizeTable() {
                this.height = 'height: ' + Math.floor(window.innerHeight) + 'px;';
            },
            timeInTheProgram(param) {
                const date = new Date(param);
                const now = new Date();
                return timeDifference(now, date);
            },
            chatLink() {
                this.$router.push('/chat/' + this.user.chats.find(el => el.type === 0).id);
            },
            sendMessage() {
                this.$store.dispatch('SOCKET_TEST');
            },
            getAgeString(birth_date) {
                const months = moment().diff(birth_date, 'months');
                const years = Math.floor(months / 12)
                const this_year_months = months % 12
                let result = years
                if (this_year_months > 0) result = result + '.' + this_year_months
                return result
            },
            formatDate(date) {
                return moment(date).format("MM/DD/YYYY")
            },
            confirmMarkUserAsComplete() {
                this.$buefy.dialog.confirm({
                    title: 'Mark client as complete',
                    message: 'Are you sure you want to <b>mark current client</b> as complete? This action cannot be undone.',
                    confirmText: 'Confirm',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.markUserAsComplete()
                })
            },
            async markUserAsComplete() {
                let resp = await this.$store.dispatch('USER_COMPLETE', this.user_id);
                this.$buefy.toast.open(RespInfo(resp));
                await this.refresh();
            }
        }
    }
</script>

<style scoped>
    .client_info {
        position: relative;
        min-height: 205px;
        margin-bottom: -285px;
    }

    .client_calendar {
        padding-top: 300px;
        min-height: 700px;
    }

    .client_card {
        position: relative;

        height: 70px;
        text-align: left;
    }

    .client_card img {
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        float: left;
    }


    .client_card_top {
        display: inline-block;
        position: relative;
        height: 60px;
        width: 200px;
    }

    .client_card_header {
        position: absolute;
        top: 10px;
        left: 75px;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        letter-spacing: -0.08px;
        color: #414042;
    }

    .client_card_name {
        position: absolute;
        top: 35px;
        left: 75px;
        font-weight: bold;
        font-size: 20px;
        line-height: 18px;
        /* or 90% */
        align-items: center;
        letter-spacing: -0.08px;
        color: #414042;
    }

    .client_card_info {
        position: absolute;
        top: 55px;
        left: 75px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        /* or 129% */
        letter-spacing: -0.08px;
        color: #5B5B5B;
    }

    .client_card_buttons {
        position: absolute;
        text-align: right;
        top: 5px;
        height: 70px;
        right: 15px;
        width: 500px;
    }

    .card_block {
        position: relative;
        display: inline-block;
        top: 0;
        height: 60px;
        padding: 3px;
    }

    .client_info_card {
        text-align: left;
        padding: 15px;
        min-width: 650px;
        border-radius: 0 70px 0 0;
        background-color: white;
        vertical-align: top;
    }

    .tab-content {
        height: 100%;
        marging-top: -20px;
    }

    .tab-item {
        height: 100%;
    }

    .lineabove {
        margin: 0;
        padding: 5px 0 0 0;
        border-top: 1px solid #9A99A2;
    }
</style>
