<template>
    <tr>
        <td>
            <b-checkbox v-model="enabled"> {{ nameOfContext[context] }}</b-checkbox>
        </td>
        <td>
            <b-field>
                <b-field v-if="context==='value_more'||context==='value_in_range'" label="more"
                         type="is-success is-small" label-position="on-border">
                    <b-numberinput :disabled="!condition.enabled" class="ninput" v-model="more_than" :controls="false"></b-numberinput>
                </b-field>
                <b-field v-if="context==='value_less'||context==='value_in_range'" label="less"
                         type="is-success is-small" label-position="on-border">
                    <b-numberinput :disabled="!condition.enabled" class="ninput" v-model="less_than" :controls="false"></b-numberinput>
                </b-field>
            </b-field>
        </td>
        <td style="text-align: right">
            <span v-if="message.hasOwnProperty('name')">
                {{message.name}}
            </span>
            <span v-else>
                <i>no message</i>
            </span>
            <b-button @click="editActionMessage"
                      :disabled="!condition.enabled"
                      class="is-rounded is-success is-small">
                <b-icon icon="pencil"></b-icon>
            </b-button>
            <b-button v-if="message.hasOwnProperty('name')" @click="deleteActionMessage()"
                      class="is-rounded is-danger is-small">
                <b-icon class="is-small is-danger" icon="close-circle"></b-icon>
            </b-button>
        </td>
        <td>
            <b-field label="points" type="is-success is-small" label-position="on-border">
                <b-numberinput v-model="points" :disabled="!condition.enabled" class="ninput" :controls="false">points</b-numberinput>
            </b-field>
        </td>
        <td>
            <b-button @click="saveCondition" type="is-small is-rounded is-success" :disabled="!condition.enabled">save</b-button>
        </td>
        <add-template-form v-if="isTemplateActive"
                           :is-active="isTemplateActive"
                           :data="message"
                           :is-edit="isTemplateEdit"
                           @close="isTemplateActive=false"
                           @save="saveActionMessage"
        />
    </tr>
</template>

<script>
    import AddTemplateForm from "../ChatTemplates/AddTemplateForm";
    import {mapGetters} from "vuex";

    export default {
        name: "StageActionConditionEdit",
        components:{
            AddTemplateForm
        },
        props: {
            context: String,
            action: {},
            stageName: String,
            stageId: Number,
            stageTitle: String,
            levelName: String,
        },
        data() {
            return {
                nameOfContext: {
                    'on_time': "On time",
                    'no_report': "No reports",
                    'value_less': "Value less than",
                    'value_more': "Value more than",
                    'value_in_range': "Value in range",
                    'in_yesterday_range': 'In yesterday range',
                    'less_yesterday_range': 'Less than yesterday',
                    'more_yesterday_range': 'More than yesterday'
                },
                condition: {
                    enabled: false,
                    reward: 0,
                },
                isTemplateActive: false,
                isTemplateEdit: true,
                triggerId: 0,
            }
        },
        created() {
            if (this.action.hasOwnProperty('triggers')) {
                if (this.action['triggers'].hasOwnProperty(this.context)) {
                    this.condition = JSON.parse(JSON.stringify(this.action.triggers[this.context]))
                }
            }
        },
        methods: {
            saveActionMessage(payload) {
                this.message = payload.action;
            },
            editActionMessage() {
                this.isTemplateActive = true;
            },
            deleteActionMessage() {
                this.message ={ type: undefined};
            },
            async saveCondition() {
                this.condition['message'] = this.message;
                let condition = {
                    'meta': 'condition',
                    'type': 'and',
                    'conditions': [],
                }

                let reportCondition = {
                    'meta': 'report',
                    'report_type': this.action['action']+'_report',
                    'type': 'report'
                }

                let triggerType = 'report';
                switch (this.context) {
                    case 'on_time':
                        break;
                    case 'no_report':
                        triggerType = 'no_reports';
                        break;
                    case 'value_less':
                        reportCondition['comparator'] = 'lt';
                        reportCondition['value'] = String(this.less_than);
                        break;
                    case 'value_more':
                        reportCondition['comparator'] = 'rt';
                        reportCondition['value'] = String(this.more_than);
                        break;
                    case 'value_in_range':
                        break;
                    case 'in_yesterday_range':
                        reportCondition['comparator'] = 'eqy';
                        break
                    case 'less_yesterday_range':
                        reportCondition['comparator'] = 'lty';
                        break;
                    case 'more_yesterday_range':
                        reportCondition['comparator'] = 'rty';
                        break;
                }
                condition.conditions.push(reportCondition)
                let timeCondition = {
                    'meta': 'condition',
                    'time_in_range': [this.action['start_time'],this.action['end_time']],
                    'type': 'date_time'
                }
                if (this.action.hasOwnProperty('weekdays')) {
                    if (this.action['weekdays'].length > 0){
                        timeCondition.days = this.action['weekdays']
                    }
                }
                condition.conditions.push(timeCondition)

                let stageCondition = {
                    'stage': this.stageId,
                    'meta': 'stage',
                    'text': 'stage: '+ this.stageTitle,
                    'type': 'stage',
                }
                condition.conditions.push(stageCondition)


                let actionTrigger = {
                    'trigger_type': triggerType,
                    'trigger_time': this.action['end_time'],
                    'name': 'stage: ' + this.stageTitle + ', action: '+ this.action['name'] + ' ' + this.nameOfContext[this.context],
                    'condition': condition,
                    'owner': this.getAuthId,
                }

                if (this.message['type']!==undefined) {
                    actionTrigger['actions'] = [ this.message ]
                } else {
                    actionTrigger['actions'] = [];
                    delete this.condition['message'];
                }
                if (this.condition.hasOwnProperty('trigger_id')) {
                    actionTrigger.id = this.condition['trigger_id']
                    await this.$store.dispatch('STATES_MACHINE_STATES_EDIT', actionTrigger)
                } else {
                    let saved_Trigger = await this.$store.dispatch('STATES_MACHINE_STATES_CREATE', actionTrigger)
                    if (saved_Trigger.hasOwnProperty('id')){
                        this.condition['trigger_id'] = saved_Trigger['id']
                    }
                }
                this.$emit('save', { 'context': this.context, 'trigger': this.condition})
            }
        },
        computed: {
            ...mapGetters(['getAuthId']),
            enabled:{
                get(){
                    if (this.condition.hasOwnProperty('enabled')) {
                        return this.condition['enabled'];
                    }
                    return false;
                },
                set(payload){
                    let condition = JSON.parse(JSON.stringify(this.condition))
                    condition['enabled'] = payload;
                    this.condition = condition
                    if (!payload) {
                        if (this.condition.hasOwnProperty('trigger_id')) {
                            this.$store.dispatch('STATES_MACHINE_DELETE', this.condition['trigger_id'])
                        }
                        this.$emit('remove', {'context': this.context})
                    }
                }
            },
            points:{
                get(){
                    return this.condition['reward'];
                },
                set(payload){
                    let condition = JSON.parse(JSON.stringify(this.condition))
                    condition['reward'] = payload;
                    this.condition = condition
                }
            },
            message: {
                get(){
                    if (this.condition.hasOwnProperty('message')) {
                        return this.condition['message'];
                    }
                    return { type: undefined}
                },
                set(payload){
                    let condition = JSON.parse(JSON.stringify(this.condition))
                    condition['message'] = payload;
                    this.condition = condition
                }
            },
            less_than: {
                get(){
                    if (this.condition.hasOwnProperty('less_than')) {
                        return this.condition['less_than'];
                    }
                    return 0
                },
                set(payload){
                    let condition = JSON.parse(JSON.stringify(this.condition))
                    condition['less_than'] = payload;
                    this.condition = condition
                }
            },
            more_than: {
                get(){
                    if (this.condition.hasOwnProperty('more_than')) {
                        return this.condition['more_than'];
                    }
                    return 0
                },
                set(payload){
                    let condition = JSON.parse(JSON.stringify(this.condition))
                    condition['more_than'] = payload;
                    this.condition = condition
                }
            }
        }
    }
</script>

<style scoped>
    .ninput {
        width: 105px;
    }
</style>
