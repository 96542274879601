<template>
    <div style="margin: 0px; padding: 0px; width: 440px">
        <b-field grouped>
            <b-field type="is-success is-small" style="margin-right: 3px">
                <b>{{index+1}}</b>
            </b-field>
            <b-field class="validateAction">
                <b-icon v-if="isActionValid" icon="check-outline" type="is-success is-small"/>
                <b-icon v-else icon="close-outline" type="is-danger is-small"/>
            </b-field>

            <b-field type="is-success is-small" calss="inline" label="action" label-position="on-border">
                <b-select v-model="actionType" :disabled="true">
                    <option>glucose</option>
                    <option>sleep</option>
                    <option>exercise</option>
                    <option>blood_pressure</option>
                    <option>medicine</option>
                    <option>supplement</option>
                    <option>weight</option>
                    <option>food</option>
                    <option>water</option>
                </b-select>
            </b-field>
            <b-field v-if="isTimeValid" type="is-success is-small" class="picker" label="start"
                     label-position="on-border">
                <b-timepicker v-model="actionStartTime" hour-format="24" editable  :disabled="true">
                </b-timepicker>
            </b-field>
            <b-field v-else type="is-danger is-small" class="picker" message="must be < end time" label="start"
                     label-position="on-border">
                <b-timepicker v-model="actionStartTime" hour-format="24" editable  :disabled="true">
                </b-timepicker>
            </b-field>
            <b-field v-if="isTimeValid" type="is-success is-small" class="picker" label="end"
                     label-position="on-border">
                <b-timepicker v-model="actionEndTime" hour-format="24" editable  :disabled="true"></b-timepicker>
            </b-field>
            <b-field v-else type="is-danger is-small" class="picker" message="must be > from time" label="end"
                     label-position="on-border">
                <b-timepicker v-model="actionEndTime" hour-format="24" editable  :disabled="!hasPermission('write:stages')"></b-timepicker>
            </b-field>
            <b-field v-if="isRewardValid" type="is-success is-small" class="picker" label="reward"
                     label-position="on-border">
                <b-input class="picker" v-model="actionReward" :disabled="true"/>
            </b-field>
            <b-field v-else type="is-danger is-small" class="picker" label="reward"
                     label-position="on-border">
                <b-input class="picker" v-model="actionReward" :disabled="true"/>
            </b-field>

            <b-field v-if="hasPermission('write:stages')" type="is-danger is-small" class="picker" style="width: 30px;">
                <b-button type="is-small is-success is-rounded" :disabled="!hasPermission('write:stages')" @click="editAction()">
                    <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button type="is-small is-danger is-rounded" :disabled="isLast" @click="remove"> -</b-button>
            </b-field>
            <stage-action-edit v-if="isStageActionEditActive"
                               :is-active="isStageActionEditActive"
                               :stage-id = "stageId"
                               :level-id = "levelId"
                               :stage-title = "stageTitle"
                               :level-name = "levelName"
                               :index = "index"
                               v-on:close = "isStageActionEditActive = false"
            >
            </stage-action-edit>
        </b-field>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import moment from 'moment';
    import StageActionEdit from "./StageActionEdit";

    export default {
        name: "StageAction",
        components: {StageActionEdit},
        props: {
            index: Number,
            stageId: Number,
            levelId: Number,
            stageTitle: String,
            levelName: String,
            isLast: Boolean,
        },
        data() {
            return {
                isStageActionEditActive: false,
            }
        },
        computed: {
            ...mapGetters(['getStageAction', 'hasPermission']),
            actionType: {
                get() {
                    return this.getStageAction(this.stageId, this.index).action
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {action: value}
                    })
                }
            },
            actionStartTime: {
                get() {
                    return new Date(moment(this.getStageAction(this.stageId, this.index).start_time, 'HH:mm'));
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {start_time: moment(value).format('HH:mm')}
                    })
                }
            },
            actionEndTime: {
                get() {
                    return new Date(moment(this.getStageAction(this.stageId, this.index).end_time, 'HH:mm'));
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {end_time: moment(value).format('HH:mm')}
                    })
                }
            },
            actionName: {
                get() {
                    return this.getStageAction(this.stageId, this.index).name
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {name: value}
                    })
                }
            },
            actionReward: {
                get() {
                    return this.getStageAction(this.stageId, this.index).reward
                },
                set(value) {
                    this.$store.dispatch("UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {reward: value}
                    })
                }
            },
            isActionValid() {
                const action = this.getStageAction(this.stageId, this.index);
                if (action !== undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return Object.keys(action.errors).length == 0
                    }
                }
                return true
            },
            isTimeValid() {
                const action = this.getStageAction(this.stageId, this.index);
                if (action != undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return !action.errors.hasOwnProperty('time')
                    }
                }
                return true
            },
            isRewardValid() {
                const action = this.getStageAction(this.stageId, this.index);
                if (action != undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return !action.errors.hasOwnProperty('reward')
                    }
                }
                return true
            }
        },
        methods: {
            remove() {
                this.$store.dispatch("REMOVE_ACTION", {stageId: this.stageId, levelId: this.levelId, index: this.index})
            },
            editAction() {
                this.isStageActionEditActive = true;
            }
        }
    }
</script>

<style scoped>
    .picker {
        width: 60px;
    }

    .validateAction {
        width: 17px;
        height: 20px;
    }
</style>
