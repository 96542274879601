<template>
    <section>
            <b-field :label="(index+1)+')'">
                <b-input v-model="radio.text" required></b-input>
                <b-input v-model="radio.score"
                         validation-message="Only Numbers is allowed in score"
                         pattern="[0-9]*"
                         required></b-input>
                <b-button v-if="unblocked" @click="removeRadio">Remove</b-button>
            </b-field>
    </section>
</template>

<script>
    export default {
        name: "AddRadioForm",
        data() {
            return {
                radio:{}
            }
        },
        props: {
            value: {},
            index: Number,
            pRemoveRadio: {},
            pUpdate: {},
            unblocked: Boolean,
        },
        created() {
            this.refresh()
        },
        methods: {
            removeRadio() {
                this.pRemoveRadio(this.index);
            },
            refresh() {
                this.radio = this.value;
                this.radio.id = this.index;
                if(!this.radio.hasOwnProperty('score')){
                    this.radio.score = 0
                }
            }
        },
        watch: {
            radio: {
                deep: true,
                handler () {
                    this.pUpdate(this.index, this.radio);
                }
            },
            value: {
                deep: true,
                handler () {
                    this.refresh()
                }
            }
        }
    }
</script>

<style scoped>

</style>