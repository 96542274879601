<template>
    <section>
        <b-button v-if="hasPermission('write:questioner')"
                  type="is-success is-rounded is-medium"
                  width="500px"
                  @click="isEdit=false; isComponentModalActive = true">
            Add Questionary
        </b-button>
        <b-modal :active.sync="isComponentModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-questionary-form
                    :isEdit="isEdit"
                    :fdata="edit_data"
                    :pCloseModal="closeModal"
                    :prefresh="refresh">
            </add-questionary-form>
        </b-modal>
        <b-button v-if="hasPermission('write:questioner')"
                  type="is-danger is-rounded is-small"
                  width="500px"
                  @click="confirmUserQuestonnairesDelete">
            flush active daily questioners
        </b-button>
        <b-collapse
                class="card"
                v-for="(questionary, index) of questionnaries"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index"
                v-bind:class="getStyles(questionary)">
            <div slot="trigger"
                 slot-scope="slot_props"
                 class="card-header"

                 role="button">
                <p class="card-header-title">
                    {{questionary.id +') '+ questionary.title }}
                </p>
                <a class="card-header-icon">
                    <section v-if="hasPermission('write:questioner')">
                        <b-button v-if="!questionary.hasOwnProperty('use_as_start')||questionary.use_as_start!=true"
                                  type="is-success is-rounded is-small"
                                  @click="confirmQuestionnaryStart(questionary.id)">Start
                        </b-button>
                        |
                        <b-button
                                v-if="!questionary.hasOwnProperty('use_as_finish')||questionary.use_as_finish!=true"
                                type="is-success is-rounded is-small"
                                @click="confirmQuestionnaryFinish(questionary.id)">Finish
                        </b-button>
                        |
                        <b-button
                                v-if="!questionary.hasOwnProperty('use_as_daily')||questionary.use_as_daily!=true"
                                type="is-success is-rounded is-small"
                                @click="confirmQuestionnaryDaily(questionary.id)">Daily
                        </b-button>
                        <b-button
                                v-else type="is-danger is-rounded is-small"
                                @click="confirmQuestionnaryDaily(questionary.id)">Disable Daily
                        </b-button>
                        |
                        <b-button type="is-success is-rounded is-small"
                                  @click="editQuestionnarie(props = questionary, edit=true)">Edit
                        </b-button>
                        |
                        <b-button type="is-danger is-rounded is-small" @click="confirmCustomDelete(questionary.id)">
                            Delete
                        </b-button>
                        |
                    </section>
                    <b-icon :icon="slot_props.open ? 'menu-down' : 'menu-up'"></b-icon>
                </a>
            </div>
            <div class="card-content">
                <div class="content">
                    <div v-for="(group, key) in questionary.groups" :key="key" class="groups">
                        <div class="group_id">{{key+1}}</div>
                        <div class="group_content">
                            <div v-for="(qindex,ke) in group" :key="ke">
                                <p><b>{{ qindex }} )</b>{{ questionary.questions.find(el => el.id == qindex).title }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
    </section>
</template>

<script>
    import {
        QUESTIONARY_DELETE,
        QUESTIONARY_GET,
        QUESTIONARY_USE_AT_START,
        QUESTIONARY_USE_AT_FINISH,
        QUESTIONARY_USE_DAILY,
        DAILY_QUESTIONARY_DELETE
    } from "../store/constants";
    import RespInfo from "../utils/respinfo";
    import AddQuestionaryForm from "../components/AddQuestionaryForm";
    import {mapGetters} from 'vuex';

    export default {
        name: 'QuestionaryPage',
        components: {
            AddQuestionaryForm
        },
        props: {},
        data() {
            return {
                isComponentModalActive: false,
                questionnaries: [],
                isOpen: -1,
                edit_data: [],
                isEdit: false
            }
        },
        created() {
            this.isComponentModalActive = false;
            this.refresh();
        },
        computed: {
            ...mapGetters(['hasPermission']),
        },
        methods: {
            async refresh() {
                const resp = await this.$store.dispatch(QUESTIONARY_GET);
                this.$buefy.toast.open(RespInfo(resp));
                this.questionnaries = JSON.parse(JSON.stringify(resp.data));// hack for deep copy
            },
            closeModal: function () {
                this.isComponentModalActive = false;
            },
            editQuestionnarie(props = [], edit = false) {
                this.isEdit = edit;
                this.edit_data = {title: "", questions: []};
                this.edit_data.title = props.title;
                if (edit) {
                    this.$log.info(this.edit_data);
                    this.edit_data.questions = [];
                    this.edit_data.id = props.id;
                    for (let group of props.groups) {
                        if (group.length == 1) {
                            this.edit_data.questions.push(props.questions.find(el => el.id == group[0]));
                        } else {
                            this.edit_data.questions.push(new Array);
                            for (let question of group) {
                                this.edit_data.questions[this.edit_data.questions.length - 1].push(props.questions.find(el => el.id == question));
                            }
                        }
                    }
                }
                this.$log.info(this.edit_data);
                this.isComponentModalActive = true;
            },
            confirmCustomDelete(id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete questionnarie',
                    message: 'Are you sure you want to <b>delete</b> questionary? This action cannot be undone.',
                    confirmText: 'Delete questionary',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.removeQuestionary(id)
                })
            },
            confirmQuestionnaryStart(id) {
                this.$buefy.dialog.confirm({
                    title: 'Mark questionnarie as start',
                    message: 'Are you sure you want to <b>mark as start</b> this questionary? ',
                    confirmText: 'Mark questionary',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.setQuestionaryUseAtStart(id)
                })
            },
            confirmQuestionnaryFinish(id) {
                this.$buefy.dialog.confirm({
                    title: 'Mark questionnarie as finish',
                    message: 'Are you sure you want to <b>mark as start</b> this questionary? ',
                    confirmText: 'Mark questionary',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.setQuestionaryUseAtFinish(id)
                })
            },
            confirmQuestionnaryDaily(id) {
                this.$buefy.dialog.confirm({
                    title: 'Mark questionnarie as daily',
                    message: 'Are you sure you want to <b>mark as daily</b> this questionary? ',
                    confirmText: 'Mark questionary',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.setQuestionaryUseDaily(id)
                })
            },
            confirmUserQuestonnairesDelete() {
                this.$buefy.dialog.confirm({
                    title: 'Flush active daily questionnaires',
                    message: 'Are you sure you want to <b>flush active daily questionnaires</b> ? ',
                    confirmText: 'Flush',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.userQuestonnairesDelete()
                })
            },
            userQuestonnairesDelete(){
                this.$store.dispatch(DAILY_QUESTIONARY_DELETE)
                this.refresh();
            },
            getStyles(questionarie) {
                let styles = '';
                if (questionarie.hasOwnProperty('use_as_start')&&questionarie.use_as_start) styles += ' started_questionnarie';
                if (questionarie.hasOwnProperty('use_as_finish')&&questionarie.use_as_finish) styles += ' finish_questionnarie';
                if (questionarie.hasOwnProperty('use_as_daily')&&questionarie.use_as_daily) styles += ' daily_questionnarie';
                return styles
            },
            async removeQuestionary(id) {
                let resp = await this.$store.dispatch(QUESTIONARY_DELETE, id);
                this.$buefy.toast.open(RespInfo(resp));
                this.refresh();
            },
            async setQuestionaryUseAtStart(id) {
                let resp = await this.$store.dispatch(QUESTIONARY_USE_AT_START, id);
                this.$buefy.toast.open(RespInfo(resp));
                this.refresh();
            },
            async setQuestionaryUseAtFinish(id) {
                let resp = await this.$store.dispatch(QUESTIONARY_USE_AT_FINISH, id);
                this.$buefy.toast.open(RespInfo(resp));
                this.refresh();
            },
            async setQuestionaryUseDaily(id) {
                let resp = await this.$store.dispatch(QUESTIONARY_USE_DAILY, id);
                this.$buefy.toast.open(RespInfo(resp));
                this.refresh();
            }
        },

    }
</script>

<style scoped>
    .groups {
        border: 1px solid lightgreen;
        text-align: left
    }

    .group_id {
        display: inline-block;
        float: left;
        width: 25px;
        height: 100%;
    }

    .group_content {
        display: inline-block;
    }

    .group {
        padding-left: 25px;
    }

    .started_questionnarie {
        border: 3px solid green;
    }

    .finish_questionnarie {
        background: lightgreen;
    }

    .daily_questionnarie {
        color: lightgreen;
        text-shadow: black 1px 1px;
    }
</style>
