<template>
    <section>
        <b-table
                :data="getUsers"
                :paginated="true"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="unread"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                v-on:click="clickRow">
            <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable searchable>
                    {{ props.row.name }}
                </b-table-column>
                <template v-if="isWide" >
                    <b-table-column field="created_at" label="Time in the Program" sortable>
                        {{ timeInTheProgram(props.row.created_at) }}
                    </b-table-column>
                    <b-table-column v-if="props.row.stages!==undefined" field="level" label="Level"
                                    sortable>
                        {{props.row.stages.level.order}}.{{props.row.stages.stage.order}}
                    </b-table-column>
                    <b-table-column v-else field="level" label="Level" sortable>
                        no data
                    </b-table-column>

                    <b-table-column v-if="props.row.stages!==undefined" field="points" label="Points"
                                    sortable>
                        {{props.row.stages.points}}/{{props.row.stages.stage.goal}}
                    </b-table-column>
                    <b-table-column v-else field="points" label="Points" sortable>
                        no data
                    </b-table-column>

                    <b-table-column v-if="props.row.glucose_level!==undefined" field="glucose_level"
                                    label="Glucose level"
                                    sortable>
                        {{Math.round(props.row.glucose_level*10)/10}}
                    </b-table-column>
                    <b-table-column v-else field="glucose_level" label="Glucose level" sortable>
                        not reported
                    </b-table-column>

                    <b-table-column v-if="props.row.last_glucose_report!==undefined" field="last_glucose_report"
                                    label="Last glucose report" sortable>
                        {{formatDate(props.row.last_glucose_report) }}
                    </b-table-column>
                    <b-table-column v-else field="last_glucose_report" label="Last glucose report" sortable>
                        not reported
                    </b-table-column>

                    <b-table-column v-if="props.row.last_nutrition_report!==undefined" field="last_nutrition_report"
                                    label="Last nutrition report" sortable>
                        {{formatDate(props.row.last_nutrition_report)}}
                    </b-table-column>
                    <b-table-column v-else field="last_nutrition_report" label="Last nutrition report" sortable>
                        not reported
                    </b-table-column>

                    <b-table-column v-if="hasPermission('read:all_users')" field="user_metadata.leader_name"
                                    label="Leader" sortable searchable>
                        {{props.row.user_metadata.leader_name}}
                    </b-table-column>
                </template>
                <b-table-column v-if="props.row.hasOwnProperty('unread')" field="unread"
                                label="Unread" sortable>
                    <div v-if="props.row.unread > 0" class="unread">
                        {{props.row.unread}}
                    </div>
                    <div v-else class="read">
                        {{props.row.unread}}
                    </div>
                </b-table-column>
                <b-table-column v-else field="chats" label="Unread" sortable>
                    <div class="unread">
                        -1
                    </div>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>
<script>
    import {mapGetters} from 'vuex';
    import RespInfo from "../utils/respinfo";
    import moment from 'moment'

    export default {
        name: 'UsersTable',
        data() {
            return {
                data: [],
                currentPage: 1,
                perPage: 50,
                isPaginationSimple: true,
                paginationPosition: 'top',
                defaultSortDirection: 'desc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
                isCompModalActive: false,
                isWide: true,
            }
        },
        created() {
            this.refresh();
            //window.addEventListener('resize', this.resizeTable);
            //this.resizeTable()
        },
        methods: {
            async refresh() {
                const now = new Date();
                if (now.getTime() > this.getUsersExpire) {
                    const resp = await this.$store.dispatch('USER_GET_HELSINKI');
                    this.$buefy.toast.open(RespInfo(resp));
                }
            },
            timeInTheProgram(date) {
                return moment(date).toNow();
            },
            resizeTable() {
                this.perPage = Math.floor((window.innerHeight - 250) / 42);
                this.isWide = window.innerWidth > 768;
            },
            formatDate(date) {
                return moment(date).format("LL-HH:mm:ss")
            },
            clickRow(payload){
                const user_id = payload.user_id;//!!!!!!!!!
                this.$router.push('/client/'+ user_id) ;
            }
        },
        computed: {
            ...mapGetters([
                "getUsers",
                "getUsersExpire",
                "hasPermission"
            ])
        }
    }
</script>

<style scoped>
    .unread {
        display: block;
        padding: 2px 5px 2px 5px;
        background-color: red;
        text-align: center;
        border-radius: 10px;
        color: white;
    }

    .read {
        display: block;
        padding: 2px 5px 2px 5px;
        background-color: green;
        text-align: center;
        border-radius: 10px;
        color: white;
    }
</style>