<template>
    <section class="chat_screen">
        <div class="chat_user">{{chat_room}}</div>
        <client-chat v-if="chat_room > 0"
                     clientId=-1
                     :room_id="chat_room"/>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex'
    import ClientChat from "../components/ClientPage/ClientChat";
    import timeDifference from "../utils/date";

    export default {
        name: "ClientChatPage",
        components: {
            ClientChat,
        },
        data() {
            return {
                height: 0,
                user: undefined,
                glucose_l: 7.5,
                user_id: '',
                chat_room: -1,
            }
        },
        created() {
            this.refresh()
        },
        computed: {
            ...mapGetters([
                "getUserById"
            ])
        },
        methods: {
            async refresh() {
                this.chat_room = Number(this.$route.params.room);

                //this.user = this.getUserById(this.user_id);
                //window.console.log(this.user);
                //if (this.user === undefined) {
//                    window.console.log('this.user -> undefined');
//                    //this.$router.push('/users');
  //                  await this.$store.dispatch('USER_GET_BY_ID', this.user_id)
    //                this.user = this.getUserById(this.user_id);
                //}
            },
            timeInTheProgram(param) {
                const date = new Date(param);
                const now = new Date();
                return timeDifference(now, date);
            },
        }
    }
</script>

<style scoped>
    .chat_screen {
        height: 100%;
        overflow: visible;
        padding-right: 10px;
    }

    .chat_user {
        position: absolute;
        top: 0;
        right: 15px;
        padding: 10px;
        font-weight: bolder;
    }

</style>