<template>
    <div>
        <StatesMachineTable>
        </StatesMachineTable>
    </div>
</template>

<script>
    import StatesMachineTable from "../components/StatesMachine/StatesMachineTable";

    export default {
        name: "StatesMachinePage",
        components: {
            StatesMachineTable,
        }
    }
</script>

<style scoped>

</style>