<template>
    <div>
        <section v-if="numberOfData<2">
            not enough data to build charts
        </section>
        <section v-else>
            <b-numberinput v-model="numberOfDays" type="is-success is-rounded is-small" :max="maxDays" :min="minDays"></b-numberinput>
            <JSCharting class="glucose_chart" :options="options()">
            </JSCharting>
        </section>
    </div>
</template>

<script>
    import JSCharting from 'jscharting-vue';
    import moment from 'moment';
    import {mapGetters} from "vuex";

    const now = new moment(new Date());


    function getLastDates(days) {
        const today = now;
        let dates = Array();
        for (let i = days; i >= 0; i--) {
            dates.push(today.clone().subtract(i, "days").format("YYYY-MM-DD"))
        }
        return dates
    }

    export default {
        name: "ClientGlucoseChart",
        components: {
            JSCharting,
        },
        props: {
            user_id: String,
            created: String,
        },
        data() {
            return {
                name: 'liveData',
                chart: null,
                start: chart => {
                    const me = this;
                    me.chart = chart;
                },
                options: () => ({
                    type: "line spline",
                    series: this.series,
                    legend_layout: "horizontal",
                    legend_position:'top',
                    yAxis: [{
                        scale_range_min: 0,
                        scale_range_max: 250,
                        scale_interval: 40,
                        markers: [
                            {
                                value: [0, 80],
                                color: ['#ff0000', 0.6]
                            },
                            {
                                value: [80, 120],
                                color: ['#00ff00', 0.6]
                            },
                            {
                                value: [120, 250],
                                color: ['#000000', 0.6]
                            }]
                    }],
                    xAxis:[{
                        scale_type: 'auto',
                        //crosshair_enabled: true,
                        defaulTick_enabled: true,
                        customTicks: [
                            {
                                // A tick for every month.
                                value: {
                                    month: "*"
                                },
                                label_text: "%min"
                            },
                            {
                                // Every 3 months
                                value: { month: "*", every: 3 },
                                label_text: function(d) {
                                    return "Q" + (Math.floor(new Date(d[0]).getMonth() / 3) + 1);
                                }
                            },
                            {
                                // Every year
                                value: { year: "*" },
                                label_text: "%min"
                            }
                        ]
                    }],
                }),
                series: [],
                averageDays: 1,
                minDays: 1,
                maxDays: 14,
                firstReportAverageValue: 0,
                dailyReportAverageValue: 0,
                allReportAverageValue: 0,
                dates: [],
                reports: [],
            };
        },
        created() {
            let days = moment().diff(this.created, 'days');
            this.maxDays = days + 1;
            this.numberOfDays = days;
        },
        computed: {
            ...mapGetters([
                "isUserQuestionnaire",
                'getUserQuestionnaires'
            ]),
            numberOfDays: {
                get() {
                    return this.averageDays
                },
                set(value) {
                    if (this.minDays < value < this.maxDays) this.averageDays = value;
                    if (value < this.minDays) this.averageDays = this.minDays
                    if (value > this.maxDays) this.averageDays = this.maxDays
                    if (this.minDays <= this.averageDays <= this.maxDays) {
                        this.getAverages();
                    }
                }
            },
            numberOfData: {
                get() {
                    return this.reports.length
                }
            }
        },
        methods: {
            async getAverages() {
                const dates = getLastDates(this.averageDays)
                this.dates = dates;
                const today = now;
                const start = today.clone().subtract(this.averageDays - 1, "days");


                if ((moment(Date(this.getUserGlucoseDailyAveragesStart)) >= start) ||
                    moment(Date(this.getUserGlucoseDailyAveragesEnd) <= today)) {
                    this.reports = await this.getReports(start, today)
                } else {
                    this.reports = this.getUserGlucoseDailyAverages
                }
                let series = [];
                let firstReport = {
                    name: 'first',
                    type:'line spline',
                    defaultPoint_marker_type: 'none',
                    color:'green',
                    legendEntry: {value:''},
                    points: [],
                };
                let dailyAverage = {
                    name: 'rest',
                    type:'line slpine',
                    defaultPoint_marker_type: 'none',
                    legendEntry: {value:''},
                    color: 'blue',
                    points: [],
                };
                let allAverage = {
                    name: 'all',
                    type: 'line spline',
                    defaultPoint_marker_type: 'none',
                    legendEntry: {value:''},
                    color: 'red',
                    points: [],
                };
                for (let reportEvent of this.reports) {
                    if (reportEvent.first_report !== 0) {
                        firstReport.points.push({
                            x: reportEvent.date,
                            y: reportEvent.first_report,
                        })
                    }
                    if (reportEvent.daily_average !== 0) {
                        dailyAverage.points.push({
                            x: reportEvent.date,
                            y: reportEvent.daily_average,
                        })
                    }
                    if (reportEvent.all_average !== 0) {
                        allAverage.points.push({
                            x: reportEvent.date,
                            y: reportEvent.all_average,
                        })
                    }
                }
                if (firstReport.points.length > 0) series.push(firstReport);
                if (dailyAverage.points.length > 0) series.push(dailyAverage);
                if (allAverage.points.length > 0) series.push(allAverage);
                this.series = series;
                this.updateData();
            },
            async getReports(startday, endday) {
                const str_startday = startday.format("YYYY-MM-DD");
                const str_endday = endday.format("YYYY-MM-DD");
                return await this.$store.dispatch('USER_GET_GLUCOSE_DAILY_AVERAGES', {
                    'user_id': this.user_id,
                    'start': str_startday,
                    'end': str_endday
                });
            },
            compareDates(date1, date2) {
                const datea = new Date(date1);
                const dateb = new Date(date2);
                if (datea.getFullYear() !== dateb.getFullYear()) return false;
                if (datea.getMonth() !== dateb.getMonth()) return false;
                return datea.getDate() === dateb.getDate();
            },
            updateData() {


                //this.chart


            },
        }
    }
</script>

<style scoped>

    .glucose_chart {
        height: 500px;
    }

</style>