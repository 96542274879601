<template>
    <section style="height: 100%">
        <b-field horizontal label="filter:" class="filter">
            <b-input v-model="filter"/>
        </b-field>
        <div class="nutrition-list-container">
            <div class="nutrition-list">
                <div v-for="(item, key) of medicine" :key="key">
                    <client-medicine-instructions-item :id='item.item_id' :user_id="user_id" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';
    import ClientMedicineInstructionsItem from "./ClientMedicineInstructionsItem";

    export default {
        name: "ClientMedicineInstructions",
        props: {
            user_id: String,
        },
        components: {
            ClientMedicineInstructionsItem
        },
        data() {
            return {
                filter: ''
            }
        },
        created() {
            this.refresh();
        },
        methods: {
            async refresh() {
                this.filter = 'loading';
                await this.$store.dispatch('CLIENT_MEDICINE_INSTRUCTION_GET', this.user_id);
                this.filter = '';
            },
        },
        computed: {
            ...mapGetters(['getClientMedicineInstructions']),
            medicine: {
                get() {
                    let filter = this.filter.toUpperCase();
                    let medicine = this.getClientMedicineInstructions
                        .filter(el => el.name.toUpperCase().indexOf(filter) > -1)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .sort((a, b) => b.assigned - a.assigned);
                    return medicine;
                },
            }
        },
    }
</script>

<style scoped>
    .nutrition-list-container {
        padding: 0 0 55px 0;
        margin: 0 0 -55px 0;
        height: 100%;
    }

    .nutrition-list {
        height: 100%;
        overflow-y: scroll;
        text-align: left;
        border: 1px solid lightgray;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Opera and Firefox */
    }
</style>