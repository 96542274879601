<template>
    <div class="stage-container draggable" :stage="id" :level="levelId">
        <table width="100%">
            <tr>
                <td>
                    <b-icon icon="check-outline" type="is-small"></b-icon>
                </td>
                <td><b>- stage #{{ stageOrder }}</b></td>
            </tr>
            <tr>
                <td>
                    <span class="good">Title:</span>
                </td>
                <td>
                    <b-input v-model="stageTitle" :disabled="true"></b-input>
                </td>
            </tr>
            <tr>
                <td>
                    <span class="good">Duration (days):</span>
                </td>
                <td>
                    <b-input v-model="stageDuration" :disabled="true"></b-input>
                </td>
            </tr>
            <tr>
                <td>
                    <span class="good">Points to qualify:</span>
                </td>
                <td>
                    <b-input v-model="stageGoal" :disabled="true"></b-input>
                </td>
            </tr>
        </table>
        <div class="actionblock">
            <stage-action class="action"
                         v-for="(action, key) in clientStageActions"
                         :key="key"
                         :index="key"
                         :stage-id="id"
                         :level-id="levelId"
            />
        </div>
        <div style="padding-top: 7px">
            <b-button @click="addAction" type="is-success is-rounded">
                add action
            </b-button>
        </div>
    </div>
</template>

<script>
    import stageAction from './StageAction'

    import {mapGetters} from 'vuex'

    export default {
        name: "Stage",
        components: { stageAction },
        props: {
            id: Number,
            num: Number,
            levelId: Number
        },
        computed: {
            ...mapGetters(['getStage', 'hasPermission', 'getClientStage']),
            stageOrder:{
                get(){
                    return this.getStage(this.id).order
                }
            },
            stageTitle: {
                get() {
                    return this.getStage(this.id).title;
                },
            },
            stageDuration: {
                get() {
                    return this.getStage(this.id).duration;
                },
            },
            stageGoal: {
                get() {
                    return this.getStage(this.id).goal;
                },
            },
            clientStageActions:{
                get() {
                    if (this.getClientStage(this.id) === undefined) return undefined;
                    if (this.getClientStage(this.id).hasOwnProperty('action_modifiers'))
                        return this.getClientStage(this.id).action_modifiers;
                    return undefined;
                }
            }
        },
        methods: {
            addAction() {
                this.$store.dispatch("CLIENT_ADD_ACTION",  this.id);
            },
            confirmRemoveStage() {
                this.$buefy.dialog.confirm({
                    title: 'Delete stage',
                    message: 'Are you sure you want to <b>delete</b> stage? This action cannot be undone.',
                    confirmText: 'Delete stage',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.remove()
                })
            },
        }
    }
</script>

<style scoped>
    .stage-container {
        flex: 0 0 auto;
        margin: 0;
        margin-right: 10px;
        width: 500px;
        display: inline-block;
    }

    .actionblock {
        display: block;
        height: 125px;
        width: 495px;
        overflow: hidden;
        overflow-y: scroll;

        border: 1px solid gray;
        padding: 5px;
        padding-top: 10px;
    }

    .action {
        display: block;
        width: 450px;
    }

    .good {
        color: limegreen;
        font-weight: bold;
    }

    .bad {
        color: red;
        font-weight: bold;
    }

    .draghandler {
        float: left;
        height: 27px;
        width: 27px;
        background-position: center;
        background-color: black;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .draghandler:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
</style>