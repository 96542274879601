<template>
    <div class="tile is-ancestor calendar">
        <div class="tile is-parent is-vertical">
            <div class="columns is-hidden-mobile">
                <div class="column has-text-left">
                    <a class="navigate" v-on:click="previous()"><i class="fa fa-chevron-left"></i></a>
                    <b class="navigate">Month</b>
                    <a class="navigate" v-on:click="next()"><i class="fa fa-chevron-right"></i></a>
                </div>
                <div class="column has-text-right">
                    <span class="month">{{currentMonth.format("MMMM")}}</span>
                    <span class="month">
                    <span class="year">{{currentMonth.format("YYYY")}}</span>
                    </span>
                </div>
            </div>
            <div class="tile rows is-hidden-tablet">
                <div class="row has-text-centered"><a v-on:click="previous()"><i
                        class="button is-primary is-fullwidth fa fa-chevron-up"></i></a></div>
                <hr/>
                <div class="row has-text-centered"><h2 class="title is-2">{{currentMonth.format("MMMM YYYY")}}</h2>
                </div>
                <hr/>
                <div class="row has-text-centered"><a v-on:click="next()"><i
                        class="button is-primary is-fullwidth fa fa-chevron-down"></i></a></div>
            </div>
            <div class="tile week-days is-hidden-mobile">
                <div v-for="weekday in weekdays" class="tile is-child week-days"
                     v-bind:key="weekday">
                    <header>
                        <p class="week-day">
                            {{weekday}}
                        </p>
                    </header>
                </div>
            </div>
            <div class="tile" v-for="key in weeks" :key="key">
                <div v-for="n in 7" :key="n" class="tile is-parent cal_cell" v-bind:class="dates[n-1+ (key-1)*7].class">
                    <p class="month_day"> {{dates[n-1 + (key-1)*7].date}}</p>
                    <div v-for="report in events" :key="report.date">
                        <div v-if="compareDates(report.date, dates[n-1 + (key-1)*7].key)" class="glucose_report"
                             v-bind:class="getFit(report.first_report)">
                            <div class="glucose_report_first">first: {{Math.round(report.first_report*10)/10}}</div>
                            <div class="glucose_report_daily">daily: {{report.daily_average}}</div>
                            <div class="glucose_report_all">all: {{report.all_average}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    //import {USER_GET_GLUCOSE_CALENDAR} from "../../store/constants";

    const now = new moment(new Date().setDate(1));

    function getDates(start) {
        const total_start = start.clone().subtract(start.day(), "days");
        const total_end = total_start.clone().add(7 * 7 - 1, "days");
        const days = Array.apply(null, {length: 7 * 7})
            .map(Number.call, Number)
            .map(function (i) {
                const d = total_start.clone().add(i, "days");
                let responce = {date: d.format("D"), key: d.format("YYYY-MM-DD")};
                if (d.month() !== start.month()) responce.class = "is-hidden-mobile disabled";
                return responce;
            });
        return {dates: days, start: total_start, end: total_end};
    }

    export default {
        props: {
            user_id: String
        },
        data() {
            return {
                showDates: true,
                currentMonth: now,
                dates: [],
                weekdays: [
                    "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
                ],
                weeks: 5,
                events: [],
            }
        },
        created() {
            this.refresh();
        },
        methods: {
            previous: function () {
                this.currentMonth.subtract(1, "months");
                this.refresh();
                //this.$emit('previous');
            },
            next: function () {
                this.currentMonth.add(1, "months");
                this.refresh();
                //this.$emit('next');
            },
            async refresh() {
                let local_dates = getDates(this.currentMonth);
                const count = this.currentMonth.day() + this.currentMonth.daysInMonth() - 1;
                this.weeks = Math.floor(count / 7) + 1;
                this.$log.info(this.weeks);
                this.dates = local_dates.dates;
                this.events = await this.getReports(local_dates.start, this.weeks * 7);

                this.$log.info(this.events);
            },
            async getReports(startday, days_count) {
                const str_startday = startday.format("YYYY-MM-DD");
                const endday = startday.clone().add(days_count - 1, "days");
                const str_endday = endday.format("YYYY-MM-DD");
                return await this.$store.dispatch('USER_GET_GLUCOSE_DAILY_AVERAGES', {
                    'user_id': this.user_id,
                    'start': str_startday,
                    'end': str_endday
                });
            },
            getFit(glucose) {
                if (glucose == 0) return 'glucose_yellow'; // ==0 yellow
                if (glucose < 80) return 'glucose_red'; // < 80 red
                if (glucose > 120) return 'glucose_black'; // > 120 black
                return '';
            },
            compareDates(date1, date2) {
                const datea = new Date(date1);
                const dateb = new Date(date2);
                if (datea.getMonth() !== dateb.getMonth()) return false;
                return datea.getDate() === dateb.getDate();
            }
        },
    }
</script>

<style scoped>

    .calendar {
        height: 100%;
        min-height: 500px;
        min-width: 450px
    }

    .navigate {
        padding: 5px;
        color: lightgreen;
    }

    .month {
        padding: 5px 10px 5px 10px;
        font-family: Heebo;
        font-weight: bold;
        font-size: 20px;
        line-height: 18px;
        /* or 90% */
        letter-spacing: -0.08px;
        color: #414042;
        border-right: 2px solid black;
    }

    .year {
        padding: 5px 10px 5px 10px;
        /* or 112% */
    }

    .week-days {
        background-color: #c3c3c3;
        margin-bottom: 10px;
        max-height: 35px;
        border-radius: 2px;

    }

    .week-day {
        padding: 5px;
        font-family: Heebo;
        font-size: 16px;
        line-height: 18px;
        /* or 112% */
        color: #414042;

    }

    .cal_cell {
        min-height: 30px;
        position: relative;
        margin: 4px;
        padding: 0px;
        border-radius: 2px;
        background-color: white;
        text-align: center;
        color: gray;
    }

    .cal_cell:hover {
        background-color: #9A99A2;
    }

    .month_day {
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 12px;
        font-weight: bolder;
        line-height: 18px;
        /* or 112% */
    }

    .glucose_report {
        position: absolute;
        height: 100%;
        width: 100%;
        border: 4px solid #4CD964;

        font-family: Heebo;
        font-weight: 500;
        font-size: 31px;
        line-height: 18px;

        letter-spacing: -0.08px;
        color: #4CD964
    }

    .glucose_report_first {
        position: absolute;
        left: 50%;
        top: 15%;
        /* allow the width to be calculated dynamically */
        height: auto;
        width: 100%;
        /* then move the element back again using a transform */
        transform: translate(-50%, -50%);
        font-size: 16px;
    }

    .glucose_report_daily {
        position: absolute;
        left: 50%;
        top: 50%;
        /* allow the width to be calculated dynamically */
        height: auto;
        width: 100%;
        /* then move the element back again using a transform */
        transform: translate(-50%, -50%);
        font-size: 16px;
    }

    .glucose_report_all {
        position: absolute;
        left: 50%;
        top: 85%;
        /* allow the width to be calculated dynamically */
        height: auto;
        width: 100%;
        /* then move the element back again using a transform */
        transform: translate(-50%, -50%);
        font-size: 16px;
    }


    .glucose_report_averages {
        position: absolute;
        left: 5px;
        top: 2px;
        font-size: 16px;
    }

    .disabled {
        background-color: lightgrey !important;
        color: white;
    }

    .disabled:hover {
        background-color: #9A99A2 !important;
    }

    .glucose_red {
        color: red;
        border-color: red;
    }

    .glucose_black {
        color: black;
        border-color: black;
    }

    .glucose_yellow {
        color: yellow;
        border-color: yellow;
        text-shadow: darkorange 1px 1px;
        box-shadow: darkorange 1px 1px;
    }
</style>