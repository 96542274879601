<template>
    <span>
        {{ yesText }} <b> {{report}}</b> - {{condition_name}} <b>{{values}}</b>
        <span v-if="hasDays">
            in <b>[
            <span v-for="(day, key) in days" v-bind:key="key">
                <span v-if="currentDays.includes(day)">
                    {{ weekdays[day] }}
                </span>
            </span>
            ]</b>
        </span>
        <span v-if="hasStage">
            for <b>[{{stageText}}]</b>
        </span>
    </span>
</template>

<script>
    export default {
        name: "ViewCondition",
        props: {
            condition: {},
            type: {
                type: String,
                default: 'report'
            }
        },
        data() {
            return {
                yesText: '',
                report: 'no report',
                condition_name: 'no condition',
                values: 'no values',
                hasDays: false,
                currentDays: [],
                days: [6, 0, 1, 2, 3, 4, 5],
                weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                hasStage: false,
                stageText:'',
            }
        },
        created() {
            if (this.type==='no_reports') {
                this.yesText = 'No';
            }
            this.condition.conditions.filter(el => el.type === 'report').map(el => {
                this.report = el.report_type;
            })
            const report = this.condition.conditions.find(el => el.type === 'report')
            if (report.hasOwnProperty('comparator')) {
                this.values = report.value;
                switch (report.comparator) {
                    case 'lt':
                        this.condition_name = 'Less'
                        break;
                    case 'rt':
                        this.condition_name = 'More'
                        break;
                    case 'eq':
                        this.condition_name = 'Equal'
                        break;
                    case 'lty':
                        this.condition_name = 'Less yesterday'
                        break;
                    case 'rty':
                        this.condition_name = 'More yesterday'
                        break;
                    case 'eqy':
                        this.condition_name = 'In yesterday range'
                        break;
                    default:
                        this.condition_name = ''
                }
            }

            this.condition.conditions.filter(el => el.meta === 'condition').map(el => {
                switch (el.type) {
                    case 'date_time':
                        if (el.hasOwnProperty('date_in_range')) {
                            this.condition_name = 'Between'
                            this.values = el.date_in_range;
                        }
                        if (el.hasOwnProperty('time_in_range')) {
                            this.condition_name = 'Between'
                            this.values = el.time_in_range;
                        }
                        if (el.hasOwnProperty('time_before')) {
                            this.condition_name = 'Before'
                            this.values = el.time_before;
                        }
                        if (el.hasOwnProperty('time_after')) {
                            this.condition_name = 'After'
                            this.values = el.time_after;
                        }
                        if (el.hasOwnProperty('days')) {
                            this.currentDays = el.days
                            this.hasDays = true;
                        }
                        break;
                }
            })

            const stages = this.condition.conditions.find(el => el.meta === 'stage')
            if (stages !== undefined) {
                this.hasStage = true;
                this.stageText = stages.text;
            }
        }
    }
</script>

<style scoped>

</style>