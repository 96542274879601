<template>
  <div>
    <b-button v-if="hasPermission('write:question')"
              type="is-success is-rounded is-medium"
              @click="createQuestion">
      Add Question
    </b-button>
    <b-modal :active.sync="isCompModalActive"
             has-modal-card
             aria-role="dialog"
             aria-modal>
      <add-question-form
          :pCloseModal="closeModal"
          :fdata="formdata"
          :isEdit="isEdit"
          :categories="categories"
          :prefresh="refresh">
      </add-question-form>
    </b-modal>


    <div class="categories">
            <span v-for="(category, key) in categories" :key="key" class="category">
                {{ category.name }}
                <span v-if="category.id != 1">
                    <b-button type="is-success is-rounded is-small" @click="editCategory(category)">Edit</b-button>
                    <b-button type="is-danger is-rounded is-small"
                              @click="confirmCategoryDelete(category.id)">Delete</b-button>
                </span>
            </span>
      <span>
                    <b-button type="is-success is-rounded is-small" @click="createCategory">Add Category</b-button>
            </span>
    </div>
    <b-modal :active.sync="isCategoryModalActive"
             has-modal-card
             aria-role="dialog"
             aria-modal>
      <add-question-category-form
          :pCloseModal="closeCateoryModal"
          :fdata="formdata"
          :isEdit="isEdit"
          :prefresh="refresh">
      </add-question-category-form>
    </b-modal>


    <b-loading :is-full-page=false :can-cancel=false :active.sync="isLoading"></b-loading>

    <div v-if="!isLoading">

      <div v-if="data.length===0">No questions in the list</div>

      <b-table v-else-if="data.length!==0"
               :data="data"
               :paginated="true"
               :per-page="perPage"
               :current-page.sync="currentPage"
               :pagination-simple="isPaginationSimple"
               :pagination-position="paginationPosition"
               :default-sort-direction="defaultSortDirection"
               :sort-icon="sortIcon"
               :sort-icon-size="sortIconSize"
               default-sort="id"
               aria-next-label="Next page"
               aria-previous-label="Previous page"
               aria-page-label="Page"
               aria-current-label="Current page">
        <template slot-scope="props">
          <b-table-column field="id" label="ID" width="70" sortable numeric>
            {{ props.row.id }}
          </b-table-column>
          <b-table-column field="title" label="Title" sortable searchable>
            {{ props.row.title }}
          </b-table-column>
          <b-table-column field="category" label="Category" sortable searchable>
            {{ props.row.category }}
          </b-table-column>
          <b-table-column v-if="hasPermission('write:question')" label="Edit" width="70px">
            <b-button type="is-success is-rounded is-small" @click="editQuestion(props.row)">Edit</b-button>
          </b-table-column>
          <b-table-column v-if="hasPermission('write:question')" label="Delete" width="100px">
            <b-button type="is-danger is-rounded is-small" @click="confirmCustomDelete(props.row.id)">Delete</b-button>
          </b-table-column>
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
import AddQuestionForm from "../components/Questions/AddQuestionForm";
import RespInfo from "../utils/respinfo";
import {
  QUESTIONS_CATEGORY_DELETE,
  QUESTIONS_CATEGORY_GET,
  QUESTIONS_DELETE,
  QUESTIONS_GET
} from "../store/constants";
import {mapGetters} from 'vuex'
import AddQuestionCategoryForm from "../components/Questions/AddQuestionCategoryForm";

export default {
  name: 'QuestionsPage',
  components: {AddQuestionForm, AddQuestionCategoryForm},
  data() {
    return {
      isLoading: true,
      data: [],
      categories: [],
      currentPage: 1,
      perPage: 20,
      isPaginationSimple: true,
      paginationPosition: 'top',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      isCompModalActive: false,
      formdata: null,
      isEdit: true,
      isCategoryModalActive: false
    }
  },
  created() {
    this.refresh();
    this.perPage = Math.floor((window.innerHeight - 250) / 33);
    window.addEventListener('resize', this.resizeTable);
  },
  computed: {
    ...mapGetters(['hasPermission']),
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const resp = await this.$store.dispatch(QUESTIONS_GET);
      this.data = resp.data;
      const resp2 = await this.$store.dispatch(QUESTIONS_CATEGORY_GET);
      this.categories = resp2.data;

      this.data = this.data.map(el => {
        el.category = this.categories.find(cat => cat.id == el.category_id).name
        return el
      })
      this.isLoading = false;
    },
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete question',
        message: 'Are you sure you want to <b>delete</b> question? This action cannot be undone.',
        confirmText: 'Delete question',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeQuestion(id)
      })
    },
    async removeQuestion(id) {
      let resp = await this.$store.dispatch(QUESTIONS_DELETE, id);
      this.$buefy.toast.open(RespInfo(resp));
      this.refresh();
    },
    confirmCategoryDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete category',
        message: 'Are you sure you want to <b>delete</b> question category? This action cannot be undone.',
        confirmText: 'Delete category',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeCategory(id)
      })
    },
    async removeCategory(id) {
      let resp = await this.$store.dispatch(QUESTIONS_CATEGORY_DELETE, id);
      this.$buefy.toast.open(RespInfo(resp));
      this.refresh();
    },
    createQuestion() {
      this.isEdit = false;
      this.formdata = 'null';
      this.isCompModalActive = true;
    },
    editQuestion(props) {
      this.isEdit = true;
      this.formdata = props;
      this.isCompModalActive = true;
    },
    closeModal: function () {
      this.isCompModalActive = false;
    },
    createCategory() {
      this.isEdit = false;
      this.formdata = 'null';
      this.isCategoryModalActive = true;
    },
    editCategory(props) {
      this.isEdit = true;
      this.formdata = props;
      this.isCategoryModalActive = true;
    },
    closeCateoryModal: function () {
      this.isCategoryModalActive = false;
    },
    resizeTable() {
      this.perPage = Math.floor((window.innerHeight - 250) / 33);
    }
  }
}
</script>

<style scoped>
td {
  padding: 2px;
}

.categories {
  text-align: left;
}

.category {
  margin: 3px;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 3px;
}
</style>