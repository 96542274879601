<template>
    <section style="height: 100%">
        <b-field horizontal label="filter:" class="filter">
            <b-input v-model="filter"/>
        </b-field>
        <div class="nutrition-list-container">
            <div class="nutrition-list">
                <b-table :data="nutritions"
                         focusable
                         paginated
                         pagination-simple
                         :default-sort="['name', 'asc']"
                         per-page="13">
                    <template slot-scope="props">
                        <client-nutrition-instruction-item :id='props.row.id' :user_id="user_id" @update="nutrtitionKey+=1"/>
                    </template>
                </b-table>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';
    import clientNutritionInstructionItem from './ClientNutritionInstructionItem';

    export default {
        name: "ClientNutritionInstructions",
        props: {
            user_id: String,
        },
        components: {
            clientNutritionInstructionItem,
        },
        data() {
            return {
                filter: '',
                nutrtitionKey: 0,
            }
        },
        created() {
            this.refresh();
        },
        methods: {
            async refresh() {
                this.filter = 'loading';
                await this.$store.dispatch('NUTRITION_INSTRUCTIONS_GET');
                await this.$store.dispatch('CLIENT_NUTRITION_INSTRUCTION_GET', this.user_id);
                this.filter = '';
            },
        },
        computed: {
            ...mapGetters(['getClientNutritionInstructions', 'getSpecificNutritionInstructions', 'getClientNutritionInstruction']),
            nutritions: {
                get() {
                    let filter = this.filter.toUpperCase();
                    let nutritions = this.getSpecificNutritionInstructions(2)
                        .filter(el => el.name.toUpperCase().indexOf(filter) > -1)
                        .sort((a, b) => a.name.localeCompare(b.name));
                    return nutritions;
                },
            }
        },
    }
</script>

<style scoped>
    .nutrition-list-container {
        padding: 0 0 55px 0;
        margin: 0 0 -55px 0;
        height: 100%;
    }

    .nutrition-list {
        height: 100%;
        overflow-y: scroll;
        text-align: left;
        border: 1px solid lightgray;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Opera and Firefox */
    }
</style>