<template>
    <div class="blood_pressue_container">
        <div class="blood_pressue_caption">
            Blood pressure
            <div class="blood_pressure_ico">
                <img src="./svg/pills.svg">
            </div>
        </div>
        <div class="blood_pressure_line">
            <div class="blood_pressure_lined_block">
                <div class="blood_pressure_data">
                    <div class="blood_pressure_data_caption">
                        Morning
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Last <span>{{lastMorningDate}}</span></div>
                        <div class="blood_pressure_data_subblock_value"><span>{{lastMorningSystolic}}/{{lastMorningDiastolic}}</span></div>
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Average</div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(avgMorningSystolic)}}/{{round(avgMorningDiastolic)}}</span></div>
                    </div>
                </div>
            </div>
            <div class="blood_pressure_lined_block">
                <div class="blood_pressure_data">
                    <div class="blood_pressure_data_caption">
                        Rest of the day
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Last <span>{{lastRestDate}}</span></div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(lastRestSystolic)}}/{{round(lastRestDiastolic)}}</span></div>
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Average</div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(avgRestSystolic)}}/{{round(avgRestDiastolic)}}</span></div>
                    </div>
                </div>
            </div>
            <div class="blood_pressure_lined_block">
                <div class="blood_pressure_data">
                    <div class="blood_pressure_data_caption">
                        Before training
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Last <span>{{lastBeforeDate}}</span></div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(lastBeforeSystolic)}}/{{round(lastBeforeDiastolic)}}</span></div>
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Average</div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(avgBeforeSystolic)}}/{{round(avgBeforeDiastolic)}}</span></div>
                    </div>
                </div>
            </div>
            <div class="blood_pressure_block">
                <div class="blood_pressure_data">
                    <div class="blood_pressure_data_caption">
                        After Training
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Last <span>{{lastAfterDate}}</span></div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(lastAfterSystolic)}}/{{round(lastAfterDiastolic)}}</span></div>
                    </div>
                    <div class="blood_pressure_data_subblock">
                        <div class="blood_pressure_data_subblock_caption">Average</div>
                        <div class="blood_pressure_data_subblock_value"><span>{{round(avgAfterSystolic)}}/{{round(avgAfterDiastolic)}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import {mapGetters} from 'vuex'

    const now = new moment(new Date());

    function getLastDates(days) {
        const today = now;
        let dates = Array();
        for (let i = days; i >= 0; i--) {
            dates.push(today.clone().subtract(i, "days").format("YYYY-MM-DD"))
        }
        return dates
    }

    export default {
        name: "ClientBloodPressure",
        data(){
            return {
                reports: [],
                lastMorningSystolic: 0,
                lastMorningDiastolic: 0,
                lastMorningDate: '',
                lastRestSystolic: 0,
                lastRestDiastolic: 0,
                lastRestDate:'',
                lastBeforeSystolic: 0,
                lastBeforeDiastolic: 0,
                lastBeforeDate:'',
                lastAfterSystolic: 0,
                lastAfterDiastolic: 0,
                lastAfterDate:'',
                avgBeforeSystolic: 0,
                avgBeforeDiastolic: 0,
                avgAfterSystolic: 0,
                avgAfterDiastolic: 0,
                avgMorningSystolic: 0,
                avgMorningDiastolic: 0,
                avgRestSystolic: 0,
                avgRestDiastolic: 0,
                dates: [],
                date: 'nope',
            }
        },
        created() {
        },
        computed: {
            ...mapGetters(['getNumberOfDaysAverage', 'getUserBloodPressureDailyAverages','getUserGlucoseDailyAveragesStart', 'getUserGlucoseDailyAveragesEnd']),
            numberOfDays: {
                get() {
                    return this.getNumberOfDaysAverage;
                }
            }
        },
        methods: {
            round(num) {
                return Math.round(num);
            },
            async getAverages() {
                const dates = getLastDates(this.getNumberOfDaysAverage)
                this.dates = dates;
                this.reports = this.getUserBloodPressureDailyAverages

                let lastDailyAverages = undefined;
                if (this.reports !== undefined ) {
                    lastDailyAverages = this.reports[this.reports.length - 1];
                    let date = dates.find(el => el === lastDailyAverages.date)
                    this.date = date;
                    if (date === undefined) {
                        lastDailyAverages = undefined;
                    }
                }

                let lastMorningSystolic = 0;
                let lastMorningDiastolic = 0;
                let lastMorningDate = '';
                let lastRestSystolic = 0;
                let lastRestDiastolic = 0;
                let lastRestDate ='';
                let lastBeforeSystolic = 0;
                let lastBeforeDiastolic = 0;
                let lastBeforeDate = '';
                let lastAfterSystolic = 0;
                let lastAfterDiastolic = 0;
                let lastAfterDate = '';

                let avgBeforeSystolicSum = 0;
                let avgBeforeDiastolicSum = 0;
                let avgAfterSystolicSum = 0;
                let avgAfterDiastolicSum = 0;
                let avgMorningSystolicSum = 0;
                let avgMorningDiastolicSum = 0;
                let avgRestSystolicSum = 0;
                let avgRestDiastolicSum = 0;
                let avgBeforeNum = 0;
                let avgAfterNum = 0;
                let avgMorningNum = 0;
                let avgRestNum = 0;

                for (const date of dates) {
                    let report = this.reports.find(el => el.date == date)
                    if (report !== undefined) {
                        if (report.first_report_systolic != 0) {
                            avgMorningSystolicSum+= report.first_report_systolic;
                            avgMorningDiastolicSum+= report.first_report_diastolic;
                            avgMorningNum += 1;
                            lastMorningSystolic = report.first_report_systolic;
                            lastMorningDiastolic = report.first_report_diastolic;
                            lastMorningDate = report.date;
                        }
                        if (report.daily_average_systolic != 0) {
                            avgRestSystolicSum+= report.daily_average_systolic;
                            avgRestDiastolicSum+= report.daily_average_diastolic;
                            avgRestNum += 1;
                            lastRestSystolic = report.daily_average_systolic;
                            lastRestDiastolic = report.daily_average_diastolic;
                            lastRestDate = report.date;
                        }
                        if (report.average_before_systolic != 0) {
                            avgBeforeSystolicSum += report.average_before_systolic;
                            avgBeforeDiastolicSum += report.average_before_diastolic;
                            avgBeforeNum += 1;
                            lastBeforeSystolic = report.average_before_systolic;
                            lastBeforeDiastolic = report.average_before_diastolic;
                            lastBeforeDate = report.date;
                        }
                        if (report.average_after_systolic != 0) {
                            avgAfterSystolicSum += report.average_after_systolic;
                            avgAfterDiastolicSum += report.average_after_diastolic;
                            avgAfterNum += 1;
                            lastAfterSystolic = report.average_after_systolic;
                            lastAfterDiastolic = report.average_after_diastolic;
                            lastAfterDate = report.date;
                        }
                    }
                }

                if (avgMorningNum > 0) {
                    this.avgMorningSystolic = avgMorningSystolicSum / avgMorningNum;
                    this.avgMorningDiastolic = avgMorningDiastolicSum / avgMorningNum;
                    this.lastMorningSystolic = lastMorningSystolic;
                    this.lastMorningDiastolic = lastMorningDiastolic;
                    this.lastMorningDate = lastMorningDate;
                } else {
                    this.avgMorningSystolic = 0;
                    this.avgMorningDiastolic = 0;
                    this.lastMorningSystolic = 0;
                    this.lastMorningDiastolic = 0;
                    this.lastMorningDate = 'no data';
                }
                if (avgRestNum > 0) {
                    this.avgRestSystolic = avgRestSystolicSum / avgRestNum;
                    this.avgRestDiastolic = avgRestDiastolicSum / avgRestNum;
                    this.lastRestSystolic = lastRestSystolic;
                    this.lastRestDiastolic = lastRestDiastolic;
                    this.lastRestDate = lastRestDate;
                } else {
                    this.avgRestSystolic = 0;
                    this.avgRestDiastolic = 0;
                    this.lastRestSystolic = 0;
                    this.lastRestDiastolic = 0;
                    this.lastRestDate = 'no data';
                }
                if (avgBeforeNum > 0) {
                    this.avgBeforeSystolic = avgBeforeSystolicSum / avgBeforeNum;
                    this.avgBeforeDiastolic = avgBeforeDiastolicSum / avgBeforeNum;
                    this.lastBeforeSystolic = lastBeforeSystolic;
                    this.lastBeforeDiastolic = lastBeforeDiastolic;
                    this.lastBeforeDate = lastBeforeDate;
                }  else {
                    this.avgBeforeSystolic = 0;
                    this.avgBeforeDiastolic = 0;
                    this.lastBeforeSystolic = 0;
                    this.lastBeforeDiastolic = 0;
                    this.lastBeforeDate = 'no data';
                }
                if (avgAfterNum > 0) {
                    this.avgAfterSystolic = avgAfterSystolicSum / avgAfterNum;
                    this.avgAfterDiastolic = avgAfterDiastolicSum / avgAfterNum;
                    this.lastAfterSystolic = lastAfterSystolic;
                    this.lastAfterDiastolic = lastAfterDiastolic;
                    this.lastAfterDate = lastAfterDate;
                } else {
                    this.avgAfterSystolic = 0;
                    this.avgAfterDiastolic = 0;
                    this.lastAfterSystolic = 0;
                    this.lastAfterDiastolic = 0;
                    this.lastAfterDate = 'no data';
                }

            },
        },
        watch: {
            numberOfDays: function() {
                this.getAverages();
            }
        }
    }
</script>

<style scoped>
    .blood_pressue_container {
        width: 100%;
    }

    .blood_pressue_caption {
        position: relative;
        font-family: Heebo;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.3499999940395355px;
        text-align: left;
        height: 35px;
    }

    .blood_pressure_ico {
        display: inline-block;
        height: 25px;
        width: 25px;
        margin-left: 5px;
    }

    .blood_pressure_line {
        margin: 0;
        padding: 0;
        min-width: 625px;
    }

    .blood_pressure_lined_block {
        display: inline-block;
        height: 115px;
        width: 25%;
        margin: 0px;
        padding: 0 0 0 5px;
        border-left: 1px solid black;
    }

    .blood_pressure_block {
        display: inline-block;
        height: 115px;
        width: 25%;
        margin: 0px;
        padding: 0 5px 0 5px;
    }

    .blood_pressure_data_caption {
        font-family: Heebo;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color:  #9A99A2;
    }

    .blood_pressure_data_subblock {
        display: inline-block;
        width: 50%;
        padding: 5px;
    }

    .blood_pressure_data_subblock_caption {
        position: relative;
        font-family: Heebo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.07999999821186066px;
        text-align: left;
        padding: 3px 0 3px 5px;
    }

    .blood_pressure_data_subblock_caption span {
        position: absolute;
        left: 5px;
        top: 15px;
        font-size: 10px;
        color: #5B5B5B;
    }

    .blood_pressure_data_subblock_value {
        padding: 7px 0;
        margin: 5px 0 0 0 ;
        border-radius: 6px;
        border: 1px solid #E6E6E6;
        text-align: center;
        vertical-align: central;
    }
</style>