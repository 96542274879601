<template>
    <div class="level-container">
        <div class="level-name">
            <b-field grouped v-if="hasPermission('read:stages')">
                <b-field :label="'Level '+index+' name'" label-position="on-border">
                    <b-input :value="levelName" icon="check-outline" disabled=true></b-input>
                </b-field>
                <b-field label="Description" label-position="on-border">
                    <b-input :value="levelDescription" icon="check-outline" disabled=true></b-input>
                </b-field>
            </b-field>
        </div>
        <div class="level-stages">
            <stage v-for="(stage, key) in levelStages"
                   :key="key"
                   :id="stage.id"
                   :num="key"
                   :level-id="id"
            />
        </div>
    </div>
</template>

<script>
    import Stage from "./Stage";
    import {mapGetters} from 'vuex'

    export default {
        name: "Level",
        components: {Stage},
        props: {
            id: Number,
            index: Number,
        },
        computed: {
            ...mapGetters(['getLevel', 'getStages', 'isFirstLevel', 'isLastLevel', 'hasPermission']),
            levelName: {
                get() {
                    const level = this.getLevel(this.id);
                    if (level != undefined) {
                        if (level.hasOwnProperty('name')) {
                            return level.name
                        }
                    }
                    return ''
                },
            },
            levelDescription: {
                get() {
                    const level = this.getLevel(this.id);
                    if (level != undefined) {
                        if (level.hasOwnProperty('description')) {
                            return this.getLevel(this.id).description
                        }
                    }
                    return ''
                },
            },
            levelStages: {
                get() {
                    const stages = Object.values(this.getStages(this.id));
                    return [...stages].sort((a, b) => a.order - b.order);
                }
            },
            isLoading: {
                get() {
                    return true;
                }
            },
        },
    }
</script>

<style scoped>
    .level-container {
        position: relative;
        width: 100%;
        height: 395px;
        text-align: left;
        border: 1px solid gray;
        border-radius: 5px;
        margin-top: 10px;
    }

    .level-name {
        padding: 10px;
        width: 100%;
        height: 55px;
    }

    .level-stages {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 10px;
        width: 100%;
        height: 335px;
        top: 55px;
    }

    .level-addstage {
        position: relative;
        height: 270px;
    }
</style>