export default function timeDifference(end, begin) {
    if (end < begin) return false;
    const difference = {
        seconds: [end.getSeconds() - begin.getSeconds(), 60],
        minutes: [end.getMinutes() - begin.getMinutes(), 60],
        hours: [end.getHours() - begin.getHours(), 24],
        days: [end.getDate() - begin.getDate(), new Date(begin.getYear(), begin.getMonth() + 1, 0).getDate()],
        months: [end.getMonth() - begin.getMonth(), 12],
        years: [end.getYear() - begin.getYear(), 0]
    };

    if (difference.years[0] != 0) {
        delete (difference.months);
        delete (difference.days);
        delete (difference.hours);
        delete (difference.minutes);
        delete (difference.seconds);
    } else if (difference.months[0] != 0) {
        delete (difference.days);
        delete (difference.hours);
        delete (difference.minutes);
        delete (difference.seconds);
    } else if (difference.days[0] != 0) {
        delete (difference.hours);
        delete (difference.minutes);
        delete (difference.seconds);
    } else if (difference.hours[0] != 0) {
        delete (difference.minutes);
        delete (difference.seconds);
    } else if (difference.minutes[0] != 0)
        delete (difference.seconds);

    let result = new Array();
    let flag = false;
    for (let i in difference) {
        if (flag) {
            difference[i][0]--;
            flag = false;
        }
        if (difference[i][0] < 0) {
            flag = true;
            difference[i][0] += difference[i][1];
        }
        if (!difference[i][0]) continue;
        result.push(difference[i][0] + ' ' + i);
    }
    return result.reverse().join(' ');
}