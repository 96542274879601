<template>
    <section class="CQUI_user_block">
        <div class="CQUI_user_block_item">
            <span>Name and Surname: </span>
            {{ this.name }}
        </div>
        <div class="CQUI_user_block_item">
            <span>Phone: </span>
            {{ this.phone }}
        </div>
        <div class="CQUI_user_block_item">
            <span>Email: </span>
            {{ this.email }}
        </div>
        <div class="CQUI_user_block_item">
            <span>Age group: </span>
            {{ this.getAgeGroup() }}
        </div>
        <div class="CQUI_user_block_item">
            <span>BMI group: </span>
            {{ this.getBMIGroup() }}
        </div>
        <div class="CQUI_user_block_item">
            <span>Diagnosed with diabetes: </span>
            {{ this.getDiagnosedGroup() }}
        </div>
        <div class="CQUI_user_block_item">
            <span>Diagnosed as Pre-diabetic: </span>
            {{ this.getPreDiagnosedGroup() }}
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ClientQuestionnariesUserInfo",
    props: {
        user_id: String,
    },
    created() {
        let user_info = this.getUserById(this.user_id);
        this.phone = user_info.user_metadata.phone_number
        this.name = user_info.name
        this.email = user_info.email
        this.birth_date = user_info.user_metadata.birth_date
        this.height = user_info.user_metadata.height
        this.weight = user_info.user_metadata.weight
        this.diagnosed_date = user_info.user_metadata.diagnosed_date
        this.pre_diagnosed_date = user_info.user_metadata.pre_diagnosed_date
    },
    methods: {
        getBMI(weight, height) {
            return Math.floor(weight / Math.pow(height / 100, 2) * 100) / 100
        },
        getBMIGroup() {
            let bmi;
            if (this.weight && this.height){
                bmi = this.getBMI(this.weight, this.height)
            } else {
                bmi = false
            }
            switch (true) {
                case (bmi === false):
                    return "No data."
                case (bmi <= 18.4):
                    return "Underweight - 18.4 and below"
                case (bmi >= 18.5 && bmi <= 24.9):
                    return "Balanced weight - 18.5-24.9"
                case (bmi >= 25 && bmi <= 29.9):
                    return "Overweight - 25-29.9"
                case (bmi >= 30 && bmi <= 34.9):
                    return "Obesity - 30-34.9"
                case (bmi > 34.9):
                    return "Severe obesity - over 35"
            }
        },
        getAge(start_date) {
            let today = new Date();
            let birthDate = new Date(start_date)
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        getAgeGroup() {
            let age;
            if (this.birth_date) {
                age = this.getAge(this.birth_date)
            } else {
                age = false;
            }
            switch (true) {
                case (age === false):
                    return "No data"
                case (age < 40):
                    return "Until the age of 40."
                case (age >= 40 && age <= 60):
                    return "Age 40-60."
                case (age > 60):
                    return "Over the age of 60"
            }
        },
        getDiabetesGroup(diagnosed_date) {
            let diagnosedYears;
            if (diagnosed_date) {
                diagnosedYears = this.getAge(diagnosed_date)
            } else {
                diagnosedYears = false;
            }
            switch (true) {
                case (diagnosedYears === false):
                    return "No data."
                case (diagnosedYears >= 10):
                    return "Over 10 years."
                case (diagnosedYears >= 3 && diagnosedYears < 10):
                    return "Up to 10 years."
                case (diagnosedYears < 3):
                    return "Under 3 years"
            }
        },
        getDiagnosedGroup() {
            return this.getDiabetesGroup(this.diagnosed_date)
        },
        getPreDiagnosedGroup() {
            return this.getDiabetesGroup(this.pre_diagnosed_date)
        }
    },
    computed: {
        ...
            mapGetters([
                "getUserById"
            ])
    }
}
</script>

<style scoped>
.CQUI_user_block {
    padding: 10px 0 10px 25px;
}

.CQUI_user_block .CQUI_user_block_item span {
    font-weight: bold;
}

</style>