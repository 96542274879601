<template>
    <div class="level_points_card">
        <div class="level_points_level">Level: <span class="level_points_value">{{ stage.level.order +"."+ stage.stage.order }}</span></div>
        <div class="level_points_points">Points:<span class="level_points_value"> {{ stage.points+"/"+stage.stage.goal}}</span></div>
    </div>
</template>

<script>
    export default {
        name: "ClientLevelPoints",
        props: {
            stage: {},
        },
    }
</script>

<style scoped>
    .level_points_card {
        position: relative;
        display: inline-block;
        height: 80px;
        width: 200px;
        background-image: url("svg/client_level_points.svg");
        background-repeat: no-repeat;
        border-left: 1px solid #9A99A2;
        font-family: Heebo;
        font-size: 18px;
        line-height: 18px;
        /* or 100% */
        align-items: center;
        letter-spacing: -0.08px;
        color: #9A99A2;
        overflow: visible;
    }

    .level_points_level {
        font-family: Heebo;
        position: absolute;
        left: 65px;
        top: 10px;
    }

    .level_points_points {
        font-family: Heebo;
        position: absolute;
        left: 65px;
        top: 35px;
    }

    .level_points_value {
        font-family: Heebo;
        font-weight: 501;
        font-size: 26px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: #5C5C5C;
    }


</style>