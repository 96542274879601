<template>
    <div class="glucose-meter_inner">
        <svg width="136" height="68" viewBox="0 0 154 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="136" height="67" rx="5.5" fill="#F9F9F9" stroke="#E6E6E6"/>
        </svg>
        <div :class="'value '+ getFit(value)"> {{round(value)}}</div>
        <div class="caption">{{caption}}</div>
    </div>
</template>

<script>
    export default {
        name: "GlucoseMeterBox",
        props: {
            value: {type: Number, default: -1},
            caption: String,
        },
        methods: {
            valueToAngle(value) {
                return (this.valueToShown(value) / 209) * 258 - 100
            },
            getFit(glucose) {
                if (glucose < 80) return 'glucose_red'; // < 80 red
                if (glucose > 120) return 'glucose_black'; // > 120 black
                return '';
            },
            round(num) {
                return Math.round(num * 10) / 10;
            },
            getCaptionPos(value) {
                let r = 65
                let angle = ((this.valueToAngle(value) - 120) * Math.PI) / 180
                let x = r * Math.cos(angle) + 63
                let y = r * Math.sin(angle) + 65
                return 'left: ' + x + 'px; top: ' + y + 'px; text-align: left;';
            }
        }
    }

</script>

<style scoped>
    .glucose-meter_inner {
        display: inline-block;
        position: relative;
        margin-top: 10px;
        padding: 5px;
        height: 75px;
        width: 140px;
        background-repeat: no-repeat;
    }

    .value {
        position: absolute;
        top: 20px;
        left: 33px;
        width: 66px;
        height: 20px;
        font-family: Heebo;
        font-size: 31px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #4CD964;

    }

    .caption {
        position: absolute;
        top: 50px;
        left: 25px;
        width: 86px;
        height: 15px;

        font-family: Heebo;
        font-size: 9px;
        font-weight: 400;
        line-height: 9px;
        text-align: center;
        color: #77767E;
    }

    .min {
        top: 95px;
        left: 0;
        text-align: right;
        width: 20px;
    }

    .max {
        top: 95px;
        left: 117px;
        text-align: left;
        width: 20px;
    }

    .circle {
        fill: none;
        stroke-width: 7px;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    .glucose_red {
        color: red;
        border-color: red;
    }

    .glucose_black {
        color: black;
        border-color: black;
    }
</style>