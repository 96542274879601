<template>
    <section>
        <span class="update_time">
            {{lastUpdateTime}}
        </span>
        <b-button v-if="hasPermission('users:create')" type="is-success is-rounded is-medium"
                @click="isComponentModalActive = true">
            Add User
        </b-button>
        <users-table>
        </users-table>
        <b-loading :is-full-page=false :can-cancel=false :active.sync="isLoading"></b-loading>
        <b-modal :active.sync="isComponentModalActive"
                 has-modal-card
                 trap-focus
                 aria-role="dialog"
                 aria-modal>
            <add-user-form
                :pCloseModal="closeModal">
            </add-user-form>
        </b-modal>
    </section>
</template>

<script>
    import AddUserForm from '../components/AddUserForm';
    import UsersTable from "../components/UsersTable";
    import {mapGetters} from 'vuex'

    import moment from 'moment'

    export default {
        name: "UsersPage",
        components: {
            AddUserForm,
            UsersTable
        },
        data() {
            return {
                isComponentModalActive: false,
            }
        },
        created() {
            this.$store.dispatch('USER_SET_LAST_UPDATE', Date());
        },
        computed: {
            ...mapGetters(['getUsersLastUpdate', 'hasPermission', 'getUsersStatus']),
            lastUpdateTime: {
                get() {
                    return moment(this.getUsersLastUpdate).format('LL - HH:mm:ss')
                }
            },
            isLoading() {
                return this.getUsersStatus === 'getting' || this.getUsersStatus === 'loading'
            }
        },
        methods: {
            closeModal: function () {
                this.isComponentModalActive = false;
                this.$children[1].refresh()
            }
        }
    }
</script>

<style scoped>
    .update_time {
        float: left;
        font-weight: bold;
        padding: 15px 10px 15px 10px;

    }
</style>
