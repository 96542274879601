<template>
    <section>
        <b-modal :active="isActive" v-on:close="close" class="change-password-modal">
            <div class="am-modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Change user's password</p>
                </header>
                <section class="modal-card-body">
                    <div>
                        <b-field label="Password">
                            <b-input type="password"
                                    :value="password"
                                    v-model="password"
                                    name="password"
                                    password-reveal
                                    placeholder="Password"
                                    @input="password"
                                    required>
                            </b-input>
                        </b-field>
                        <b-field label="Password repeat">
                            <b-input type="password"
                                    :value="password_repeat"
                                    v-model="password_repeat"
                                    name="password_repeat"
                                    password-reveal
                                    placeholder="Password repeat"
                                    @input="password_repeat"
                                    required>
                            </b-input>
                        </b-field>
                        <p class="error-message" v-show="error_message" v-text="error_message"></p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div style="display: flex; width: 100%;">
                        <b-button class="is-rounded is-danger" @click="close" style="margin-right: auto;">
                            Cancel
                        </b-button>
                        <b-button :disabled="!isSendButtonActive" class="is-rounded is-success" @click="sendPassword">
                            Change password
                        </b-button>
                    </div>
                </footer>
            </div>
        </b-modal>
    </section>
</template>
<script>
    import RespInfo from "../../utils/respinfo";
    import {getAxios} from "../../api";
    const axios = getAxios();

    export default {
        name: "PasswordChangeModal",
        props: {
            isActive: Boolean,
            isTemplate: {
                type: Boolean,
                default: false,
            },
            userId: {
                type: String,
                default: null,
            },
        },
        methods: {
            setDefaultState() {
                this.password = '';
                this.password_repeat = '';
                this.error_message = '';
            },
            close() {
                this.setDefaultState();
                this.$emit('close');
            },
            sendPassword() {
                axios.post('users/' + this.userId + "/password", {
                    password: this.password,
                    password_repeat: this.password_repeat,
                }).then(resp => {
                    this.close();
                    this.$buefy.toast.open(RespInfo(resp));
                }).catch(error => {
                    this.error_message = error.response.data.non_field_errors[0];
                })
            },
        },
        computed: {
            isSendButtonActive() {
                return this.password === this.password_repeat && this.password.length > 0;
            },
        },
        data() {
            return {
                password: '',
                password_repeat: '',
                error_message: '',
            }
        }
    }
</script>

<style>
    .change-password-modal {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        font-size: 18px;
    }

    .change-password-modal .am-modal-card {
        width: 440px;
        height: 500px;
    }

    .change-password-modal .modal-content {
        width: 470px;
    }

    .change-password-modal .modal-card-head {
        height: 60px;
    }

    .change-password-modal .modal-card-foot {
        height: 60px;
    }

    .change-password-modal .modal-card-body {
        height: 230px;
        overflow: auto;
    }

    .change-password-modal .modal-card-head {
        padding: 16px;
    }

    .error-message {
        color: red;
        font-size: 14px;
        padding-top: 8px;
    }
</style>
