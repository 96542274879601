<template>
    <div class="glucose-meter_inner">
        <svg viewBox="0 0 136 136" >
            <rect x="22.2402" y="86.0889" width="89" height="39" rx="5.5" fill="#F9F9F9" stroke="#E6E6E6"/>
            <g filter="url(#filter0_d)">
                <path class="circle" stroke="url(#paint0_linear)"
                      d="M105 95 a48 48 0 1 0 -79 0"
                />
            </g>
            <path class="circle" stroke="url(#paint1_linear)"
                  :stroke-dasharray="209-valueToShown(green)+',360'"
                  d="M105 95 a48 48 0 1 0 -79 0"
            />
            <path class="circle" stroke="url(#paint2_linear)"
                  :stroke-dasharray="209-valueToShown(black)+',360'"
                  d="M105 95 a48 48 0 1 0 -79 0"
            />
            <g filter="url(#filter1_d)" :transform="'rotate('+(valueToAngle(value)-4)+',67,67)'">
                <path d="M65.8796 72.713L70.1565 70.5595C70.675 70.2984 70.863 69.652 70.5653 69.1536L53.4822 40.5508C52.846 39.4856 51.2236 40.3026 51.7005 41.448L64.5067 72.2043C64.7298 72.7402 65.361 72.9741 65.8796 72.713Z" fill="#C4C4C4"/>
            </g>
            <defs>
                <filter id="filter0_d" x="10.6426" y="16.5537" width="112.196" height="90.6571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0.46 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter1_d" x="45.1426" y="38.6436" width="29.9074" height="42.5189" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0.46 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear" x1="-26.7509" y1="20.0537" x2="67.9017" y2="134.483" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.718232" stop-color="#FF0F00"/>
                    <stop offset="1" stop-color="#DB0D00"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="-13.8394" y1="56.3787" x2="108.812" y2="75.7871" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.598958" stop-color="#4CD964"/>
                    <stop offset="1" stop-color="#4CD964"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="37.0848" y1="-7.26513" x2="81.6022" y2="108.658" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.3125" stop-color="#585858"/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
        <div  :class="'value '+ getFit(value)"> {{round(value)}}</div>
        <div class="caption">{{caption}}</div>
        <div class="caption min" >min</div>
        <div class="caption max" >max</div>
        <div class="caption green" :style="getCaptionPos(green)">{{green}}</div>
        <div class="caption black" :style="getCaptionPos(black)">{{black}}</div>
    </div>
</template>

<script>
    export default {
        name: "GlucoseMeter",
        props:{
            value: {type: Number, default: -1},
            caption: String,
            min: { type: Number, default: 40 },
            green: {type: Number, default: 80},
            black: {type: Number, default: 120},
            max: { type: Number, default: 160}
        },
        methods: {
            valueToShown(value){
                if (value < this.min) return 0
                if (value > this.max) return 209
                return ((value-this.min)/(this.max-this.min))*209
            },
            valueToAngle(value){
                return (this.valueToShown(value)/209)*258-100
            },
            getFit(glucose) {
                if (glucose < 80) return 'glucose_red'; // < 80 red
                if (glucose > 120) return 'glucose_black'; // > 120 black
                return '';
            },
            round(num) {
                return Math.round(num * 10) / 10;
            },
            getCaptionPos(value) {
                let r = 65
                let angle = ((this.valueToAngle(value)-120) * Math.PI )/ 180
                let x = r * Math.cos(angle) + 63
                let y = r * Math.sin(angle) + 65
                return 'left: '+x+'px; top: '+ y+'px; text-align: left;';
            }
        },

    }
</script>

<style scoped>
    .glucose-meter_inner {
        display: inline-block;
        position: relative;
        height: 140px;
        width: 140px;
        background-repeat: no-repeat;
    }

    .value {
        position: absolute;
        top: 90px;
        left: 33px;
        width: 66px;
        height: 20px;
        font-family: Heebo;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #4CD964;

    }

    .caption {
        position: absolute;
        top: 110px;
        left: 25px;
        width: 86px;
        height: 15px;

        font-family: Heebo;
        font-size: 9px;
        font-weight: 400;
        line-height: 9px;
        text-align: center;
        color: #77767E;
    }

    .min {
        top: 95px;
        left: 0;
        text-align: right;
        width: 20px;
    }

    .max {
        top: 95px;
        left: 117px;
        text-align: left;
        width: 20px;
    }

    .circle {
        fill: none;
        stroke-width: 7px;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    .glucose_red {
        color: red;
        border-color: red;
    }

    .glucose_black {
        color: black;
        border-color: black;
    }
</style>