<template>
    <div align="left">
        <div v-if="meta.hasOwnProperty('contact_one')">
            <b>contact 1:</b> <br/>
            <b>first name:</b>{{meta['contact_one']['first_name']}} <br/>
            <b>last name:</b>{{meta['contact_one']['last_name']}} <br/>
            <b>phone:</b>{{meta['contact_one']['phone']}} <br/>
            <br />
        </div>
        <div v-else>
            <b>no data for contact 1 </b> <br /> <br />
        </div>
        <div v-if="meta.hasOwnProperty('contact_two')">
            <b>contact 2:</b> <br/>
            <b>first name:</b>{{meta['contact_two']['first_name']}} <br/>
            <b>last name:</b>{{meta['contact_two']['last_name']}} <br/>
            <b>phone:</b>{{meta['contact_two']['phone']}} <br/>
        </div>
        <div v-else>
            <b>no data for contact 2 </b> <br />
        </div>
    </div>

</template>

<script>
    export default {
        name: "ClientSupportContacts",
        props: {
            meta: {}
        }
    }
</script>

<style scoped>

</style>