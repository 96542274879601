<template>
    <form action="" @submit.prevent="createCategory">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
            </header>
            <section class="modal-card-body">
                <b-field label="category name">
                    <b-input type="title"
                             v-model="category.name"
                             maxlength="150"
                             required>
                    </b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="closeModal">Close</button>
                <button class="button is-primary">Save</button>
            </footer>
        </div>

    </form>
</template>

<script>
    import {QUESTIONS_CATEGORY_CREATE, QUESTIONS_CATEGORY_EDIT} from "../../store/constants";
    import RespInfo from "../../utils/respinfo";

    export default {
        name: "AddQuestionCategoryForm",
        props:{
            isEdit: Boolean,
            fdata: null,
            pCloseModal: {},
            prefresh:{},
        },
        data() {
            return {
                title: 'Create new category',
                category: {},
                category_id: null,
            }
        },
        created() {
            if (this.isEdit) {
                this.title = "Edit questions category";
                this.category_id = this.fdata.id;
            }
            if (typeof this.fdata == 'object') {
                this.category = JSON.parse(JSON.stringify(this.fdata)) // hack for deep copy\
            }
        },
        methods: {
            closeModal: function () {
                this.pCloseModal();
            },
            async createCategory() {
                let resp;
                if (this.isEdit){
                    resp = await this.$store.dispatch(QUESTIONS_CATEGORY_EDIT, this.category)
                } else {
                    resp = await this.$store.dispatch(QUESTIONS_CATEGORY_CREATE, this.category)
                }
                this.$buefy.toast.open(RespInfo(resp));
                if (resp.status !== 200 && resp.status !== 201) {
                    this.$log.error(resp);
                } else {
                    this.prefresh();
                    this.pCloseModal();
                }
            }
        }
    }
</script>

<style scoped>

</style>