<template>
    <section>
        <div style="text-align: left; padding: 0 10px">

            <b-button tag="a" :href="'/client/'+user_id" type="is-success is-rounded">return to "{{ name }}" page </b-button>

        </div>
        <div class="levels">
            <level v-for="(level, key) in levels"
                   :key="key"
                   :id="level.id"
                   :index="Number(key)"
            />
            <b-loading :is-full-page="isFullPage" :can-cancel="canCancel" :active.sync="isLoading"></b-loading>
        </div>
    </section>
</template>

<script>
    import level from '../components/ClientPage/ClientStages/Level'

    import {mapGetters} from 'vuex'

    export default {
        name: "ClientStagesPage",
        components: {
            level
        },
        data() {
            return {
                user_id:'',
                isFullPage: false,
                canCancel: false,
                dndoptions: {},
                user: undefined,
                name: '',
            }
        },
        computed: {
            ...mapGetters(['getLevels', 'getClientStagesStatus', 'hasPermission', 'getUserById']),
            levels: {
                get() {
                    const levels = Object.values(this.getLevels);
                    return [...levels].sort((a, b) => a.order - b.order);
                }
            },
            isLoading: {
                get() {
                    if (this.getClientStagesStatus == 'loading') return true;
                    return false;
                }
            }
        },
        created() {

            this.refresh();
        },
        methods: {
            async refresh() {
                this.user_id = this.$route.params.id;
                await this.$store.dispatch('LEVELS_GET', this.user_id);
                await this.$store.dispatch('CLIENT_STAGES_GET', this.user_id);
                this.user = this.getUserById(this.user_id);
                window.console.log(this.user);
                if (this.user === undefined || !this.user.user_metadata.hasOwnProperty('status')) {
                    window.console.log('this.user -> undefined');
                    await this.$store.dispatch('USER_GET_BY_ID', this.user_id)
                    this.user = this.getUserById(this.user_id);
                }
                this.name = this.user.name
            }
        }
    }
</script>

<style scoped>
    .levels {
        padding: 10px;
    }
</style>