<template>
    <section>
        <b-pagination
            :total.sync="getTotalUsers"
            :current.sync="currentPage"
            :per-page="perPage"
            :simple="false"
            :range-before=3
            :range-after=3
            :order="order"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            style="margin: 1rem"
        >
        </b-pagination>
        <div class="columns">
            <div class="column is-5">
                <b-field>
                    <b-input placeholder="Search by name or email" v-model="searchQuery"></b-input>
                </b-field>
            </div>
            <div class="column is-1">
                <b-button @click="searchQuery=''" type="is-danger" icon-left="close-circle">Clear</b-button>
            </div>
        </div>
        <b-table
                :data="getUsers"
                :row-class="(row, index) => new Date(row.created_at) < new Date('2022-06-30') && 'old-user'"
                :default-sort-direction="defaultSortDirection"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="created_at"
                v-on:click="clickRow">
            <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable>
                    {{ props.row.name }}
                </b-table-column>
                <template v-if="isWide" >
                    <b-table-column field="created_at" label="Time in the Program" sortable>
                        {{ timeInTheProgram(props.row.created_at) }}
                    </b-table-column>
                    <b-table-column v-if="props.row.stages!==undefined" field="level" label="Level"
                                    sortable>
                        {{props.row.stages.level.order}}.{{props.row.stages.stage.order}}
                    </b-table-column>
                    <b-table-column v-else field="level" label="Level" sortable>
                        no data
                    </b-table-column>

                    <b-table-column v-if="props.row.stages!==undefined" field="points" label="Points"
                                    sortable>
                        {{props.row.stages.points}}/{{props.row.stages.stage.goal}}
                    </b-table-column>
                    <b-table-column v-else field="points" label="Points" sortable>
                        no data
                    </b-table-column>

                    <b-table-column v-if="props.row.glucose_level!==undefined" field="glucose_level"
                                    label="Glucose level"
                                    sortable>
                        {{Math.round(props.row.glucose_level*10)/10}}
                    </b-table-column>
                    <b-table-column v-else field="glucose_level" label="Glucose level" sortable>
                        not reported
                    </b-table-column>

                    <b-table-column v-if="props.row.last_glucose_report!==undefined" field="last_glucose_report"
                                    label="Last glucose report" sortable>
                        {{formatDate(props.row.last_glucose_report) }}
                    </b-table-column>
                    <b-table-column v-else field="last_glucose_report" label="Last glucose report" sortable>
                        not reported
                    </b-table-column>

                    <b-table-column v-if="props.row.last_nutrition_report!==undefined" field="last_nutrition_report"
                                    label="Last nutrition report" sortable>
                        {{formatDate(props.row.last_nutrition_report)}}
                    </b-table-column>
                    <b-table-column v-else field="last_nutrition_report" label="Last nutrition report" sortable>
                        not reported
                    </b-table-column>

                    <b-table-column v-if="hasPermission('read:all_users')" field="user_metadata.leader_name"
                                    label="Leader" sortable searchable>
                        {{props.row.user_metadata.leader_name}}
                    </b-table-column>
                </template>
                <b-table-column v-if="props.row.hasOwnProperty('unread')" field="unread"
                                label="Unread" sortable>
                    <div v-if="props.row.unread > 0" class="unread">
                        {{props.row.unread}}
                    </div>
                    <div v-else class="read">
                        {{props.row.unread}}
                    </div>
                </b-table-column>
                <b-table-column v-else field="chats" label="Unread" sortable>
                    <div class="unread">
                        -1
                    </div>
                </b-table-column>
                <b-table-column v-if="props.row.error!==null" field="error"
                                label="Is valid" sortable>
                    <section v-on:click.stop>
                        <b-button type="is-primary" class="unread" @click="showError(props.row.error)"><i class="fas fa-times"></i></b-button>
                    </section>
                </b-table-column>
                <b-table-column v-else field="error" label="Is valid" sortable>
                    <b-button class="read"><i class="fas fa-check"></i></b-button>
                </b-table-column>
                <b-table-column label="Delete">
                    <section v-on:click.stop>
                        <a @click="deleteUser(props.row.user_id)"><i class="button unread fas fa-trash-alt"></i></a>
                    </section>
                </b-table-column>
            </template>
        </b-table>
        <user-delete-modal :user-id="deleteUserId"
                          :is-active="isDeleteModalActive"
                          v-on:close="isDeleteModalActive=false" />
        <user-error-modal :error="userError"
                          :is-active="isErrorModalActive"
                          v-on:close="isErrorModalActive=false" />
    </section>
</template>
<script>
    import {mapGetters} from 'vuex';
    import {USER_GET} from "../store/constants";
    import RespInfo from "../utils/respinfo";
    import moment from 'moment'
    import UserDeleteModal from './UserDeleteModal';
    import UserErrorModal from './UserErrorModal';

    export default {
        components: { UserDeleteModal, UserErrorModal },
        name: 'UsersTable',
        data() {
            return {
                data: [],
                currentPage: 0,
                total: 0,
                perPage: 25,
                isPaginationSimple: true,
                paginationPosition: 'top',
                defaultSortDirection: 'desc',
                order: 'is-centered',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
                isCompModalActive: false,
                isErrorModalActive: false,
                isDeleteModalActive: false,
                deleteUserId: null,
                userError: null,
                isWide: true,
                searchQuery: '',
                timer: null,
            }
        },
        created() {
            this.currentPage = this.getCurrentPage;
            this.refresh();
            //window.addEventListener('resize', this.resizeTable);
            //this.resizeTable()
        },
        methods: {
            async refresh(ignore_expire=false) {
                const now = new Date();
                if (now.getTime() > this.getUsersExpire || ignore_expire) {
                    const resp = await this.$store.dispatch(USER_GET, {page: this.currentPage - 1});
                    this.$buefy.toast.open(RespInfo(resp));
                }
            },
            timeInTheProgram(date) {
                return moment(date).toNow();
            },
            resizeTable() {
                this.perPage = Math.floor((window.innerHeight - 250) / 42);
                this.isWide = window.innerWidth > 768;
            },
            formatDate(date) {
                return moment(date).format("LL-HH:mm:ss")
            },
            clickRow(payload){
                const user_id = payload.user_id;
                let url = this.$router.resolve({name: 'client', params: {id: user_id}}).href;
                if (event.ctrlKey) {
                    window.open(url, '_blank');
                } else {
                    this.$router.push(url);
                }
            },
            deleteUser(user_id) {
                this.deleteUserId = user_id;
                this.isDeleteModalActive = true;
            },
            showError(error) {
                this.userError = error;
                this.isErrorModalActive = true;
            },
            searchByQuery(queryStr) {
                this.$store.dispatch(USER_GET, {page: this.currentPage - 1, query: queryStr});
            }
        },
        computed: {
            ...mapGetters([
                "getUsers",
                "getUsersExpire",
                "hasPermission",
                "getTotalUsers",
                "getCurrentPage",
            ])
        },
        watch: {
            currentPage(newPage, oldPage) {
                if (newPage !== oldPage && oldPage !== 0) {
                    this.$store.commit('USER_PAGINATION_SET_CURRENT_PAGE', newPage);
                    this.$store.dispatch(USER_GET, {page: newPage - 1, query: this.searchQuery});
                }
            },
            searchQuery(newQuery) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(async () => {
                    if (newQuery && newQuery.length > 2) {
                        await this.searchByQuery(newQuery);
                    } else  if (newQuery.length === 0) {
                        await this.refresh(true);
                    }
                }, 250);
            },
        }
    }
</script>

<style scoped>
    .unread {
        display: block;
        padding: 2px 5px 2px 5px;
        background-color: red;
        text-align: center;
        border-radius: 10px;
        color: white;
    }

    .read {
        display: block;
        padding: 2px 5px 2px 5px;
        background-color: green;
        text-align: center;
        border-radius: 10px;
        color: white;
    }
</style>

<style>
    tr.old-user {
        background: #c3c3c3;
    }
</style>
