import { render, staticRenderFns } from "./AddUserForm.vue?vue&type=template&id=28032a66&scoped=true"
import script from "./AddUserForm.vue?vue&type=script&lang=js"
export * from "./AddUserForm.vue?vue&type=script&lang=js"
import style0 from "./AddUserForm.vue?vue&type=style&index=0&id=28032a66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28032a66",
  null
  
)

export default component.exports