<template>
    <section>
        <b-modal :active="isActive" v-on:close="$emit('close')">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Error</p>
                </header>
                <section class="modal-card-body">
                    <p>{{ error }}</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="$emit('close')">Close</button>
                </footer>
            </div>
        </b-modal>
    </section>
</template>

<script>
export default {
    name: "UserErrorModal",
    props: {
        error: String,
        isActive: Boolean,
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        refresh() {
        }
    }
}
</script>

<style scoped>
.unread {
    display: block;
    padding: 2px 5px 2px 5px;
    background-color: red;
    text-align: center;
    border-radius: 10px;
    color: white;
}

.read {
    display: block;
    padding: 2px 5px 2px 5px;
    background-color: green;
    text-align: center;
    border-radius: 10px;
    color: white;
}
</style>
