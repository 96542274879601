<template>
    <span>
        <span v-for="(condition, key) in block.conditions" :key="key">
            <span v-if="key > 0"> {{block.type}} </span>
            (<view-condition v-if="condition.meta === 'condition'" :condition="condition"/>
            <view-conditions-block v-if="condition.meta === 'block'" :block="condition" />)
        </span>
    </span>
</template>

<script>
    import ViewCondition from "./ViewCondition";

    export default {
        name: "ViewConditionsBlock",
        components: {
            ViewCondition,
        },
        props: {
            block: {}
        }
    }
</script>

<style scoped>

</style>