<template>
    <section>
        <span class="update_time">
            {{lastUpdateTime}}
        </span>
        <helsinki-users-table>
        </helsinki-users-table>
        <b-modal :active.sync="isComponentModalActive"
                 has-modal-card
                 trap-focus
                 aria-role="dialog"
                 aria-modal>
            <add-user-form
                :pCloseModal="closeModal">
            </add-user-form>
        </b-modal>
    </section>
</template>

<script>
    import AddUserForm from '../components/AddUserForm';
    import HelsinkiUsersTable from "../components/HelsinkiUsersTable";
    import {mapGetters} from 'vuex'

    import moment from 'moment'

    export default {
        name: "UsersPage",
        components: {
            AddUserForm,
            HelsinkiUsersTable
        },
        data() {
            return {
                isComponentModalActive: false,
            }
        },
        created() {
            this.$store.dispatch('USER_SET_LAST_UPDATE', Date());
        },
        computed: {
            ...mapGetters(['getUsersLastUpdate', 'hasPermission']),
            lastUpdateTime: {
                get() {
                    return moment(this.getUsersLastUpdate).format('LL - HH:mm:ss')
                }
            },
        },
        methods: {
            closeModal: function () {
                this.isComponentModalActive = false;
                this.$children[1].refresh()
            }
        }
    }
</script>

<style scoped>
    .update_time {
        float: left;
        font-weight: bold;
        padding: 15px 10px 15px 10px;

    }
</style>