<template>
    <div class="stage-container draggable" :stage="id" :level="levelId">
        <table width="100%">
            <tr>
                <td>
                    <div class="draghandler" v-if="hasPermission('write:stages')"><br></div>
                    <b-icon v-if="isStageValid" icon="check-outline" type="is-success is-small"></b-icon>
                    <b-icon v-else icon="close-outline" type="is-danger is-small"></b-icon>
                </td>
                <td><b>- stage #{{ stageOrder }}</b></td>
            </tr>
            <tr>
                <td>
                    <span v-if="isStageTitleValid" class="good">Title:</span>
                    <span v-else class="bad">Title:</span>
                </td>
                <td>
                    <b-input v-model="stageTitle" :disabled="!hasPermission('write:stages')"></b-input>
                </td>
            </tr>
            <tr>
                <td>
                    <span v-if="isStageDurationValid" class="good">Duration (days):</span>
                    <span v-else class="bad">Duration (days):</span>
                </td>
                <td>
                    <b-input v-model="stageDuration" :disabled="!hasPermission('write:stages')"></b-input>
                </td>
            </tr>
            <tr>
                <td>
                    <span v-if="isStageGoalValid" class="good">Points to qualify:</span>
                    <span v-else class="bad">Points to qualify:</span>
                </td>
                <td>
                    <b-input v-model="stageGoal" :disabled="!hasPermission('write:stages')"></b-input>
                </td>
            </tr>
            <tr class="message_row">
                <td>
                    <span v-if="isMessageValid" class="good">Message:</span>
                    <span v-else class="bad">Message:</span>
                </td>
                <td>
                    <span v-if="stageMessage.hasOwnProperty('name')">
                        {{ stageMessage.name}}
                    </span>
                    <b-button @click="editMessage()"
                              class="is-rounded is-success is-small">
                        <b-icon icon="pencil"></b-icon>
                    </b-button>
                    <b-button @click="deleteMessage()"
                              class="is-rounded is-danger is-small">
                        <b-icon class="is-small is-danger" icon="close-circle"></b-icon>
                    </b-button>
                </td>
            </tr>
        </table>
        <div class="actionblock">
            <stage-action class="action"
                          v-for="(action, key) in stageActions"
                          :key="key"
                          :index="key"
                          :stage-id="id"
                          :level-id="levelId"
                          :stage-title="stageTitle"
                          :level-name="levelName"
                          :is-last="stageActions.length === 1"
            />
        </div>
        <div v-if="hasPermission('write:stages')" style="padding-top: 7px">
            <b-button @click="addAction" type="is-success is-rounded">
                add action
            </b-button>
            <b-button @click="confirmRemoveStage" type="is-danger is-rounded">remove stage</b-button>
        </div>
        <add-template-form v-if="isTemplateActive"
                           :is-active="isTemplateActive"
                           :data="message"
                           :is-edit="isTemplateEdit"
                           @close="isTemplateActive=false"
                           @save="saveMessage"
        />
    </div>
</template>

<script>
    import stageAction from './StageAction'
    import AddTemplateForm from "../ChatTemplates/AddTemplateForm";

    import {mapGetters} from 'vuex'

    export default {
        name: "Stage",
        components: { stageAction, AddTemplateForm },
        props: {
            id: Number,
            num: Number,
            levelId: Number,
            levelName: String,
        },
        data(){
            return {
                message: 0,
                isMessageEdit: false,
                isTemplateActive: false,
                isTemplateEdit: true,
            }
        },
        computed: {
            ...mapGetters(['getStage', 'hasPermission']),
            stageOrder:{
                get(){
                    return this.getStage(this.id).order
                }
            },
            stageTitle: {
                get() {
                    return this.getStage(this.id).title
                },
                set(value) {
                    this.$store.dispatch("UPDATE_STAGE", {id: this.id, levelId: this.levelId, fields: {title: value}})
                }
            },
            stageDuration: {
                get() {
                    return this.getStage(this.id).duration
                },
                set(value) {
                    this.$store.dispatch("UPDATE_STAGE", {id: this.id, levelId: this.levelId, fields: {duration: value}})
                }
            },
            stageGoal: {
                get() {
                    return this.getStage(this.id).goal
                },
                set(value) {
                    this.$store.dispatch("UPDATE_STAGE", {id: this.id, levelId: this.levelId, fields: {goal: value}})
                }
            },
            stageMessage: {
                get() {
                    if (!this.getStage(this.id).hasOwnProperty('message'))  return '';
                    return this.getStage(this.id).message;
                },
                set(value) {
                    this.$store.dispatch("UPDATE_STAGE", {id: this.id, levelId: this.levelId, fields: {message: value}})
                    //this.message = value
                }
            },
            stageActions: {
                get() {
                    return this.getStage(this.id).action_types
                }
            },
            isStageValid() {
                const stage = this.getStage(this.id);
                if (stage != undefined) {
                    if (stage.hasOwnProperty('errors')) {
                        //this.$log.info(stage.errors);
                        return Object.keys(stage.errors).length == 0;
                    }
                }
                return true
            },
            isStageTitleValid() {
                const stage = this.getStage(this.id);
                if (stage != undefined) {
                    if (stage.hasOwnProperty('errors')) {
                        return !stage.errors.hasOwnProperty('title')
                    }
                }
                return true
            },
            isStageDurationValid() {
                const stage = this.getStage(this.id);
                if (stage != undefined) {
                    if (stage.hasOwnProperty('errors')) {
                        return !stage.errors.hasOwnProperty('duration')
                    }
                }
                return true
            },
            isStageGoalValid() {
                const stage = this.getStage(this.id);
                if (stage != undefined) {
                    if (stage.hasOwnProperty('errors')) {
                        return !stage.errors.hasOwnProperty('goal')
                    }
                }
                return true
            },
            isMessageValid() {
                const message = this.getStage(this.id).message;
                let messageError = false;
                if (message.hasOwnProperty('type')) {
                    if (message.type !== 'send_response_template') messageError = true;
                } else messageError = true;
                if (!message.hasOwnProperty('name')) messageError = true;
                if (!message.hasOwnProperty('response_id')) messageError = true;
                return !messageError
            }
        },
        methods: {
            addAction() {
                this.$store.dispatch("ADD_ACTION",  this.id);
            },
            confirmRemoveStage() {
                this.$buefy.dialog.confirm({
                    title: 'Delete stage',
                    message: 'Are you sure you want to <b>delete</b> stage? This action cannot be undone.',
                    confirmText: 'Delete stage',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.remove()
                })
            },
            remove() {
                this.$store.dispatch("REMOVE_STAGE", {stageId: this.id, levelId: this.levelId});
            },
            editMessage() {
                let action = JSON.parse(JSON.stringify(this.stageMessage))

                if (action.type === 'send_response_template') {
                    this.isMessageEdit = true;
                    this.message = action;
                } else this.isMessageEdit = false;

                this.isTemplateActive = true;
            },
            deleteMessage() {
                this.stageMessage =  {
                    type: undefined
                }
            },
            saveMessage(payload) {
                let message = payload.action;
                this.stageMessage = message
            },
        }
    }
</script>

<style scoped>
    .stage-container {
        flex: 0 0 auto;
        margin: 0;
        margin-right: 10px;
        width: 490px;
        display: inline-block;
    }

    .actionblock {
        display: block;
        height: 125px;
        width: 490px;
        overflow: hidden;
        overflow-y: scroll;

        border: 1px solid gray;
        padding: 5px;
        padding-top: 10px;
    }

    .action {
        display: block;
        width: 450px;
    }

    .good {
        color: limegreen;
        font-weight: bold;
    }

    .bad {
        color: red;
        font-weight: bold;
    }

    .draghandler {
        float: left;
        height: 27px;
        width: 27px;
        background-image: url("../../assets/svg/pawprint.svg");
        background-position: center;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .draghandler:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    .message_row {
        height: 35px;
    }

    .message_row td {
        padding: 3px 0;
    }
</style>
