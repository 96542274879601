<template>
    <div style="margin: 0px; padding: 0px; width: 390px">
        <b-field grouped>
            <b-field type="is-success is-small" style="margin-right: 3px">
                <b>{{index+1}}</b><br/>
            </b-field>
            <b-field class="validateAction">
                <b-icon v-if="meta === 'nothing'&&hasId" icon="check-outline" type="is-success is-small"/>
                <b-icon v-else-if="meta === 'nothing'&&!hasId" icon="minus-outline" type="is-info is-small"/>
                <b-icon v-else-if="meta === 'add'" icon="plus-outline" type="is-info is-small"/>
                <b-icon v-else-if="meta === 'edit'" icon="pencil" type="is-warning is-small"/>
                <b-icon v-else-if="meta === 'remove'" icon="delete" type="is-danger is-small"/>
            </b-field>
            <b-field type="is-success is-small" calss="inline" label="action" label-position="on-border">

                <b-select v-model="actionType" :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'" >
                    <option>nutrition</option>
                    <option>glucose</option>
                    <option>sleep</option>
                    <option>exercise</option>
                    <option>blood_pressure</option>
                    <option>medicine</option>
                    <option>supplement</option>
                    <option>weight</option>
                    <option>food</option>
                    <option>water</option>
                </b-select>
            </b-field>
            <b-field v-if="isTimeValid" type="is-success is-small" class="picker" label="start"
                     label-position="on-border">
                <b-timepicker v-model="actionStartTime" hour-format="24" editable
                              :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'">
                </b-timepicker>
            </b-field>
            <b-field v-else type="is-danger is-small" class="picker" message="must be < end time" label="start"
                     label-position="on-border">
                <b-timepicker v-model="actionStartTime" hour-format="24" editable
                              :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'">
                </b-timepicker>
            </b-field>
            <b-field v-if="isTimeValid" type="is-success is-small" class="picker" label="end"
                     label-position="on-border">
                <b-timepicker v-model="actionEndTime" hour-format="24" editable
                              :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'"></b-timepicker>
            </b-field>
            <b-field v-else type="is-danger is-small" class="picker" message="must be > from time" label="end"
                     label-position="on-border">
                <b-timepicker v-model="actionEndTime" hour-format="24" editable
                              :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'"></b-timepicker>
            </b-field>
            <b-field v-if="isRewardValid" type="is-success is-small" class="picker" label="reward"
                     label-position="on-border">
                <b-input class="picker" v-model="actionReward" :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'"/>
            </b-field>
            <b-field v-else type="is-danger is-small" class="picker" label="reward"
                     label-position="on-border">
                <b-input class="picker" v-model="actionReward" :disabled="!hasPermission('write:stages')||meta==='remove'||meta==='nothing'"/>
            </b-field>
            <b-field v-if="hasPermission('write:stages')" type="is-danger is-small" class="picker" style="width: 30px;">
                <b-button v-if="meta === 'add'" type="is-small is-danger is-rounded" @click="remove"> -</b-button>
                <b-button v-if="meta === 'nothing'" type="is-rounded is-success is-small" @click="markEditable">
                    edit
                </b-button>
                <b-button v-if="meta === 'nothing'" type="is-small is-danger is-rounded" @click="markRemoved">
                    remove
                </b-button>
                <b-button v-if="meta === 'edit'||meta === 'remove'" type="is-small is-danger is-rounded" @click="removeMarking">
                    clear
                </b-button>
            </b-field>
        </b-field>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import moment from 'moment';

    export default {
        name: "StageAction",
        props: {
            index: Number,
            stageId: Number,
            levelId: Number,
        },
        computed: {
            ...mapGetters(['getClientStageAction', 'hasPermission']),
            meta:{
              get(){
                  return this.getClientStageAction(this.stageId, this.index).meta
              }
            },
            hasId:{
                get(){
                    if (this.getClientStageAction(this.stageId, this.index).hasOwnProperty('id')) {
                        return true;
                    }
                    return false;
                }
            },
            actionType: {
                get() {
                    return this.getClientStageAction(this.stageId, this.index).action
                },
                set(value) {
                    this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {action: value}
                    })
                }
            },
            actionStartTime: {
                get() {
                    return new Date(moment(this.getClientStageAction(this.stageId, this.index).start_time, 'HH:mm'));
                },
                set(value) {
                    this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {start_time: moment(value).format('HH:mm')}
                    })
                }
            },
            actionEndTime: {
                get() {
                    return new Date(moment(this.getClientStageAction(this.stageId, this.index).end_time, 'HH:mm'));
                },
                set(value) {
                    this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {end_time: moment(value).format('HH:mm')}
                    })
                }
            },
            actionReward: {
                get() {
                    return this.getClientStageAction(this.stageId, this.index).reward
                },
                set(value) {
                    this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                        index: this.index,
                        stageId: this.stageId,
                        levelId: this.levelId,
                        fields: {reward: value}
                    })
                }
            },
            isActionValid() {
                const action = this.getClientStageAction(this.stageId, this.index);
                if (action !== undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return Object.keys(action.errors).length == 0
                    }
                }
                return true
            },
            isTimeValid() {
                const action = this.getClientStageAction(this.stageId, this.index);
                if (action != undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return !action.errors.hasOwnProperty('time')
                    }
                }
                return true
            },
            isRewardValid() {
                const action = this.getClientStageAction(this.stageId, this.index);
                if (action != undefined) {
                    if (action.hasOwnProperty('errors')) {
                        return !action.errors.hasOwnProperty('reward')
                    }
                }
                return true
            }
        },
        methods: {
            remove() {
                this.$store.dispatch("CLIENT_REMOVE_ACTION", {
                    stageId: this.stageId,
                    levelId: this.levelId,
                    index: this.index
                })
            },
            markRemoved() {
                this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                    index: this.index,
                    stageId: this.stageId,
                    levelId: this.levelId,
                    fields: {meta: 'remove'}
                })
            },
            markEditable() {
                this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                    index: this.index,
                    stageId: this.stageId,
                    levelId: this.levelId,
                    fields: {meta: 'edit'}
                })
            },
            removeMarking() {
                this.$store.dispatch("CLIENT_UPDATE_ACTION", {
                    index: this.index,
                    stageId: this.stageId,
                    levelId: this.levelId,
                    fields: {meta: 'nothing'}
                })
            }

        }
    }
</script>

<style scoped>
    .picker {
        width: 60px;
    }

    .validateAction {
        width: 17px;
        height: 20px;
    }
</style>
