<template>
    <section v-if="getUserQuestionnaires.length > 0">
        <b-collapse
                class="card"
                v-for="(user_questionary, index) of getUserQuestionnaires"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index">
            <div slot="trigger"
                 slot-scope="slot_props"
                 class="card-header"
                 role="button">
                <p v-if="user_questionary.hasOwnProperty('title')" class="card-header-title">
                    {{user_questionary.id}} ) {{user_questionary.title }} [<i>{{user_questionary.type}}</i>]
                </p>
                <i v-if="user_questionary.finished_at != 'None'">finish at:
                    {{formatDate(user_questionary.finished_at)}} </i>
                <i v-else>not finished yet</i>
                <a class="card-header-icon">
                    <b-icon :icon="slot_props.open ? 'menu-down' : 'menu-up'"></b-icon>
                </a>
            </div>
            <div class="card-content">
                <div class="content" v-if="user_questionary.finished === true">
                    <div class="groups">
                        <ClientQuestionnariesUserInfo :user_id="user_id" />
                        <div v-for="(question, ke) in user_questionary.answers" :key="ke" class="groups">
                            <div class="group_id">{{ ke+1 }}</div>
                            <div class="group_content">
                                <div class="question" v-if="user_questionary.questions.find(el=>el.id ==
                                        question.question) !== undefined">
                                    <div class="question_title"><b>{{ user_questionary.questions.find(el=>el.id ==
                                        question.question).title }}</b>
                                    </div>
                                    <div class="answer"
                                         v-for="(answer, akey) in question.values"
                                         :key="akey">
                                        <section v-if="answer !== undefined">
                                            <b>type:</b> {{ user_questionary.questions.find(el=>el.id ==
                                            question.question).answers.find(ele=> ele.id == answer.answer).type }}
                                            <b>title:</b> {{ user_questionary.questions.find(el=>el.id ==
                                            question.question).answers.find(ele=> ele.id == answer.answer).title }}

                                        </section>
                                        <section v-else>
                                            <b>type:</b> Unknown question
                                        </section>
                                        <b>ans:</b>{{answer.value}}
                                        <section v-if="answer.hasOwnProperty('score')">
                                            <b>score:</b> {{answer.score}}
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
    </section>
</template>

<script>
    import moment from 'moment'
    import {mapGetters} from "vuex";
    import ClientQuestionnariesUserInfo from "@/components/ClientPage/ClientQuestionnariesUserInfo.vue";

    export default {
        name: "ClientQuestionnaires",
        props: {
            user_id: String,
        },
        components: {
            ClientQuestionnariesUserInfo
        },
        data() {
            return {
                isOpen: -1,
            }
        },
        created() {
            this.refresh()
        },
        methods: {
            async refresh() {
                if (this.getUserQuestionnairesStatus === 'success') {
                    if (!this.isUserQuestionnaire(this.user_id)) {
                        await this.$store.dispatch('USER_GET_QUESTIONERS', this.user_id)
                    }
                } else {
                    await new Promise(r => setTimeout(r, 2000));
                    await this.refresh();
                }
            },
            formatDate(date) {
                return moment(date).format('YYYY-MM-DD HH:mm');
            }
        }
        ,
        computed: {
            ...
                mapGetters([
                    "isUserQuestionnaire",
                    'getUserQuestionnaires'
                ])
        }

    }
</script>

<style scoped>
    .question {
        padding: 5px 0 5px 0;
    }

    .question_title {

    }

    .answer {

    }

    .groups {
        border: 1px solid lightgreen;
        text-align: left
    }

    .group_id {
        display: inline-block;
        float: left;
        width: 25px;
        height: 100%;
    }

    .group_content {
        display: inline-block;
    }

    .group {
        padding-left: 25px;
    }
</style>