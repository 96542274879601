<template>
    <section>
        <b-modal :active="isActive" v-on:close="$emit('close')">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete user</p>
                </header>
                <section class="modal-card-body">
                    <p>Do you really want to delete this client?</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="$emit('close')">Close</button>
                    <button class="button is-danger" type="button" @click="deleteUser">Delete</button>
                </footer>
            </div>
        </b-modal>
    </section>
</template>

<script>
import RespInfo from "../utils/respinfo";

export default {
    name: "UserDeleteModal",
    props: {
        userId: String,
        isActive: Boolean,
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        refresh() {
        },
        deleteUser() {
            this.$store.dispatch('USER_DELETE', this.userId).then((resp) => {
                this.$buefy.toast.open(RespInfo(resp));
                this.$emit('close');
            });
        },
    }
}
</script>

<style scoped>
.unread {
    display: block;
    padding: 2px 5px 2px 5px;
    background-color: red;
    text-align: center;
    border-radius: 10px;
    color: white;
}

.read {
    display: block;
    padding: 2px 5px 2px 5px;
    background-color: green;
    text-align: center;
    border-radius: 10px;
    color: white;
}
</style>
