<template>
    <form action="" @submit.prevent="createUser">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">User creation</p>
            </header>
            <section class="modal-card-body">
                <b-field label="Email">
                    <b-input type="email"
                             :value="user.email"
                             v-model="user.email"
                             maxlength="50"
                             required>
                    </b-input>
                </b-field>
                <b-field label="Password"
                         :type="{ 'is-danger': !isValid}"
                         :message="getMessage">
                    <b-input type="password"
                             :value="user.password"
                             v-model="user.password"
                             name="password"
                             password-reveal
                             placeholder="Password"
                             pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                             @input="getMessage = user.password"
                             required>
                    </b-input>
                </b-field>
                <b-field label="First name">
                    <b-input type="username"
                             :value="user.first_name"
                             v-model="user.first_name"
                             placeholder="First name"
                             required>
                    </b-input>
                </b-field>
                <b-field label="Last name">
                    <b-input
                            type="username"
                            :value="user.last_name"
                            v-model="user.last_name"
                            placeholder="Last name"
                            required>
                    </b-input>
                </b-field>
                <b-field label="Phone number">
                    <b-field grouped>
                        <b-field :type="{ 'is-danger': !isCountryValid }"
                                 :message="getCountryMessage">
                            <b-autocomplete v-model="countryCode"
                                            :data="filteredDataArray"
                                            placeholder="Country code"
                                            clearable
                                            class="phone_code"
                                            field="string"
                                            :open-on-focus="openOnFocus"
                                            @select="option => selected = option"
                                            required>
                                <template slot-scope="props">
                                    {{props.option.string}}
                                </template>
                            </b-autocomplete>
                        </b-field>
                        <b-field :type="{ 'is-danger': !isAreaValid }"
                                 :message="getAreaMessage">
                            <b-input type="tel"
                                     :value="areaCode"
                                     v-model="areaCode"
                                     placeholder="Area code"
                                     class="phone_code"
                                     pattern="^[0]?[1-9][0-9]"
                                     @input="getAreaMessage = areaCode"
                                     required>
                            </b-input>
                        </b-field>
                        <b-field :type="{ 'is-danger': !isPhoneNumberValid }"
                                 :message="getPhoneNumberMessage">
                            <b-input type="tel"
                                     :value="phoneNumber"
                                     v-model="phoneNumber"
                                     placeholder="Phone number"
                                     class="phone_number"
                                     pattern="^[0-9]{3}[0-9]{2}[0-9]{2}"
                                     @input="getPhoneNumberMessage = phoneNumber"
                                     required>
                            </b-input>
                        </b-field>
                    </b-field>
                </b-field>
                <b-field label="Helsinki client">
                    <b-checkbox v-model="helsinki"></b-checkbox>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="closeModal">Close</button>
                <button class="button is-primary">Create</button>
            </footer>
            <b-loading :is-full-page=true :can-cancel=false :active.sync="isCreation"></b-loading>
        </div>
    </form>
</template>

<script>
    import {USER_CREATE} from '../store/constants';
    import RespInfo from "../utils/respinfo";
    import {mapGetters} from 'vuex';

    import countryCodes from "./country_codes.json"

    export default {
        name: "AddUserForm",
        props: {
            'pCloseModal': {}
        },
        components: {},
        data() {
            return {
                user: {
                    email: "",
                    first_name: "",
                    last_name: "",
                    phone_number: "",
                    password: "",
                    experiment: "none"
                },
                password: "",
                isValid: false,
                passwordMessage: "",
                countryCode: "",
                countryCodes: countryCodes,
                countryCodeData: [],
                countryCodeMessage: "",
                isCountryValid: false,
                areaCode: "",
                areaCodeMessage: "",
                isAreaValid: false,
                phoneNumber: "",
                phoneNumberMessage: "",
                isPhoneNumberValid: false,
                selected: null,
                openOnFocus: false,
            }
        },
        created() {
            this.getMessage = '';
            this.getCountryMessage = '';
            this.getAreaMessage = '';
            this.getPhoneNumberMessage = '';
            this.countryCodes.forEach((code) => {
                const string = code.name + ' (' + code.dial_code + ')';
                let obj = JSON.parse(JSON.stringify(code));
                obj.string = string;
                this.countryCodeData.push(obj);
            })
        },
        methods: {
            closeModal: function () {
                this.pCloseModal();
            },
            async createUser() {
                if (this.isCountryValid && this.isAreaValid && this.isPhoneNumberValid) {

                    const countryCode = this.selected.dial_code;
                    const areaCode = this.areaCode.slice(-2);
                    this.user.phone_number = countryCode + areaCode + this.phoneNumber;
                    if (!this.isCreation) {
                        const resp = await this.$store.dispatch(USER_CREATE, this.user);
                        this.$buefy.toast.open(RespInfo(resp));
                        if (resp.status !== 200 && resp.status !== 201) {
                            this.$log.error(resp);
                        } else {
                            this.pCloseModal();
                        }
                    }
                }
            },
            typingCode() {
                if (this.selected !== null) {
                    this.isCountryValid = true;
                    this.countryCodeMessage = '';
                } else {
                    this.isCountryValid = false;
                    this.countryCodeMessage = 'select from the list'
                }
            }
        },
        computed: {
            ...mapGetters(['getUsersStatus']),
            isCreation() {
                return this.getUsersStatus === 'creation'
            },
            getMessage: {
                get() {
                    return this.passwordMessage;
                },
                set(value) {
                    let strongRegex = new RegExp("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}");
                    const isValid = strongRegex.test(value);
                    this.isValid = isValid;
                    let rLength = new RegExp("(?=.{8,})");
                    let uLength = '';
                    if (!rLength.test(value)) uLength = ' - 8 characters';
                    let rULetter = new RegExp("(?=.*[A-Z])");
                    let uULetter = '';
                    if (!rULetter.test(value)) uULetter = ' - 1 uppercase letter';
                    let rLLetter = new RegExp("(?=.*[a-z])");
                    let uLLetter = '';
                    if (!rLLetter.test(value)) uLLetter = ' - 1 lowercase letter';
                    let rNumber = new RegExp("(?=.*\\d)");
                    let uNumber = '';
                    if (!rNumber.test(value)) uNumber = ' - 1 digit';
                    if (isValid) {
                        this.passwordMessage = '';
                    } else {
                        this.passwordMessage = 'Should have:' + uULetter + uLLetter + uNumber + uLength;
                    }
                }
            },
            getCountryMessage: {
                get() {
                    this.typingCode();
                    return this.countryCodeMessage;
                }
            },
            getAreaMessage: {
                get() {
                    return this.areaCodeMessage;
                },
                set(value) {
                    let Regex = new RegExp("^[0]?[1-9][0-9]$");
                    const isValid = Regex.test(value);
                    this.isAreaValid = isValid;
                    if (isValid) {
                        this.areaCodeMessage = '';
                    } else {
                        this.areaCodeMessage = '0??'
                    }
                }
            },
            getPhoneNumberMessage: {
                get() {
                    return this.phoneNumberMessage;
                },
                set(value) {
                    let Regex = new RegExp("^[0-9]{7}$");
                    const isValid = Regex.test(value);
                    this.isPhoneNumberValid = isValid;
                    if (isValid) {
                        this.phoneNumberMessage = '';
                    } else {
                        this.phoneNumberMessage = '???????'
                    }
                }
            },
            helsinki: {
                get(){
                    if (this.user.hasOwnProperty('experiment')) {
                        return this.user['experiment'] === 'helsinki';
                    }
                    return false
                },
                set(payload){
                    if(payload) {
                        this.user['experiment'] = 'helsinki';
                    } else {
                        this.user['experiment'] = 'none';
                    }
                }
            },
            filteredDataArray() {
                return this.countryCodeData.filter((option) => {
                    return option.string
                        .toString()
                        .toLowerCase()
                        .indexOf(this.countryCode.toLowerCase()) >= 0
                })
            }

        }
    }
</script>

<style scoped>

    .phone_code {
        width: 200px;
    }

    .phone_number {
        min-width: 200px;
    }
</style>