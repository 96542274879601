<template>
    <section>
        <div class="nutrition-list-item">
            <div class="nutrition-list-item-name">{{name}}</div>
            <div class="nutrition-list-item-dosage">
                {{ dosage }}
        </div>
            <div class="nutrition-list-item-buttons">
                <b-field grouped>
                    <b-field horizontal class="is-small">
                        <b-checkbox disabled v-model="yes">Assigned</b-checkbox>
                    </b-field>
                </b-field>
            </div>

        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "ClientSupplementsInstructionItem",
        props: {
            id: Number,
            user_id: String,
        },
        computed: {
            ...mapGetters(['getClientMedicineInstruction']),
            name() {
                const medicine = this.getClientMedicineInstruction(this.id);
                if (medicine !== undefined) {
                    return medicine.name;
                } else
                    return ''
            },
            yes: {
                get() {
                    const medicine = this.getClientMedicineInstruction(this.id);
                    if (medicine !== undefined) {
                        return medicine.assigned == 1;
                    }
                    return false
                },
                set(value) {
                    this.updateMedicineInstruction(value);
                }
            },
            dosage: {
                get() {
                    const medicine = this.getClientMedicineInstruction(this.id);
                    if (medicine !== undefined) {
                        return medicine.dosage+'mg';
                    }
                    return 'not specified'
                }
            }
        },
        methods:{
            async updateMedicineInstruction(assignValue) {
                let role = 0;
                if (assignValue===true) role = 1;
                let oldInstruction = this.getClientMedicineInstruction(this.id);
                let nutritionInstruction = {};
                if (oldInstruction === undefined ) {
                    nutritionInstruction.item_id = this.id;
                } else {
                    nutritionInstruction = Object.assign({}, oldInstruction);
                }
                nutritionInstruction.assigned = role;
                await this.$store.dispatch("CLIENT_MEDICINE_INSTRUCTION_UPDATE", {user: this.user_id, instruction: nutritionInstruction});
            }
        }
    }
</script>

<style scoped>
    .nutrition-list-item {
        height: 45px;
        padding: 7px;
        margin: 2px;
        border: 1px solid lightgray;
    }

    .nutrition-list-item-name {
        font-weight: bold;
        float: left;
        padding-left: 10px;
    }

    .nutrition-list-item-buttons {
        float: right;
        padding-right: 5px;
    }

    .nutrition-list-item-dosage {
        float: left;
        padding-left: 10px;
        padding-right: 12px;
    }


</style>