<template>
    <form action="" @submit.prevent="createQuestion">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
<!--                <b-select v-if="isEdit" v-model="edit_type" icon="earth" size="is-small">-->
<!--                    <option value="question">question</option>-->
<!--                    <option value="en_us">en_us</option>-->
<!--                    <option value="he_is">he_is</option>-->
<!--                    <option value="he_is_female">he_is(female)</option>-->
<!--                </b-select>-->
            </header>
            <section class="modal-card-body">
                <table width="100%">
                    <tr>
                        <td>
                            <b>Title :</b>
                        </td>
                        <td>
                            <b-field>
                                <b-input type="title"
                                         v-model="question.title"
                                         maxlength="150"
                                         required>
                                </b-input>
                            </b-field>
                        </td>
                        <td><b-select placeholder="Select answer type to Add" v-model="question.category_id" width="350px">
                            <option v-for="(category, keyc) in categories" :key="keyc" :value="category.id">{{category.name}}</option>
                        </b-select></td>
                    </tr>
                    <add-answer-form v-for="(answer, key) in question.answers"
                                     :key="key"
                                     :answer="answer"
                                     :index="key"
                                     :pRemoveAnswer="cRemoveAnswer"
                                     :pUpdate="cUpdateMe"
                                     :unblocked="unblocked"
                                     :clearExclusives="clearExclusives"
                                     :nanswers="question.answers.length"
                    />
                    <tr>
                        <td></td>
                        <td>
                            <b-field v-if="unblocked" label="Add Answer by Type : ">
                                <b-select placeholder="Select answer type to Add" v-model="answerType" width="350px">
                                    <option value="radio">Radio</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="date">Date</option>
                                    <option value="number">Number</option>
                                    <option value="text">Text</option>
                                </b-select>
                                <b-button @click="addAnswer">+</b-button>
                            </b-field>
                        </td>
                        <td></td>
                    </tr>
                </table>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="closeModal">Close</button>
                <button class="button is-primary">Save</button>
            </footer>
        </div>
    </form>
</template>

<script>
    import {
        QUESTIONS_CREATE,
        QUESTIONS_EDIT
    } from '../../store/constants';
    import AddAnswerForm from "./AddAnswerForm";
    import RespInfo from "../../utils/respinfo";


    export default {

        name: "AddQuestionForm",
        components: {
            AddAnswerForm
        },
        props: {
            'pCloseModal': {},
            fdata: null,
            isEdit: Boolean,
            prefresh: {},
            categories: {},
        },
        data() {
            return {
                question: {
                    title: "",
                    answers: [],
                },
                questions: {},
                answerType: "radio",
                title: "Question creation",
                question_id: 0,
                translations: [],
                unblocked: true,
                creation: true,
                edit_type: 'question',
                old_edit_type: ''
            }
        },
        created() {
            if (this.isEdit) {
                this.title = "Edit question";
                this.question_id = this.fdata.id;
                this.old_edit_type = this.edit_type;
            }
            if (typeof this.fdata == 'object') {
                this.question = JSON.parse(JSON.stringify(this.fdata)) // hack for deep copy\
                delete this.question['category']
                this.questions['question'] = JSON.parse(JSON.stringify(this.fdata))
            }
            this.getTranslations();
        },
        methods: {
            closeModal: function () {
                this.pCloseModal();
            },
            addAnswer() {

                let payload = {"type": this.answerType}
                if (this.answerType === 'number') {
                    payload.min = 0;
                    payload.max = 10;
                }
                this.question.answers.push(payload)
            },
            cRemoveAnswer(index) {
                this.question.answers[index] = {};
                delete this.question.answers.splice(index, 1);
            },
            cUpdateMe(index, data) {
                this.question.answers[index] = data;
            },
            clearExclusives(index) {
                if (this.question.answers[index].is_exclusive) {
                    const answers = JSON.parse(JSON.stringify(this.question.answers));
                    this.question.answers = [];
                    for (let i in answers)
                        if (i == index) {
                            answers[i].is_exclusive = true;
                        } else {
                            answers[i].is_exclusive = false;
                        }
                    this.question.answers = JSON.parse(JSON.stringify(answers));
                }
            },
            async getTranslations() {
                const responce = await this.$store.dispatch('TRANSLATIONS_GET', {
                    'entity': 'question',
                    'entity_id': this.question_id
                });
                this.translations = responce.data
                for (let [key, value] of Object.entries(this.translations)) {
                    this.questions[key] = JSON.parse(JSON.stringify(value.data))
                }
            },
            async createQuestion() {
                let resp;
                if (this.isEdit) {
                    this.questions[this.old_edit_type] = JSON.parse(JSON.stringify(this.question));
                    for (let [key, value] of Object.entries(this.questions)) {
                        if (key === 'question') {
                            resp = await this.$store.dispatch(QUESTIONS_EDIT, value);
                        } else {
                            let dataValue = JSON.parse(JSON.stringify(this.questions['question']))
                            dataValue.title = value.title;
                            dataValue.category_id = value.category_id;
                            for (let ansKey in dataValue.answers) {
                                if (dataValue.answers.hasOwnProperty(ansKey)) {
                                    dataValue.answers[ansKey].title = value.answers[ansKey].title;
                                }
                            }
                        }
                    }
                } else {
                    resp = await this.$store.dispatch(QUESTIONS_CREATE, this.question);
                }
                this.$buefy.toast.open(RespInfo(resp));
                if (resp.status !== 200 && resp.status !== 201) {
                    this.$log.error(resp);
                } else {
                    this.prefresh();
                    this.pCloseModal();
                }
            }
        },
        watch: {
            edit_type: function () {
                this.questions[this.old_edit_type] = JSON.parse(JSON.stringify(this.question));
                switch (this.edit_type) {
                    case 'question':
                        this.title = "Edit question";
                        this.unblocked = true;
                        if (this.questions.hasOwnProperty('question')) {
                            this.question = JSON.parse(JSON.stringify(this.questions['question']));
                        } else {
                            this.question = JSON.parse(JSON.stringify(this.fdata));
                            delete this.question['category']
                        }
                        break;
                    default:
                        this.unblocked = false;
                        this.title = "Edit " + this.edit_type + " locale";
                        if (this.questions.hasOwnProperty(this.edit_type)) {
                            this.question = JSON.parse(JSON.stringify(this.questions[this.edit_type]));
                        } else {
                            if (this.translations.hasOwnProperty(this.edit_type)) {
                                this.question = JSON.parse(JSON.stringify(this.translations[this.edit_type].data));
                            } else {
                                this.question = JSON.parse(JSON.stringify(this.fdata));
                                delete this.question['category']
                            }
                        }
                }
                this.old_edit_type = (" " + this.edit_type).slice(1)
            }
        }
    }
</script>

<style scoped>
</style>