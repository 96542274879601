<template>
    <section>
        <form action="" @submit.prevent="createQuestionary()">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{title}}</p>
                </header>
                <section class="modal-card-body" style="text-align: left;" v-drag-and-drop:options="dndoptions">
                    <b-field label="Title:">
                        <b-input type="title"
                                 v-model="questionary.title"
                                 maxlength="150"
                                 required>
                        </b-input>
                    </b-field>
                    <div v-for="(question ,key) of questionary.questions" v-bind:key="key">
                        <div class="draggable" :outerkey="key">
                            <div class="draghandler"><br></div>
                            <div v-if="Array.isArray(question)" class="group">
                                <div v-if="question.length > 0" class="groupcontainer">
                                    <div v-for="(que, ke) of question" v-bind:key="ke">
                                        <div class="draggable" :outerkey="key" :innerkey="ke">
                                            <div class="draghandler"><br></div>
                                            <p style="vertical-align: center">
                                                <b-button type="is-small is-rounded is-danger" style="float: right"
                                                          @click="questionary.questions[key].splice(ke, 1)">
                                                    remove
                                                </b-button>
                                                <b>{{que.id}}</b>
                                                {{que.title}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="groupcontainer">
                                    <div class="draggable" :outerkey="key" innerkey="-1">
                                        <b-button v-if="SelectedQuestion !== null"
                                                  type="is-small is-rounded is-success"
                                                  @click="addQuestion(question)">Add question
                                        </b-button>
                                        <b-button v-else type="is-small is-rounded is-success" disabled>Add question
                                        </b-button>
                                        <b-button @click="questionary.questions.splice(key, 1)"
                                                  type="is-small is-rounded is-danger">Remove section
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="question.hasOwnProperty('title')">
                                <p style="vertical-align: center">
                                    <b-button type="is-small is-rounded is-danger" style="float: right"
                                              @click="questionary.questions.splice(key, 1)">remove
                                    </b-button>
                                    <b>{{question.id}}</b>
                                    {{question.title}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="draggable" outerkey="-1"></div>
                    <b-field label="Select Question">
                        <b-autocomplete
                                v-model="name"
                                placeholder="Select Question"
                                :keep-first="keepFirst"
                                :open-on-focus="openOnFocus"
                                :data="filteredDataObj"
                                field="title"
                                @select="option => SelectedQuestion = option"
                        >
                        </b-autocomplete>
                    </b-field>
                    <b-button v-if="SelectedQuestion !== null"
                              type="is-small is-rounded is-success"
                              @click="addQuestion(questionary.questions)">Add question
                    </b-button>
                    <b-button v-else type="is-small is-rounded is-success " disabled>Add question</b-button>
                    <b-button type="is-small is-rounded is-success" @click="questionary.questions.push([])">Add
                        section
                    </b-button>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="closeModal">Close</button>
                    <button class="button is-primary">Save</button>
                </footer>
            </div>
        </form>
    </section>
</template>

<script>
    import {QUESTIONARY_CREATE, QUESTIONARY_EDIT, QUESTIONS_GET} from "../store/constants";
    import RespInfo from "../utils/respinfo";

    export default {
        name: "AddQuestionaryForm",
        props: {
            'pCloseModal': {},
            'prefresh': {},
            fdata: {},
            isEdit: Boolean
        },
        data() {
            return {
                data: [],
                questionary: {
                    title: "",
                    questions: [],
                },
                SelectedQuestion: null,
                name: '',
                openOnFocus: true,
                keepFirst: true,
                title: 'Questionarie creation',
                dndoptions: {},
            }
        },
        created() {
            this.dndoptions = {
                dropzoneSelector: '.draggable',
                draggableSelector: '.draggable',
                handlerSelector: '.draghandler',
                showDropzoneAreas: false,
                onDragend: function (event) {
                    if (event.droptarget != null) {
                        let move_from;
                        let numberofarrays = 0;
                        let toouterkey = event.droptarget.attributes['outerkey'].value;
                        const fromouterkey = event.items[0].attributes['outerkey'].value;
                        // check number of arrays (from + to targets) to prevent more than two levels of questions array
                        if (Array.isArray(this.questionary.questions[toouterkey]))
                            if (event.droptarget.attributes.hasOwnProperty('innerkey'))
                                numberofarrays += 1;
                        if (Array.isArray(this.questionary.questions[fromouterkey]))
                            if (!event.items[0].attributes.hasOwnProperty('innerkey'))
                                numberofarrays += 1;
                        if (numberofarrays < 2) {
                            if (Array.isArray(this.questionary.questions[fromouterkey])) {
                                if (event.items[0].attributes.hasOwnProperty('innerkey')) {
                                    const frominnerkey = event.items[0].attributes['innerkey'].value;
                                    move_from = JSON.parse(JSON.stringify(this.questionary.questions[fromouterkey][frominnerkey]));
                                    this.questionary.questions[fromouterkey].splice(frominnerkey, 1)
                                } else {
                                    move_from = JSON.parse(JSON.stringify(this.questionary.questions[fromouterkey]));
                                    this.questionary.questions.splice(fromouterkey, 1);
                                    if (fromouterkey < toouterkey) toouterkey -= 1;
                                }
                            } else {
                                move_from = JSON.parse(JSON.stringify(this.questionary.questions[fromouterkey]));
                                this.questionary.questions.splice(fromouterkey, 1);
                                if (fromouterkey < toouterkey) toouterkey -= 1;
                            }
                            if (toouterkey < 0) {
                                this.questionary.questions.push(move_from);
                            } else {
                                if (Array.isArray(this.questionary.questions[toouterkey]) && event.droptarget.attributes.hasOwnProperty('innerkey')) {
                                    const toinnerkey = event.droptarget.attributes['innerkey'].value;
                                    if (toinnerkey < 0)
                                        this.questionary.questions[toouterkey].push(move_from);
                                    else
                                        this.questionary.questions[toouterkey].splice(toinnerkey, 0, move_from)
                                } else {
                                    this.questionary.questions.splice(toouterkey, 0, move_from)
                                }
                            }
                        }
                    }
                }
            };
            if (this.isEdit) {
                this.title = 'Edit questioner';
                this.questionary = JSON.parse(JSON.stringify(this.fdata));
            }
            this.refresh()
        },
        methods: {
            closeModal: function () {
                this.pCloseModal();
            },
            addQuestion(context) {
                if (typeof this.SelectedQuestion.title === 'string') {
                    context.push(this.SelectedQuestion);
                }
                this.name = '';
                this.SelectedQuestion = null;

            },
            async createQuestionary() {
                let query = {title: "", questions: []};
                query.title = this.questionary.title;
                query.questions = this.questionary.questions.map(obj => {
                    if (Array.isArray(obj)) {
                        return obj.map(obb => {
                            return obb.id
                        })
                    } else {
                        return obj.id
                    }
                });
                let resp;
                if (this.isEdit) {
                    query.id = this.questionary.id;
                    resp = await this.$store.dispatch(QUESTIONARY_EDIT, query);
                } else {
                    resp = await this.$store.dispatch(QUESTIONARY_CREATE, query);
                }
                this.$buefy.toast.open(RespInfo(resp));
                if (resp.status !== 200 && resp.status !== 201) {
                    this.$log.error(resp);
                } else {
                    this.prefresh();
                    this.pCloseModal();
                }
            },
            async refresh() {
                const resp = await this.$store.dispatch(QUESTIONS_GET);
                this.$buefy.toast.open(RespInfo(resp));
                this.data = resp.data;
            },

        },
        computed: {
            filteredDataObj() {
                return this.data.filter((option) => {
                    return option.title
                        .toString()
                        .toLowerCase()
                        .indexOf(this.name.toLowerCase()) >= 0
                })
            }
        }
    }
</script>

<style scoped>

    .draggable {
        min-height: 35px;
        padding: 3px;
        margin: 2px;
        border: 1px solid lightgray;
        border-radius: 4px;
    }

    .draggable p {
        padding-left: 30px;
    }

    .group {
    }

    .groupcontainer {
        padding-left: 30px;
    }

    .draghandler {
        float: left;
        height: 27px;
        width: 27px;
        border-radius: 5px;

        background-image: url("../assets/svg/pawprint.svg");
        background-position: center;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .draghandler:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    .drag-wrapper {
        display: flex;
        justify-content: center;
    }

    /* drop target state */
    .group[aria-dropeffect="move"] {
        border-color: #68b;
        background: #fff;
    }

    /* drop target focus and dragover state */
    .group[aria-dropeffect="move"]:focus,
    .group[aria-dropeffect="move"].dragover {
        outline: none;
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px #68b;
    }

    /* draggable items */
    .draggable:hover {
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items focus state */
    .draggable:focus {
        outline: none;
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items grabbed state */
    .draggable[aria-grabbed="true"] {
        background: #5cc1a6;
        color: #fff;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }
        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 2rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
    }
</style>