<template>
    <section class="medical_exams_container">
        <div class="medical_exams">
            <div v-for="(exam, key) of getExams" :key="key" class="medical_exam">
                <b-field v-if="exam.hasOwnProperty('timestamp')" :label="formatDate(exam.timestamp)">
                    <img v-if="expansion(exam.image_path) == 'image'" :src="exam.image_path"
                         @click="showImage(exam.image_path)"/>
                    <a v-else-if="expansion(exam.image_path) == 'pdf'"
                       :href="'http://docs.google.com/gview?url='+exam.image_path+'&embedded=true'"
                       target="_blank">
                        <b-icon icon="file-pdf" size="is-large"/>
                    </a>
                    <p v-else>unknown link</p>
                </b-field>
            </div>
        </div>
        <view-image-modal v-if="isImageModalActive"
                          :src="modalImagePath"
                          :is-active="isImageModalActive"
                          v-on:close="isImageModalActive=false"/>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';
    import moment from 'moment';
    import ViewImageModal from "../ViewImageModal";

    export default {
        name: "ClientMedicalExams",
        props: {
            user_id: String,
        },
        components: {
            ViewImageModal
        },
        data() {
            return {
                isImageModalActive: false,
                modalImagePath: '',
            }
        },
        created() {
            this.$store.dispatch("GET_MEDICAL_EXAMS", this.user_id);
        },
        computed: {
            ...mapGetters(['getExams']),
        },
        methods: {
            formatDate(date) {
                return moment(date).format("LL - HH:mm")
            },
            showImage(path) {
                this.modalImagePath = path;
                this.isImageModalActive = true;
            },
            expansion(filename) {
                let expansion = filename.split('.').pop();
                if (expansion == 'jpg' && 'jpeg' && 'gif' && 'png') {
                    return 'image';
                } else if (expansion == 'pdf') {
                    return 'pdf';
                }
                return 'default';
            },
            showPdf(url) {
                window.open(url)
            }
        }
    }
</script>

<style scoped>

    .medical_exams_container {
        height: 100%;
    }

    .medical_exams {
        text-align: left;
        height: 100%;
    }

    .medical_exam {
        text-align: center;
        display: inline-block;
        padding: 10px;
        border: 1px solid lightgray;
        margin: 7px;
        width: 200px;
        height: 150px;
    }

    .medical_exam:hover {
        background-color: lightgray;
    }

    .medical_exam img {
        height: 100px;
    }

</style>