<template>
    <section>
        <div class="adminbuttons" v-if="hasPermission('write:stages')">
            <b-button type="is-small is-rounded is-danger" @click="confirmFix">fix stages reordering</b-button>
        </div>
        <div class="levels" v-drag-and-drop:options="dndoptions">
            <level v-for="(level, key) in levels"
                   :key="key"
                   :id="level.id"
                   :index="Number(key)"
            />
            <b-button v-if="hasPermission('write:stages')" class="addlevel" @click="addLevel"
                      type="is-success is-rounded">add level
            </b-button>
            <b-loading :is-full-page="isFullPage" :can-cancel="canCancel" :active.sync="isLoading"></b-loading>
        </div>
    </section>
</template>

<script>
    import level from '../components/StagesAdminPage/Level'

    import {mapGetters} from 'vuex'

    export default {
        name: "StagesAdminPage",
        components: {
            level
        },
        props: {

        },
        data() {
            return {
                isFullPage: false,
                canCancel: false,
                dndoptions: {}
            }
        },
        computed: {
            ...mapGetters(['getLevels', 'getStagesStatus', 'hasPermission']),
            levels: {
                get() {
                    const levels = Object.values(this.getLevels);
                    return [...levels].sort((a, b) => a.order - b.order);
                }
            },
            isLoading: {
                get() {
                    if (this.getStagesStatus == 'loading') return true;
                    return false;
                }
            }
        },
        created() {
            this.refresh();
            this.dndoptions = {
                dropzoneSelector: '.draggable',
                draggableSelector: '.draggable',
                handlerSelector: '.draghandler',
                showDropzoneAreas: false,
                onDragend: function (event) {
                    if (event.droptarget != null) {
                        //let fromLevel = event.items[0].attributes['level'].value;
                        let fromStage = event.items[0].attributes['stage'].value;
                        if (fromStage > 0) {
                            let toLevel = event.droptarget.attributes['level'].value;
                            let toStage = event.droptarget.attributes['stage'].value;
                            //let str = 'from l:'+ fromLevel +' s:'+fromStage+' to l:'+toLevel+' s:'+ toStage;
                            //alert(str);
                            this.$store.dispatch("REORDER_STAGE", {
                                fromStage: fromStage,
                                toStage: toStage,
                                toLevel: toLevel
                            })
                        }
                    }
                }
            };

        },
        methods: {
            async refresh() {
                await this.$store.dispatch('LEVELS_GET');
            },
            confirmFix() {
                this.$buefy.dialog.confirm({
                    title: 'Run stages reordering fix',
                    message: 'Are you sure you want to <b>run stages reordering fix script</b> ? This action cannot be undone.',
                    confirmText: 'Confirm',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.fix()
                })
            },
            async fix() {
                await this.$store.dispatch('STAGES_FIX')
            },
            confirmIdsFix() {
                this.$buefy.dialog.confirm({
                    title: 'Run stages actions Id\'s fix',
                    message: 'Are you sure you want to <b>run stages actions Id\'s fixx script</b> ? This action cannot be undone.',
                    confirmText: 'Confirm',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.idFix()
                })
            },
            async idFix() {
                await this.$store.dispatch('STAGES_FIX_ACTION_ID')
            },
            addLevel() {
                this.$store.dispatch('ADD_LEVEL')
            }
        }
    }
</script>

<style scoped>
    .levels {
        padding: 10px;
    }

    .addlevel {
        margin-top: 10px;
        width: 100%;
    }

    .adminbuttons {
        position: relative;
        padding: 0;
        margin: 0;
        text-align: right;
    }
</style>