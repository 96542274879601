<template>
    <div class="glucose-meter_inner">

        <svg viewBox="0 0 136 90" >
            <rect x="24.2402" y="0.5" width="89" height="39" rx="5.5" fill="#F9F9F9" stroke="#E6E6E6"/>
            <g filter="url(#filter0_d)">
                <rect :x="valueToShown(0)" y="62" :width="valueToShown(green)-6 " height="8" rx="4" fill="url(#paint0_linear)"/>
                <rect :x="valueToShown(green)" y="62" :width="valueToShown(black)-valueToShown(green)+4" height="8" rx="4" fill="url(#paint1_linear)"/>
                <rect :x="valueToShown(black)" y="62" :width="valueToShown(max)-valueToShown(black)" height="8" rx="4" fill="url(#paint2_linear)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="-19.0943" y1="62" x2="-17.0311" y2="77.5896" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.718232" stop-color="#FF0F00"/>
                    <stop offset="1" stop-color="#DB0D00"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="11.5734" y1="64.9892" x2="44.1001" y2="101.019" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.598958" stop-color="#4CD964"/>
                    <stop offset="1" stop-color="#4CD964"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="49.5991" y1="64.9892" x2="81.627" y2="104.267" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.3125" stop-color="#585858"/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
        <div class="pointer" :style="getPointerPos(value)">
            <svg width="5" height="14" viewBox="0 0 5 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.00002 3H3.00002V11H2.00002V3Z" fill="#414042"/>
                <path d="M2.50002 4L4.66509 0.25H0.334961L2.50002 4Z" fill="#414042"/>
                <path d="M2.50002 10L4.66509 13.75H0.334961L2.50002 10Z" fill="#414042"/>
            </svg>
        </div>
        <div  :class="'value '+ getFit(value)"> {{round(value)}}</div>
        <div class="caption">{{caption}}</div>
        <div class="caption" :style="getCaptionPos(min)">min</div>
        <div class="caption" :style="getCaptionPos(max)">max</div>
        <div class="caption" :style="getCaptionPos(green)">{{green}}</div>
        <div class="caption" :style="getCaptionPos(black)">{{black}}</div>
    </div>
</template>

<script>
    export default {
        name: "GlucoseMeterLinear",
        props:{
            value: {type: Number, default: -1},
            caption: String,
            min: { type: Number, default: 40},
            green: {type: Number, default: 80},
            black: {type: Number, default: 120},
            max: { type: Number, default: 160}
        },
        data(){
            return{
            }
        },
        created() {
        },
        methods: {
            valueToShown(value){
                let left = 10;
                let multiply = 103;
                if (value < this.min) return left
                if (value > this.max) return multiply + left
                return ((value-this.min)/(this.max-this.min)) * multiply + left;
            },
            getFit(glucose) {
                if (glucose < 80) return 'glucose_red'; // < 80 red
                if (glucose > 120) return 'glucose_black'; // > 120 black
                return '';
            },
            round(num) {
                return Math.round(num * 10) / 10;
            },
            getCaptionPos(value) {
                let x = this.valueToShown(value);
                let y = 75
                return 'left: '+x+'px; top: '+ y+'px; text-align: left;';
            },
            getPointerPos(value) {
                let x = this.valueToShown(value);
                return 'left: '+x+'px; top: 58px; text-align: left;';
            }
        },

    }
</script>

<style scoped>
    .glucose-meter_inner {
        display: inline-block;
        position: relative;
        margin-top: 15px;
        height: 100px;
        width: 140px;
        background-repeat: no-repeat;

    }

    .value {
        position: absolute;
        top: 5px;
        left: 35px;
        width: 66px;
        height: 20px;
        font-family: Heebo;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #4CD964;

    }

    .caption {
        position: absolute;
        top: 26px;
        left: 27px;
        width: 86px;
        height: 15px;

        font-family: Heebo;
        font-size: 9px;
        font-weight: 400;
        line-height: 9px;
        text-align: center;
        color: #77767E;
    }


    .pointer {
        position: absolute;
    }
    .min {
        top: 95px;
        left: 0;
        text-align: right;
        width: 20px;
    }

    .max {
        top: 95px;
        left: 117px;
        text-align: left;
        width: 20px;
    }

    .circle {
        fill: none;
        stroke-width: 7px;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    .glucose_red {
        color: red;
        border-color: red;
    }

    .glucose_black {
        color: black;
        border-color: black;
    }
</style>