<template xmlns:b-table-column="http://www.w3.org/1999/html">
    <div class="comparison">
        <div class="filters">
            <b-field horizontal>
                <b-field>
                    <b-button type="is-small is-rounded is-success" @click="selectStart">Start/Finish</b-button>
                    <b-button type="is-small is-rounded is-success" @click="selectLast">Last finished daily</b-button>
                    <b-button type="is-small is-rounded is-success" @click="flipLastAndN">Last finished and Nd before
                    </b-button>
                </b-field>
                <b-field>
                    <b-numberinput v-model="numberDays" :disabled="!isSlider" :min="1" :max="maxNumberDays" class="inline" :key="tableKey" type="is-success is-rounded is-small">
                    </b-numberinput>
                </b-field>
            </b-field>
        </div>
        <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" :open="false">
            <template #trigger="props">
                <div class="card-header"
                     role="button"
                     aria-controls="contentIdForA11y3">
                    <p class="card-header-title">
                        Select visible questionnaires
                    </p>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>

            <div class="card-content">
                <div class="content">
                    <b-field v-for="(questionnaire, key) in getUserQuestionnaires" :key="comparisonKey+'_'+key">
                        <b-checkbox :value="comparison[key]" @click.native="flipComparison(key)">
                        </b-checkbox>
                        {{key+1}} {{questionnaire.title}}, [<i>{{questionnaire.type}}</i>],
                        {{formatDate(questionnaire.finished_at)}}
                    </b-field>
                </div>
            </div>
        </b-collapse>
        <b-table
                :data="getUserQuestionnairesComparison"
                :paginated="false"
                :key="tableKey"
                default-sort="">
            <template slot-scope="props">
                <b-table-column label="questions" field="questions">
                    {{ props.row[0].title }}
                </b-table-column>
                <b-table-column v-for="(el, key) in props.row" :key="tableKey+'_'+key"
                                :field="el.label" :label="formatDate(el.label)+' ('+ el.type+')'">
                    <b>{{ el.title }}</b><br/>
                    <div v-for="(question, ke) in el.answers" :key="ke" class="answer">
                        <b>{{ke+1}}) type:</b> {{question.type}}<br/>
                        <b>title:</b> {{question.title}} <br/>
                        <b>answer:</b> {{findValue(el, question.id)}} <br/>
                        <b>score:</b> {{findScore(el, question.id)}}
                    </div>
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    import moment from "moment"

    export default {
        name: "ClientQuestionnairesComparison",
        props: {
            user_id: String,
        },
        data() {
            return {
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
                indexes: [],
                questionnaireIndexes: [],
                questions: [],
                selectable: true,
                comparisonKey: 0,
                tableKey: 0,
                isSlider: false,
                numberDays: 1,
                maxNumberDays: 10,
            }
        },
        created() {
            this.refresh()
        },
        methods: {
            async refresh() {
                if (this.getUserQuestionnairesStatus === 'success') {
                    if (!this.isUserQuestionnaire(this.user_id)) {
                        await this.$store.dispatch('USER_GET_QUESTIONERS', this.user_id);
                    }
                } else {
                    await new Promise(r => setTimeout(r, 2000));
                    await this.refresh();
                }
            },
            findValue(ans, question) {
                if (ans.hasOwnProperty('answer')) {
                    let answer = ans.answer.values.find(element => {
                        if (element.hasOwnProperty('answer')) {
                            if (element.answer === question) return element
                        }
                    })
                    if (answer !== undefined) return answer.value
                }
                return 'no answer'
            },
            findScore(ans, question) {
                if (ans.hasOwnProperty('answer')) {
                    let answer = ans.answer.values.find(element => {
                        if (element.hasOwnProperty('score')) {
                            if (element.answer === question) return element
                        }
                    })
                    if (answer !== undefined) return answer.score
                }
                return ''
            },
            selectStart() {
                let user_questionnaires_compare = [];
                for (let questionnaire of this.getUserQuestionnaires) {
                    if ((questionnaire.type === 'start' || questionnaire.type === 'finish') && (questionnaire.finished))
                        user_questionnaires_compare.push(true)
                    else
                        user_questionnaires_compare.push(false)
                }
                this.comparison = user_questionnaires_compare;
                this.tableKey++;
                this.isSlider = false;
            },
            selectLast() {
                let user_questionnaires_compare = [];
                let lastIndex = 0;
                let index = 0;
                let lastDate = new Date("2019")
                for (let questionnaire of this.getUserQuestionnaires) {
                    user_questionnaires_compare.push(false)
                    if (questionnaire.finished) {
                        let currentDate = new Date(questionnaire.finished_at)
                        if (questionnaire.type === 'daily') {
                            if (currentDate > lastDate) {
                                lastDate = currentDate;
                                lastIndex = index;
                            }
                        }
                    }
                    index++;
                }
                user_questionnaires_compare[lastIndex] = true;
                this.comparison = user_questionnaires_compare;
                this.tableKey++;
                this.isSlider = false;
            },
            selectLastAndN() {
                let user_questionnaires_compare = [];
                let lastIndex = 0;
                let index = 0;
                let lastDate = new Date("2019")
                for (let questionnaire of this.getUserQuestionnaires) {
                    user_questionnaires_compare.push(false)
                    if (questionnaire.finished) {
                        let currentDate = new Date(questionnaire.finished_at)
                        if (currentDate > lastDate) {
                            lastDate = currentDate;
                            lastIndex = index;
                        }
                    }
                    index++;
                }
                user_questionnaires_compare[lastIndex] = true;
                let iii = lastIndex - this.numberDays;
                if (iii >= 0) {
                    user_questionnaires_compare[lastIndex - this.numberDays] = true;
                }
                this.maxNumberDays = lastIndex -1;
                this.comparison = user_questionnaires_compare;
                this.tableKey++;
            },
            flipLastAndN(){
                this.isSlider=!this.isSlider;
                if (this.isSlider) {
                    this.selectLastAndN()
                }
            },
            formatDate(textdate) {
                return moment(textdate).format("DD/MM/YYYY")
            },
            flipComparison(key) {
                let comparison = JSON.parse(JSON.stringify(this.comparison))
                if (comparison[key] === true)
                    comparison[key] = false;
                else
                    comparison[key] = true;
                this.comparison = comparison;
                this.comparisonKey += 1;
                this.tableKey++;
            }
        },
        computed: {
            ...mapGetters([
                "isUserQuestionnaire",
                'getUserQuestionnaires',
                'getUserQuestionnairesStatus',
                'getUserQuestionnairesComparisonQuestions',
                'getUserQuestionnairesComparison',
                'getUserQuestionnairesCompare',
            ]),
            comparison: {
                get() {
                    return this.getUserQuestionnairesCompare;
                },
                set(payload) {
                    this.$store.dispatch('USER_SET_QUESTIONERS_COMPARE', payload)
                }
            },
        },
        watch: {
            numberDays: function () {
                this.selectLastAndN();
            }
        }
    }
</script>

<style scoped>
    .comparison {
        text-align: left;
        overflow-x: auto;
    }

    .answer {
        border: 1px solid green;
    }

</style>