<template>
    <section>
        <div class="logic_block">
            <b-field label="logic:" horizontal>
                <b-select v-model="logic_operator">
                    <option>and</option>
                    <option>or</option>
                </b-select>
            </b-field>
            <div v-if="data.conditions !== undefined">
                <div v-for="(condition, key) in conditions" :key="key">
                    <div v-if="key>0">{{logic_operator}}</div>
                    <div v-if="notBlocked">
                        <add-condition-block v-if="condition.meta==='block'"
                                                  :block="condition"
                                                  :level="level+1"
                                                  :index="key"
                                                  :delete-me="deleteBlock"
                                                  @change="changedCondition(key, $event)">
                        </add-condition-block>
                        <div v-if="condition.meta==='condition'" class="logic_block">
                            <view-condition :condition="condition" />
                            <b-button type="is-success is-rounded is-small" @click="editCondition(key)">edit</b-button>
                            <b-button type="is-danger is-rounded is-small" @click="deleteBlock(key)">delete</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <b-button type="is-success is-rounded is-small" @click="addCondition"> add condition</b-button>
            <b-button v-if="level<2" type="is-success is-rounded is-small" @click="addLogicBlock"> add subblock</b-button>
            <b-button type="is-danger is-rounded is-small" @click="deleteMe(index)"> delete block</b-button>
        </div>
        <b-modal :active.sync="isCompModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-condition-form
                    :pCloseModal="closeChildModal"
                    :fdata="formData"
                    :isEdit="isConditionEdit"
                    @change="saveCondition($event)" >
            </add-condition-form>
        </b-modal>
    </section>
</template>

<script>
    import AddConditionForm from "./AddConditionForm";
    import ViewCondition from "./ViewCondition";

    export default {
        name: "AddConditionBlock",
        components: {
            AddConditionForm,
            ViewCondition
        },
        props: {
            block: {},
            level: Number,
            deleteMe: {},
            index: Number,
        },
        data() {
            return {
                data: {},
                notBlocked: true,
                isCompModalActive: false,
                editIndex: -1,
                isConditionEdit: false,
                formData: {},
            }
        },
        created() {
            this.data = Object.assign({}, this.block);
        },
        computed: {
            logic_operator: {
                get() {
                    return this.data.type;
                },
                set(payload) {
                    this.data.type = payload;
                    this.save()
                }
            },
            conditions: {
                get() {
                    return this.data.conditions;
                },
                set(payload) {
                    this.data.conditions = JSON.parse(JSON.stringify(payload))
                    this.save()
                }
            }
        },
        methods: {
            save() {
                this.$emit('change', this.data)
            },
            addLogicBlock() {
                let block = {
                    type: 'and',
                    meta: 'block',
                    conditions: [],
                };
                this.data.conditions.push(block);
                this.save()
            },
            addCondition() {
                this.formData = {};
                this.isConditionEdit = false;
                this.isCompModalActive = true;
            },
            editCondition(index){
                this.formData = Object.assign({},this.data.conditions[index]);
                this.isConditionEdit = true;
                this.isCompModalActive = true;
                this.editIndex = index;

            },
            saveCondition(payload){
                if (this.isConditionEdit){
                    this.data.conditions[this.editIndex]=Object.assign({},payload)
                } else {
                    this.data.conditions.push(payload)
                }
                this.save()
            },
            closeChildModal(){
                this.isCompModalActive = false;
            },
            changedCondition(key, payload) {
                window.console.log(payload)
                let data = JSON.parse(JSON.stringify(this.data));
                data.conditions[key] = Object.assign(data.conditions[key], payload);
                this.data = JSON.parse(JSON.stringify(data));
                this.save()
            },
            deleteBlock(key){
                this.notBlocked = false;
                this.data.conditions.splice(key, 1);
                this.save()
                this.notBlocked = true;
            },
        },
    }


</script>

<style scoped>
    .logic_block {
        border: 1px solid lightgray;
        padding: 5px;
    }
</style>