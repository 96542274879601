<template>
    <div class="weights_heights_card">
        <div class="caption">{{caption}}</div>
        <div class="user_heights">Height: <span class="weights_heights_value">{{ height }} cm</span></div>
        <div class="user_weights">Weight:<span class="weights_heights_value"> {{ weight}} kg</span></div>
        <div class="user_bmi">BMI:<span class="weights_heights_value" v-bind:class="getColor()"> {{ getBMI() }}</span></div>
    </div>
</template>

<script>
    export default {
        name: "ClientWeightHeight",
        props: {
            caption: String,
            height: Number,
            weight: Number
        },
        methods:{
            getBMI() {
                return Math.floor(this.weight  / Math.pow(this.height/100,2) * 100)/100
            },
            getColor() {
                let bmi = this.getBMI();
                if (bmi < 18.5) return 'bmi_black';
                if (bmi < 25) return 'bmi_green';
                if (bmi < 30) return 'bmi_yellow';
                if (bmi < 35) return 'bmi_red';
                return 'bmi_black';
            },
        }
    }
</script>

<style scoped>
    .weights_heights_card {
        position: relative;
        display: inline-block;
        height: 80px;
        width: 200px;
        border-left: 1px solid #9A99A2;
        /*background-image: url("svg/client_level_points.svg");
        background-repeat: no-repeat;*/
        font-family: Heebo;
        font-size: 14px;
        line-height: 18px;
        /* or 100% */
        align-items: center;
        letter-spacing: -0.08px;
        color: #9A99A2;
        overflow: visible;
    }

    .caption {
        position: absolute;
        top:2px;
        left: 14px;
        width:15px;
        font-family: Heebo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.07999999821186066px;
        text-align: center;
    }


    .user_heights{
        font-family: Heebo;
        position: absolute;
        left: 14px;
        top: 20px;
    }

    .user_weights {
        font-family: Heebo;
        position: absolute;
        left: 14px;
        top: 40px;
    }

    .user_bmi {
        font-family: Heebo;
        position: absolute;
        left: 14px;
        top: 60px;
    }

    .weights_heights_value {
        font-family: Heebo;
        font-weight: 900;
        font-size: 20px;
        line-height: 14px;
        letter-spacing: -0.08px;
        color: #5C5C5C;
    }

    .bmi_black {
        color: black;
    }

    .bmi_green {
        color: #4CD964;
    }

    .bmi_yellow {
        color: yellow;
        text-shadow: darkorange 1px 1px;
    }

    .bmi_red {
        color: red;
    }
</style>
